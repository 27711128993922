import React from 'react';
import styled, {css} from "styled-components/macro";
import { Margins, Text } from "styles/Mixins";
import { toRem } from "styles/Utility";

/* ====================================================
Table Container
==================================================== */

function Body(props) {
  return (
      <StyledTable {...props}><tbody>{props.children}</tbody></StyledTable>
  );
}

const StyledTable = styled.table`
  ${Margins}
  margin-right: ${props => toRem(props.theme.gutter.small * -1)};
  margin-left: ${props => toRem(props.theme.gutter.small * -1)};
`;


/* ====================================================
Table Row
==================================================== */

function Tr(props) {
    return (
        <StyledTr {...props}>{props.children}</StyledTr>
    );
}
  
const StyledTr = styled.tr`
    ${Margins}
`;

/* ====================================================
Table Cell
==================================================== */

function Td(props) {
    return (
        <StyledTd {...props}>{props.children}</StyledTd>
    );
}
  
const StyledTd = styled.td`
    ${Text}

    padding-right: ${props => props.theme.gutter.remSmall};
    padding-left: ${props => props.theme.gutter.remSmall};
    padding-bottom: ${props => props.theme.gutter.remSmall};

    ${props =>
    props.alignTop &&
        css`
            vertical-align: top;
        `
    }
`;


export default {
    Body,
    Tr,
    Td
}