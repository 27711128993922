import React from 'react';
import styled, {css} from "styled-components/macro";
import { Margins, FlexContainer } from "styles/Mixins";
import { toRem } from "styles/Utility";
import { Visibility } from "styles/Mixins";

function Row(props) {
  return (
      <StyledRow className="row" {...props}>{props.children}</StyledRow>
  );
}

const StyledRow = styled.div`
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;

  ${props => 
    props.collapse &&
    css`
      margin-left: ${props => toRem(props.theme.gutter.small * -1)};
      margin-right: ${props => toRem(props.theme.gutter.small * -1)};
    `
  }
  
  ${Margins}
  ${FlexContainer}
  ${Visibility}
`;

export default Row;