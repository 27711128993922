import React from "react";
import styled, {css} from "styled-components";
import {toRem} from "styles/Utility";
import {Text, Margins} from "styles/Mixins";

export default function TextCta(props) {
  return (
    <StyledTextCTA {...props} as="p">
      <span>{props.children}</span>
      {props.icon && <StyledIcon className={`icon-${props.icon}`} /> }
    </StyledTextCTA>
  )
}

const StyledTextCTA = styled.p`
  ${Margins}
  ${Text}
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  i {

  }

  ${props =>
    !props.disableHoverAnimation &&
    css`
      &:hover {
        i {
          transform: translateX(5px);
        }
      }
    `
  }

  ${props =>
    props.showIconOnHover &&
    css`
      i {
        opacity: 0;
      }

      &:hover {
        i {
          opacity: 1;
          transform: translateX(5px);
        }
      }
    `
  }
`

const StyledIcon = styled.i`
  color: ${props => props.theme.colors.primaryLight};
  margin-left: ${toRem(3)};
  font-size: ${toRem(12)};
  display: block;
  transition: transform 0.2s, opacity 0.2s;
  will-change: transform, opacity;
`