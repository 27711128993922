import React, { useState } from "react";
import { Panel, FlexContainer, Button } from "components";
import { useCookies } from "react-cookie";
import SpacingWrapper from "../SpacingWrapper";
import Locales from "locales";
import org from "org";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import locales, { getAvailableLanguages } from "locales";
import { languageCookieName } from "locales/settings";
import { withCookies } from "react-cookie";
import { setLanguagePreference } from "./helper";

const text__go = locales("general.go");
const text__title = Locales("page.settings.lang.title");

/*eslint-disable*/
function LanguagePreferences(props) {
  /* ====================================
  DELETE ONCE FR TRANSLATIONS ARE IN
  ==================================== */
  return <span />;
  /* ====================================
  DELETE ONCE FR TRANSLATIONS ARE IN
  ==================================== */

  const presetLang = getAvailableLanguages()[0].short;
  const [cookies] = useCookies([languageCookieName]);
  const [lang, setLang] = useState(
    cookies[languageCookieName] ? cookies[languageCookieName] : presetLang
  );
  const [showButton, setShowButton] = useState(false);

  if (!props.cookies) return <span />;

  const cookiesObject = props.cookies;

  if (org.learnMoreLinks) {
    return (
      <SpacingWrapper>
        <Panel.Container>
          <Panel.Header>
            <Panel.Heading>{text__title}</Panel.Heading>
          </Panel.Header>
          <Panel.Body padLarge>
            <FlexContainer align="center" justify="flex-start">
              <FormControl style={{ minWidth: 120 }}>
                <Select
                  value={lang}
                  displayEmpty
                  onChange={e => {
                    const { value } = e.target;
                    setLang(value);
                    setShowButton(true);
                  }}
                >
                  {getAvailableLanguages().map((lang, i) => (
                    <MenuItem key={i} value={lang.short}>
                      {lang.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {showButton && (
                <Button
                  small
                  ml={10}
                  onClick={() => {
                    setLanguagePreference(lang, cookiesObject);
                  }}
                >
                  {text__go}
                </Button>
              )}
            </FlexContainer>
          </Panel.Body>
        </Panel.Container>
      </SpacingWrapper>
    );
  } else {
    return <span />;
  }
}
/*eslint-enable*/

export default withCookies(LanguagePreferences);
