import { closeAllOpenNavs } from "store/navigation";
import { mobileNav } from "main/components/navigationSettings";
import store from "store";

export function isChildIsActive(sublinks, activePathname) {
  if (sublinks.length > 0) {
    for (let index = 0; index < sublinks.length; index++) {
      const sublink = sublinks[index];
      if (sublink.path === activePathname) {
        return true
      }
    }
  } else {
    return false
  }
}

export function hideOtherOpenNavs() {
  mobileNav.close();
  store.dispatch(closeAllOpenNavs(true));
  setTimeout(() => {store.dispatch(closeAllOpenNavs(false))}, 20);
}