import { getUserStatus, getProfile } from "utility/StackApi/User";
import { getBalance } from "utility/StackApi/Money";
import { setFeatureFlaggingConfig } from "utility/FeatureFlagging";
import { userStatus, setUserProfile } from "store/user";
import { setBalance } from "store/money";
import store from "store";

//fetch profile info (for navigation)
export async function fetchProfile() {
  try {
    const profileResponse = await getProfile();
    store.dispatch(setUserProfile(profileResponse.data));
  } catch (err) {
    return;
  }
}

//After fetch balance info (for navigation)
export async function fetchBalance() {
  try {
    const balanceResponse = await getBalance();
    store.dispatch(setBalance(balanceResponse.data));
  } catch (err) {
    return;
  }
}

//After fetch balance info (for navigation)
export async function fetchUserStatus() {
  try {
    const response = await getUserStatus();
    store.dispatch(userStatus(response.data.userStatus));
  } catch (err) {
    store.dispatch(userStatus(true)); //Set as true so user can enter app
  }
}

export const promiseArray = [fetchProfile(), fetchBalance(), setFeatureFlaggingConfig()];
