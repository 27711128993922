import React, { useReducer } from "react";
import ModuleContainer from "./ModuleContainer";
import { FlexContainer, Button, P, Span } from "components";
import locales from "locales";
import { Input } from "@material-ui/core";
import { sendPasswordResetEmail } from "utility/StackApi/General";
import { emailRegex } from "utility";
import { screenEnums } from "./store";
import { hideAndShowModule } from "./helper";

const text__emailPlaceholder = locales("general.email");
const text__forgotPassTitle = locales("component.forgotPass.title");
const text__forgotPassDesc = locales("component.forgotPass.desc");
const text__forgotPassBackToLogin = locales("component.forgotPass.backToLogin");
const text__resetLinkSent = locales("component.forgotPass.resetLinkSent");
const text__submit = locales("general.submit");
const text__invalidCredentials = locales("error.invalidCredentials");
const text__loading = locales("general.loading");

const initialState = {
  email: "",
  loading: false,
  status: false,
  statusMessage: false
};

function reducer(state, action) {
  switch (action.type) {
    case "email":
      return { ...state, email: action.value };
    case "loading":
      return { ...state, loading: action.value };
    case "status":
      return { ...state, status: action.value };
    case "statusMessage":
      return { ...state, statusMessage: action.value };
    default:
      throw new Error();
  }
}

export default function LoginComponent(props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  async function handleSubmit() {
    const validationMessage = validation(state.email);
    if (validationMessage !== true) {
      dispatch({ type: "status", value: "ERROR" });
      dispatch({ type: "statusMessage", value: validationMessage });
      return;
    }

    dispatch({ type: "loading", value: true });
    dispatch({ type: "status", value: false });

    try {
      await sendPasswordResetEmail(state.email);
      dispatch({ type: "loading", value: false });
      dispatch({ type: "status", value: "SUCCESS" });
      dispatch({ type: "statusMessage", value: text__resetLinkSent });
    } catch (err) {
      dispatch({ type: "loading", value: false });
      dispatch({ type: "status", value: "ERROR" });
      dispatch({ type: "statusMessage", value: text__invalidCredentials });
    }
  }

  function goToLogin() {
    hideAndShowModule(screenEnums.login);
  }

  return (
    <ModuleContainer
      title={text__forgotPassTitle}
      statusMessage={state.statusMessage}
      status={state.status}
    >
      <form
        onSubmit={e => {
          e.preventDefault();
        }}
      >
        <P smaller center>
          {text__forgotPassDesc}
        </P>
        <FlexContainer mb={25}>
          <Input
            placeholder={text__emailPlaceholder}
            inputProps={{ style: { textAlign: "center" } }}
            fullWidth
            value={state.email}
            onChange={e => {
              const { value } = e.target;
              dispatch({ type: "email", value });
            }}
          />
        </FlexContainer>
        <Button
          fullWidth
          mb={10}
          loading={state.loading ? "true" : undefined}
          onClick={handleSubmit}
        >
          <Span>{state.loading ? text__loading : text__submit}</Span>
        </Button>
        <P small center clickable onClick={goToLogin}>
          {text__forgotPassBackToLogin}
        </P>
      </form>
    </ModuleContainer>
  );
}

function validation(email, password) {
  if (!emailRegex.test(email)) return locales("error.invalidEmail");

  return true;
}
