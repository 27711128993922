import React from "react";
import { Panel, P } from "components";
import SpacingWrapper from "./SpacingWrapper";
import Locales from "locales";

const text__title = Locales("page.settings.closeAcc.title");
const text__desc = Locales("page.settings.closeAcc.desc");
const text__call = Locales("page.settings.closeAcc.call");

export default function PersonalInfo(props) {
  return (
    <SpacingWrapper>
      <Panel.Container>
        <Panel.Header>
          <Panel.Heading>{text__title}</Panel.Heading>
        </Panel.Header>
        <Panel.Body padLarge>
          <P smaller>{text__desc}</P>
          <P dangerouslySetInnerHTML={{ __html: text__call }} />
        </Panel.Body>
      </Panel.Container>
    </SpacingWrapper>
  );
}
