import React, { useEffect} from 'react';
import { CenterContent } from "components";
import { createGlobalStyle } from "styled-components";
import QRCode from 'qrcode.react';

var triggered = false;

export const queryParam = 'qrCodeUrl'

function printPage() {
  setTimeout(function(){
    if (!triggered) {
      triggered = true;
      window.print();
    }
  }, 1000)
}

function getQRCodeUrl() {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let qrCodeUrl = params.get('qrCodeUrl');
  return qrCodeUrl;
}

function QRCodePage(props) {
  const url = getQRCodeUrl();
  useEffect(printPage, [])

  return (
    <CenterContent fullHeight id="qrCodeContainer">
      <HideEverything/>
      <QRCode size={210} value={url} />
    </CenterContent>
  );
}

const HideEverything = createGlobalStyle`
  body, html {
    background-color: white !important;
    width: 100% !important;
    height: 100vh !important;
    overflow: hidden;
  }

  nav {
    display: none !important;
  }
`

export default QRCodePage;
