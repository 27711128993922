import React from "react";
import styled from "styled-components/macro";
import { FlexContainer as FlexContainerMixin, Margins as MarginMixin } from "styles/Mixins";

export default function FlexContainer(props) {
  return <StyledFlexContainer {...props}>{props.children}</StyledFlexContainer>;
}

const StyledFlexContainer = styled.div`
  ${FlexContainerMixin}
  ${MarginMixin}
`;
