import {css} from "styled-components";
import ResponsivePropGenerator from "./ResponsivePropGenerator";

export default css`
  ${ResponsivePropGenerator({
    cssProperty: 'display', 
    cssUnit: 'none',
    propName: 'display', 
    expectedType: 'string'
  })}
`