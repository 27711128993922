import { buildRequest } from "utility/StackApi";
import qs from "qs";

export async function getCardDetails() {
  return buildRequest({
    verb: 'get',
    endpoint: '/card/details'
  })
}

export async function getPhysicalCardDetails(passcode) {
  return buildRequest({
    verb: 'post',
    data: qs.stringify({pin: passcode}),
    headers: {"Content-Type": "application/x-www-form-urlencoded"},
    endpoint: '/card/details/physical'
  })
}

export async function getPhysicalCardPin(passcode) {
  return buildRequest({
    verb: 'get',
    query: `?pin=${passcode}`,
    headers: {"Content-Type": "application/x-www-form-urlencoded"},
    endpoint: '/cardPin'
  })
}

export async function getVirtualCardDetails(passcode) {
  return buildRequest({
    verb: 'post',
    data: qs.stringify({pin: passcode}),
    headers: {"Content-Type": "application/x-www-form-urlencoded"},
    endpoint: '/card/details/virtual'
  })
}

export async function freezePhysicalCard() {
  return buildRequest({
    verb: 'post',
    endpoint: '/card/freeze'
  })
}

export async function unfreezePhysicalCard() {
  return buildRequest({
    verb: 'post',
    endpoint: '/card/unfreeze'
  })
}

export async function freezeVirtualCard() {
  return buildRequest({
    verb: 'post',
    endpoint: '/card/freeze/virtual'
  })
}

export async function unfreezeVirtualCard() {
  return buildRequest({
    verb: 'post',
    endpoint: '/card/unfreeze/virtual'
  })
}

//Supply 4-Digit Card Number (Last 4 digits of card)
export async function activateCard(cardNumber) {
  return buildRequest({
    verb: 'post',
    data: qs.stringify({cardNumber}),
    headers: {"Content-Type": "application/x-www-form-urlencoded"},
    endpoint: '/card/activate'
  })
}