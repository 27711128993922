import merge from "lodash/merge";
import {
  Typography as type,
  Colors as colors,
  Button as button,
  GutterSettings as gutter,
  GridSettings as gridSettings,
  Panel as panel,
  Navigation as navigation,
  Home as home,
  Reward as reward,
  MaterialUITheme as materialTheme,
} from "./DefaultTheme";
import { createMuiTheme } from "@material-ui/core/styles";
import ThemeOverrides from "org/theme.js";

// 1. Supply basic variables to theme function. This creates a basic theme
// 2. Pass object to override theme's specific styling of certain components

const defaultStyling = () => ({
  type,
  colors,
  button,
  gutter,
  gridSettings,
  panel,
  navigation,
  home,
  reward,
  materialTheme,
});

export function generateTheme(overrideOptions = {}) {
  const defaultStyles = defaultStyling();
  return merge(defaultStyles, overrideOptions);
}

export function generateMaterialUITheme(theme) {
  const themeObject = createMuiTheme({
    palette: {
      primary: { 500: theme.colors.primaryLight },
      text: {
        primary: theme.colors.pop,
      },
      type: theme.materialTheme.theme,
    },
  });

  return themeObject;
}

export const theme = generateTheme(ThemeOverrides);

export const materialUITheme = generateMaterialUITheme(theme);

export default theme;
