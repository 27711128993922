import { css } from "styled-components";
import ResponsivePropGenerator from "./ResponsivePropGenerator";

export default css`
  ${props =>
    props.pReset &&
    css`
      padding: 0;
    `}

  ${ResponsivePropGenerator({
    cssProperty: "padding-top",
    cssUnit: "rem",
    propName: "pt",
    expectedType: "number"
  })}

  ${ResponsivePropGenerator({
    cssProperty: "padding-left",
    cssUnit: "rem",
    propName: "pl",
    expectedType: "number"
  })}

  ${ResponsivePropGenerator({
    cssProperty: "padding-right",
    cssUnit: "rem",
    propName: "pr",
    expectedType: "number"
  })}

  ${ResponsivePropGenerator({
    cssProperty: "padding-bottom",
    cssUnit: "rem",
    propName: "pb",
    expectedType: "number"
  })}

  ${ResponsivePropGenerator({
    cssProperty: "padding",
    cssUnit: "none",
    propName: "p",
    expectedType: "string"
  })}
`;
