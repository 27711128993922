import React, { useState } from "react";
import styled from "styled-components";
import { CenterContent, Login } from "components";
import { handleSuccess, getVerify2faData } from "./helper";
import { Query, toRem } from "styles/Utility";
import { screenEnums } from "components/Login/store";

function LoginPage(props) {
  const data = getVerify2faData();
  const [currentScreen] = useState(data ? screenEnums.twoFactorAuth : screenEnums.login);
  const [prePopulatedData] = useState(data);

  return (
    <StyledLoginContainer fullWidth>
      <Login
        {...props}
        successCallback={response => {
          const user = response.data;
          handleSuccess({ user, props });
        }}
        startingScreen={currentScreen}
        prePopulatedData={prePopulatedData}
      />
    </StyledLoginContainer>
  );
}

const StyledLoginContainer = styled(CenterContent)`
  height: 100vh;

  @media ${Query.mobileOnly} {
    height: calc(100vh - ${toRem(90)});
  }
`;

export default LoginPage;
