export const actionTypes = {
  PAGE_LOADED: "PAGE_LOADED",
  PAGE_LOADING: "PAGE_LOADING",
  PAGE_TITLE: "PAGE_TITLE",
  PAGE_REQUIRED_STATUS: "PAGE_REQUIRED_STATUS",
  PAGE_REQUESTS_ALLOWED: "PAGE_REQUESTS_ALLOWED"
};

// actionCreators
export function pageLoaded(bool) {
  return {
    type: actionTypes.PAGE_LOADED,
    value: bool
  };
}

export function pageLoading(bool) {
  return {
    type: actionTypes.PAGE_LOADING,
    value: bool
  };
}

export function pageTitle(value) {
  return {
    type: actionTypes.PAGE_TITLE,
    value
  };
}

export function pageRequiredStatus(value) {
  return {
    type: actionTypes.PAGE_REQUIRED_STATUS,
    value
  };
}

export function requestsAllowed(value) {
  return {
    type: actionTypes.PAGE_REQUESTS_ALLOWED,
    value
  };
}
