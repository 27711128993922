import React from "react";
import { Panel, P, TextCta } from "components";
import SpacingWrapper from "./SpacingWrapper";
import Locales from "locales";
import org from "org";

const text__title = Locales("page.settings.learnMore.title");
const text__desc = Locales("page.settings.learnMore.desc");
const text__privacyPolicy = Locales("page.settings.learnMore.privacyPolicy");
const text__tos = Locales("page.settings.learnMore.tos");
const text__cardAgr = Locales("page.settings.learnMore.cardAgr");
const text__faq = Locales("page.settings.learnMore.faq");

export default function PersonalInfo(props) {
  if (org.learnMoreLinks) {
    return (
      <SpacingWrapper>
        <Panel.Container>
          <Panel.Header>
            <Panel.Heading>{text__title}</Panel.Heading>
          </Panel.Header>
          <Panel.Body padLarge>
            <P mb={25} faded>
              {text__desc}
            </P>
            {org.learnMoreLinks.privacyPolicy && (
              <div>
                <TextCta mReset mb={15} showIconOnHover icon="caret-right">
                  <a
                    href={org.learnMoreLinks.privacyPolicy}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {text__privacyPolicy}
                  </a>
                </TextCta>
              </div>
            )}
            {org.learnMoreLinks.termsOfService && (
              <div>
                <TextCta mReset mb={15} showIconOnHover icon="caret-right">
                  <a
                    href={org.learnMoreLinks.termsOfService}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {text__tos}
                  </a>
                </TextCta>
              </div>
            )}
            {org.learnMoreLinks.cardholderAgreement && (
              <div>
                <TextCta mReset mb={15} showIconOnHover icon="caret-right">
                  <a
                    href={org.learnMoreLinks.cardholderAgreement}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {text__cardAgr}
                  </a>
                </TextCta>
              </div>
            )}
            {org.learnMoreLinks.faq && (
              <div>
                <TextCta mReset mb={15} showIconOnHover icon="caret-right">
                  <a href={org.learnMoreLinks.faq} target="_blank" rel="noopener noreferrer">
                    {text__faq}
                  </a>
                </TextCta>
              </div>
            )}
          </Panel.Body>
        </Panel.Container>
      </SpacingWrapper>
    );
  } else {
    return <span />;
  }
}
