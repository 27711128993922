import React from "react";
import { Panel, P, Button, FlexContainer } from "components";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import Locales from "locales";
import SpacingWrapper from "./SpacingWrapper";
import { Query } from "styles/Utility";
import has from "lodash/has";
import routePaths from "routes/routePaths";

const text__cardNotActivatedTitle = Locales("page.home.cardNotActivatedTitle");
const text__cardNotActivatedText = Locales("page.home.cardNotActivatedText");
const text__cardNotActivatedButtonText = Locales("page.home.cardNotActivatedButtonText");

export default function ActivateYourCard(props) {
  const hasCardDetails = has(props, "store.cards.details.cards");
  const hasInactiveCard = hasCardDetails
    ? getHasInactiveCard(props.store.cards.details.cards)
    : false;

  if (hasInactiveCard) {
    return (
      <SpacingWrapper>
        <Panel.Container>
          <Panel.Header icon={"warning"} iconColor={props.theme.colors.warning}>
            <Panel.Heading>{text__cardNotActivatedTitle}</Panel.Heading>
          </Panel.Header>
          <Panel.Body pt={35} pb={35}>
            <FlexContainer justify={{ sm: "center", md: "space-between" }} align={{ sm: "center" }}>
              <StyledP mReset mb={{ sm: 15, md: 1 }} faded>
                {text__cardNotActivatedText}
              </StyledP>
              <NavLink to={routePaths.cards.path}>
                <Button secondary warning>
                  {text__cardNotActivatedButtonText}
                </Button>
              </NavLink>
            </FlexContainer>
          </Panel.Body>
        </Panel.Container>
      </SpacingWrapper>
    );
  } else {
    return <span />;
  }
}

const StyledP = styled(P)`
  @media ${Query.mobileOnly} {
    text-align: center;
  }
`;

function getHasInactiveCard(cardsArray) {
  let hasInactiveCard = false;

  for (let index = 0; index < cardsArray.length; index++) {
    const card = cardsArray[index];
    if (card.status === "ISSUED_INACTIVE") return (hasInactiveCard = true);
  }

  return hasInactiveCard;
}
