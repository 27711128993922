import React, { useState } from "react";
import Locales from "locales";
import { Button, P, Span } from "components";
import { Checkbox, FormControlLabel, Input } from "@material-ui/core";
import { SINRegex } from "utility";
import { postSINNumber } from "utility/StackApi/User";
import styled from "styled-components/macro";
import has from "lodash/has";

//Text
const text__cra__subtitle = Locales("page.addMoney.cra.subtitle");
const text__cra__consentPolicy = Locales("page.addMoney.cra.consentPolicy");
const text__cra__privacyPolicy = Locales("page.addMoney.cra.privacyPolicy");
const text__cra__consentText = Locales("page.addMoney.cra.consentText");
const text__cra__enterSinPlaceholder = Locales(
  "page.addMoney.cra.enterSinPlaceholder"
);
const text__cra__reenterSinPlaceholder = Locales(
  "page.addMoney.cra.reenterSinPlaceholder"
);
const text__cra__enterValidSin = Locales("page.addMoney.cra.enterValidSin");
const text__cra__sinMustMatch = Locales("page.addMoney.cra.sinMustMatch");
const text__cra__mustConsent = Locales("page.addMoney.cra.mustConsent");
const text__submit = Locales("general.submit");
const text__loading = Locales("general.loading");
const text__success = Locales("page.addMoney.cra.success");

function CRADirectDeposit(props) {
  const [consented, setConsented] = useState(false);
  const [sin1, setSin1] = useState("");
  const [sin2, setSin2] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [successfulCall, setSuccessfulCall] = useState(false);

  if (successfulCall) {
    return <P success>{text__success}</P>;
  }

  function resetErrorMessage() {
    setErrorMessage(false);
  }

  function formErrors() {
    let errors = "";

    function addToErrors(error) {
      errors += error + "<br/>";
    }

    if (!consented) addToErrors(text__cra__mustConsent);
    if (!SINRegex.test(sin1) || !SINRegex.test(sin2))
      addToErrors(text__cra__enterValidSin);
    if (sin1 !== sin2) addToErrors(text__cra__sinMustMatch);

    if (errors === "") {
      return false;
    } else {
      setErrorMessage(errors);
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    resetErrorMessage();

    if (formErrors() === false) {
      setLoading(true);

      //Extract the numbers from sin, store as string (in case 0 in beginning)
      const sinOnlyNumbersString = sin1.match(/\d+/g).join("");

      try {
        await postSINNumber(sinOnlyNumbersString);
        setSuccessfulCall(true);
        setLoading(false);
        resetErrorMessage();
      } catch (error) {
        if (has(error, "response.data.message")) {
          setErrorMessage(error.response.data.message);
        }
        setLoading(false);
      }
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <P>{text__cra__subtitle}</P>
      <div dangerouslySetInnerHTML={{ __html: text__cra__consentPolicy }} />
      <div dangerouslySetInnerHTML={{ __html: text__cra__privacyPolicy }} />
      <FormControlLabel
        control={
          <Checkbox
            checked={consented}
            onChange={() => {
              setConsented(!consented);
            }}
            name="consent"
            color="primary"
          />
        }
        label={text__cra__consentText}
      />
      <InputWrapper>
        <Input
          placeholder={text__cra__enterSinPlaceholder}
          type="text"
          fullWidth
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="none"
          value={sin1}
          onChange={(e) => {
            setSin1(e.target.value);
          }}
        />
      </InputWrapper>
      <InputWrapper>
        <Input
          placeholder={text__cra__reenterSinPlaceholder}
          type="text"
          fullWidth
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="none"
          value={sin2}
          onChange={(e) => {
            setSin2(e.target.value);
          }}
        />
      </InputWrapper>
      <InputWrapper>
        <Button type="submit" mb={10} loading={loading ? "true" : undefined}>
          <Span>{loading ? text__loading : text__submit}</Span>
        </Button>
      </InputWrapper>
      {errorMessage && (
        <P
          error
          mReset
          smaller
          dangerouslySetInnerHTML={{ __html: errorMessage }}
        />
      )}
    </form>
  );
}

export default CRADirectDeposit;

const InputWrapper = styled.div`
  padding-top: ${(props) => props.theme.gutter.rem};
  padding-bottom: ${(props) => props.theme.gutter.rem};
  position: relative;
`;
