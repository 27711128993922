import {css} from "styled-components";
import {toRem, Query} from "styles/Utility";

export default function({
    cssProperty,
    cssUnit = 'none',
    propName, 
    expectedType
  }) {


  let expectedTypeTest = function(val) {
    return true
  }
  switch(expectedType) {
    case 'number':
      expectedTypeTest = function(val) {
        return Number.isInteger(val)
      }
      break;
    case 'string':
      expectedTypeTest = function(val) {
        return typeof val === 'string'
      }
      break;
    default:
      break;
  }

  let converter = function(val) {
    return val
  }
  switch(cssUnit) {
    case 'rem':
      converter = function(val) {
        return toRem(val);
      }
      break;
    case 'px':
      converter = function(val) {
        return `${val}px`
      }
      break;
    case 'none':
      converter = function(val) {
        return val
      }
      break;
    default:
      break;
  }

  

  return css`
    ${props =>
      props[propName] && 
      css`
        ${props =>
          expectedTypeTest(props[propName]) && 
          css`
            ${cssProperty}: ${props => converter(props[propName])};
          `
        }
      `
    }

    ${props =>
      props[propName] && 
      css`
        ${props =>
          props[propName].sm && 
          css`
            ${cssProperty}: ${props => converter(props[propName].sm)};
          `
        }

        ${props =>
          props[propName].md && 
          css`
            @media ${Query.small} {
              ${cssProperty}: ${props => converter(props[propName].md)};
            }
          `
        }

        ${props =>
          props[propName].lg && 
          css`
            @media ${Query.medium} {
              ${cssProperty}: ${props => converter(props[propName].lg)};
            }
          `
        }
      `
    }  
  `
}