import React from "react";
import { FlexContainer, Icon, Span } from "components";
import styled from "styled-components";
import { Logout } from "utility/Authentication";
import Locales from "locales";

const text__logout = Locales("navigation.logout");

export default function Navigation() {
  return (
    <StyledFlexContainer align="center" justify="center" flex={"inline-flex"} onClick={Logout}>
      <Span smaller faded mReset>
        <Icon name="logout" />
      </Span>
      <Span smaller faded ml={4} mb={2}>
        {text__logout}
      </Span>
    </StyledFlexContainer>
  );
}

const StyledFlexContainer = styled(FlexContainer)`
  margin-bottom: 3px;
  cursor: pointer;
`;
