import React from "react";
import { P, Table, TapToCopy } from "components";
import Locales from "locales";
import has from "lodash/has";

const text__description = Locales("page.addMoney.payroll.description");
const text__customerName = Locales("page.addMoney.payroll.customerName");
const text__transitNumber = Locales("page.addMoney.payroll.transitNumber");
const text__institutionNumber = Locales("page.addMoney.payroll.institutionNumber");
const text__accountNumber = Locales("page.addMoney.payroll.accountNumber");

export default function LoadingOption(props) {
  const hasFirstName = has(props, "user.profile.firstName");
  const { accountNumber, institutionNumber, transitNumber } = props.money.payrollInfo;

  return (
    <div>
      <P faded mb={25}>
        {text__description}
      </P>
      <Table.Body>
        <Table.Tr>
          <Table.Td light faded>
            {text__customerName}
          </Table.Td>
          <Table.Td>
            {hasFirstName && (
              <TapToCopy
                showIconOnHover
                copyText={`${props.user.profile.firstName} ${props.user.profile.lastName}`}
                displayText={`${props.user.profile.firstName} ${props.user.profile.lastName}`}
              />
            )}
          </Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td light faded>
            {text__transitNumber}
          </Table.Td>
          <Table.Td>
            {transitNumber && (
              <TapToCopy showIconOnHover copyText={transitNumber} displayText={transitNumber} />
            )}
          </Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td light faded>
            {text__institutionNumber}
          </Table.Td>
          <Table.Td>
            {institutionNumber && (
              <TapToCopy
                showIconOnHover
                copyText={institutionNumber}
                displayText={institutionNumber}
              />
            )}
          </Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td light faded>
            {text__accountNumber}
          </Table.Td>
          <Table.Td>
            {accountNumber && (
              <TapToCopy showIconOnHover copyText={accountNumber} displayText={accountNumber} />
            )}
          </Table.Td>
        </Table.Tr>
      </Table.Body>
    </div>
  );
}
