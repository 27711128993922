import { getProfile } from "utility/StackApi/User";
import {
  getBalance,
  getGoal,
  getBankingInfo,
  getLoadhubQRCode,
  getPayrollInfo,
} from "utility/StackApi/Money";
import { getRecentTransactions } from "utility/StackApi/Transactions";
import { getCardDetails } from "utility/StackApi/Cards";
import { getRewards } from "utility/StackApi/Rewards";
import { setBalance, setGoal, setBankingInfo, setLoadhubQRCode, setPayrollInfo } from "store/money";
import { setRecentTransactions } from "store/transactions";
import { setUserProfile } from "store/user";
import { setCardDetails } from "store/cards";
import { setRewards } from "store/rewards";
import store from "store";
import get from "lodash/get";

let attemptObj = {
  profile: 0,
};

const dataRetriever = {
  profile: function () {
    return fetchAndSet({
      methodName: "profile",
      getFunction: getProfile,
      setDataFunction: setUserProfile,
      dataToSet: "data",
    });
  },
  balance: function () {
    return fetchAndSet({
      methodName: "balance",
      getFunction: getBalance,
      setDataFunction: setBalance,
      dataToSet: "data",
    });
  },
  goal: function () {
    return fetchAndSet({
      methodName: "goal",
      getFunction: getGoal,
      setDataFunction: setGoal,
      dataToSet: "data",
    });
  },
  recentTransactions: function () {
    return fetchAndSet({
      methodName: "recentTransactions",
      getFunction: getRecentTransactions,
      setDataFunction: setRecentTransactions,
      dataToSet: "data",
    });
  },
  cardDetails: function () {
    return fetchAndSet({
      methodName: "cardDetails",
      getFunction: getCardDetails,
      setDataFunction: setCardDetails,
      dataToSet: "data",
    });
  },
  rewards: function () {
    return fetchAndSet({
      methodName: "rewards",
      getFunction: getRewards,
      setDataFunction: setRewards,
      dataToSet: "data",
    });
  },
  bankingInfo: function () {
    return fetchAndSet({
      methodName: "bankingInfo",
      getFunction: getBankingInfo,
      setDataFunction: setBankingInfo,
      dataToSet: "data",
    });
  },
  loadhubQRCode: function () {
    return fetchAndSet({
      methodName: "loadhubQRCode",
      getFunction: getLoadhubQRCode,
      setDataFunction: setLoadhubQRCode,
      dataToSet: "data",
    });
  },
  payrollInfo: function () {
    return fetchAndSet({
      methodName: "payrollInfo",
      getFunction: getPayrollInfo,
      setDataFunction: setPayrollInfo,
      dataToSet: "data",
    });
  },
};

/* ============================================
Fetch and set data
Accepts option object, containing:
{
  methodName: [string: the name of the method you're setting this for.]
  getFunction: [async fn that gets the info],
  setDataFunction: [dispatch function that sets data to store],
  dataToSet: [the property return from 'response' that you want to set. ie to set response.data you would just enter 'data']
}

It tries up to 3 times to fetch data because sometimes it fails for whatever odd reason.
Every time it makes an attempt, it increases its corresponding key value in the attemptObj.
============================================ */

async function fetchAndSet(options) {
  let attemptObjKey = attemptObj[options.methodName];

  //If the key doesn't exist, add it to object
  if (!attemptObjKey) {
    attemptObj[options.methodName] = 0;
  }

  try {
    //Initially try to get the content
    const response = await options.getFunction();

    //If successful, set it to the store and reset the counter
    store.dispatch(options.setDataFunction(get(response, options.dataToSet)));
    resetAttemptObj(attemptObjKey);
    return response;
  } catch (err) {
    attemptObj[options.methodName] = attemptObj[options.methodName] + 1;

    //If you fail, try fetching up to 3 times
    if (attemptObjKey <= 3) {
      setTimeout(async () => {
        const method = await dataRetriever[options.methodName];
        return await method();
      }, 100);

      //If failed 3 times, just return and reset attempt counter
    } else {
      return resetAttemptObj(0);
    }
  }
}

function resetAttemptObj(keyToReset) {
  attemptObj[keyToReset] = 0;
}

export default dataRetriever;
