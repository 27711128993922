import React, { useState, Fragment } from "react";
import { Panel, TransactionListItem, Button, Spinner, P } from "components";
import styled, { css, keyframes } from "styled-components/macro";
import { getTransactions } from "utility/StackApi/Transactions";
import { getMonthDayYear } from "utility/Time";
import { toRem } from "styles/Utility";
import Locales from "locales";
import useEffectAsync from "utility/useEffectAsync";

const text__noTransactionsFound = Locales(
  "page.transactions.noTransactionsFound"
);

async function fetchTransactions(
  isFirstFetch,
  transactions,
  setTransactions,
  setNoMoreTransactions,
  setIsLoading
) {
  /*
  Need to set to false immediately after function runs so 
  user can enter page and conduct on-load fetch again
  */

  setIsLoading(true);

  const retrievedTransactions = await getTransactions(isFirstFetch);
  if (retrievedTransactions) {
    setTransactions([...transactions, ...retrievedTransactions]);
  } else {
    setNoMoreTransactions(true);
  }

  setIsLoading(false);
}

function TransactionsPage(props) {
  const [transactions, setTransactions] = useState([]);
  const [noMoreTransactions, setNoMoreTransactions] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffectAsync(async () => {
    await fetchTransactions(
      true,
      transactions,
      setTransactions,
      setNoMoreTransactions,
      setIsLoading
    );
  }, []);

  if (transactions.length > 0) {
    return (
      <TransactionsContainer>
        <Panel.Container>
          <Panel.Body noHorizontalPad noVerticalPad>
            {transactions &&
              transactions.map((transaction, i) => {
                if (!transaction?.transaction) return <></>;

                const date = getMonthDayYear(
                  transaction?.transaction?.transactionDate
                );

                return (
                  <Fragment key={`dateHeader${i}`}>
                    <DayHeader date={date} data-date={date}>
                      <Panel.Heading>{date}</Panel.Heading>
                    </DayHeader>
                    <TransactionListItem {...transaction} />
                  </Fragment>
                );
              })}
          </Panel.Body>
        </Panel.Container>
        {!noMoreTransactions && (
          <ButtonContainer>
            <Button
              loading={isLoading ? "true" : undefined}
              onClick={async () => {
                fetchTransactions(
                  false,
                  transactions,
                  setTransactions,
                  setNoMoreTransactions,
                  setIsLoading
                );
              }}
            >
              {Locales(isLoading ? "general.loading" : "general.viewMore")}
            </Button>
          </ButtonContainer>
        )}
      </TransactionsContainer>
    );
  } else {
    if (!isLoading) {
      return (
        <P faded mt={10}>
          {text__noTransactionsFound}
        </P>
      );
    } else {
      return <Spinner />;
    }
  }
}

const ButtonContainer = styled.div`
  margin-top: ${toRem(30)};
  text-align: center;
`;

const FadeInAnimation = keyframes`
    from {
        opacity: 0;
        transform: translateY(${toRem(25)});
    },
    to {
        opacity: 1;
        transform: none;
    }
`;

const TransactionsContainer = styled.div`
  animation: 0.3s ${FadeInAnimation} 1 forwards;
`;

const DayHeader = styled(Panel.Header)`
  min-height: 0;
  padding-top: ${(props) => props.theme.gutter.remSmall};
  padding-bottom: ${(props) => props.theme.gutter.remSmall};

  ${(props) =>
    props.date &&
    css`
      &[data-date="${(props) => props.date}"] {
        & ~ [data-date="${(props) => props.date}"] {
          display: none !important;
        }
      }
    `}
`;

export default TransactionsPage;
