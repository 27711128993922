import React, { useState } from "react";
import { P, Panel, Table, Span, CircleTextCta, CardStatusDot } from "components";
import Locales from "locales";
import styled from "styled-components/macro";
import MobileCard from "./MobileCard";
import { Query, toRem } from "styles/Utility";
import { formatCardNumber } from "utility/Cards";
import {
  handleViewPhysicalCardDetails,
  handleViewPhysicalCardPin,
  handleViewVirtualCardDetails,
  handleFreezePhysicalCard,
  handleUnfreezePhysicalCard,
  handleFreezeVirtualCard,
  handleUnfreezeVirtualCard,
  hidePhysicalCardDetails,
  hideVirtualCardDetails,
  hidePhysicalCardPin
} from "./helper";
import has from "lodash/has";
import ReactTooltip from "react-tooltip";
import { featureEnums, featureIsEnabled, featureUnavailableText } from "utility/FeatureFlagging";
const tooltipClass = "transaction-tooltip";

//Locales
const text__cardStatus = Locales("card.cardStatus");
const text__cardNumber = Locales("card.cardNumber");
const text__expiryDate = Locales("card.expiryDate");
const text__securityCode = Locales("card.securityCode");
const text__pin = Locales("card.pin");
const text__cards_frozenViewDetails = Locales("page.cards.frozenViewDetails");
const text__hideCardNumber = Locales("card.hideCardNumber");
const text__viewCardNumber = Locales("card.viewCardNumber");
const text__cards_frozenViewPin = Locales("page.cards.frozenViewPin");
const text__hideCardPin = Locales("card.hideCardPin");
const text__viewCardPin = Locales("card.viewCardPin");
const text__virtualCard = Locales("card.virtualCard");

export default function CardModule(props) {
  //Initial states
  const [freezeCardLoading, setFreezeCardLoading] = useState(false);
  const [cardPinLoading, setCardPinLoading] = useState(false);
  const [cardInfoLoading, setCardInfoLoading] = useState(false);

  //Props and variables
  const { card } = props;
  const hasPhysicalCardNumber = has(props, "store.cards.physicalCardDetails.cardNumber");
  const hasVirtualCardNumber = has(props, "store.cards.virtualCardDetails.cardNumber");
  const { physicalCardPin } = props.store.cards;
  const showCardDetails =
    (featureIsEnabled(featureEnums.virtualCard) && card.type === "VIRTUAL") ||
    card.type !== "VIRTUAL";

  const cardDetails = () => {
    if (card.type === "VIRTUAL") {
      return hasVirtualCardNumber ? props.store.cards.virtualCardDetails : false;
    } else {
      return hasPhysicalCardNumber ? props.store.cards.physicalCardDetails : false;
    }
  };

  return (
    <StyledPanelContainer>
      <ReactTooltip />
      <Panel.Header>
        <Panel.Heading>
          {Locales(card.type === "VIRTUAL" ? "card.virtualCard" : "card.physicalCard")}
        </Panel.Heading>
      </Panel.Header>
      <Panel.Body pt={30} pb={30}>
        {showCardDetails ? (
          <div>
            <MobileCard {...props.card} />
            <StatusText mReset smaller mb={20}>
              <Span faded>{text__cardStatus}:</Span>{" "}
              {Locales(card.status === "ACTIVE" ? "card.active" : "card.frozen")}
              <CardStatusDot status={card.status} />
            </StatusText>
            <Table.Body mb={15}>
              <Table.Tr>
                <Table.Td small faded>
                  {text__cardNumber}
                </Table.Td>
                <Table.Td small>
                  {cardDetails() ? (
                    <Span>{formatCardNumber(cardDetails().cardNumber)}</Span>
                  ) : (
                    <Span faded>**** **** **** {card.lastFourDigits}</Span>
                  )}
                </Table.Td>
              </Table.Tr>
              <Table.Tr>
                <Table.Td small faded>
                  {text__expiryDate}
                </Table.Td>
                <Table.Td small>
                  {cardDetails() ? <Span>{cardDetails().expiry}</Span> : <Span faded>****</Span>}
                </Table.Td>
              </Table.Tr>
              <Table.Tr>
                <Table.Td small faded>
                  {text__securityCode}
                </Table.Td>
                <Table.Td small>
                  {cardDetails() ? <Span>{cardDetails().cvv}</Span> : <Span faded>***</Span>}
                </Table.Td>
              </Table.Tr>
              {card.type !== "VIRTUAL" && (
                <Table.Tr>
                  <Table.Td small faded>
                    {text__pin}
                  </Table.Td>
                  <Table.Td small>
                    {physicalCardPin ? <Span>{physicalCardPin}</Span> : <Span faded>****</Span>}
                  </Table.Td>
                </Table.Tr>
              )}
            </Table.Body>
            <span
              data-tip={card.status !== "ACTIVE" ? text__cards_frozenViewDetails : ""}
              data-class={tooltipClass}
              data-html={true}
              data-effect="solid"
            >
              <StyledCircleTextCta
                disabled={card.status !== "ACTIVE"}
                mReset
                mr={20}
                icon={"eye"}
                loading={cardInfoLoading ? "true" : undefined}
                onClick={() => {
                  if (cardDetails()) {
                    card.type === "VIRTUAL" ? hideVirtualCardDetails() : hidePhysicalCardDetails();
                  } else {
                    card.type === "VIRTUAL"
                      ? handleViewVirtualCardDetails(setCardInfoLoading)
                      : handleViewPhysicalCardDetails(setCardInfoLoading);
                  }
                }}
              >
                {cardDetails() ? text__hideCardNumber : text__viewCardNumber}
              </StyledCircleTextCta>
            </span>
            {card.type !== "VIRTUAL" && (
              <span
                data-tip={card.status !== "ACTIVE" ? text__cards_frozenViewPin : ""}
                data-class={tooltipClass}
                data-html={true}
                data-effect="solid"
              >
                <StyledCircleTextCta
                  disabled={card.status !== "ACTIVE"}
                  loading={cardPinLoading ? "true" : undefined}
                  onClick={() => {
                    if (physicalCardPin) {
                      hidePhysicalCardPin();
                    } else {
                      handleViewPhysicalCardPin(setCardPinLoading);
                    }
                  }}
                  mReset
                  mr={20}
                  icon={"lock"}
                >
                  {physicalCardPin ? text__hideCardPin : text__viewCardPin}
                </StyledCircleTextCta>
              </span>
            )}
            <StyledCircleTextCta
              mReset
              mr={20}
              icon={"snowflake"}
              loading={freezeCardLoading ? "true" : undefined}
              onClick={() => {
                setFreezeCardLoading(true);
                if (card.status === "ACTIVE") {
                  card.type === "VIRTUAL"
                    ? handleFreezeVirtualCard(setFreezeCardLoading)
                    : handleFreezePhysicalCard(setFreezeCardLoading);
                } else {
                  card.type === "VIRTUAL"
                    ? handleUnfreezeVirtualCard(setFreezeCardLoading)
                    : handleUnfreezePhysicalCard(setFreezeCardLoading);
                }
              }}
            >
              {Locales(card.status === "ACTIVE" ? "card.freezeCard" : "card.unfreezeCard")}
            </StyledCircleTextCta>
          </div>
        ) : (
          <P faded>{featureUnavailableText(text__virtualCard)}</P>
        )}
      </Panel.Body>
    </StyledPanelContainer>
  );
}

const StatusText = styled(P)`
  position: relative;
  display: inline-block;
`;

const StyledPanelContainer = styled(Panel.Container)`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.panel.bodyBg};
`;

const StyledCircleTextCta = styled(CircleTextCta)`
  vertical-align: middle;

  @media ${Query.mobileOnly} {
    display: flex;
    flex: 1 1 100%;
    justify-content: flex-start;
    margin-bottom: ${toRem(10)};
  }
`;
