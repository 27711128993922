import React from "react";
import styled from "styled-components/macro";
import { toRem, Query } from "styles/Utility";
import { Heading, P, DownloadAppButtons } from "components";

/* ====================================================
Page Container
==================================================== */

function Container(props) {
  return <StyledContainer {...props}>{props.children}</StyledContainer>;
}

const StyledContainer = styled.div`
  padding: ${toRem(120)} 0 ${toRem(60)};

  @media ${Query.mobileOnly} {
    padding: ${toRem(100)} 0 ${toRem(60)};
  }
`;

/* ====================================================
Page Title
==================================================== */

function Title(props) {
  return (
    <StyledPageHeading type="h2" mReset>
      {props.children}
    </StyledPageHeading>
  );
}

const StyledPageHeading = styled(Heading)`
  margin-bottom: ${toRem(25)};

  @media ${Query.mobileOnly} {
    margin-bottom: ${props => props.theme.gutter.rem};
  }
`;

/* ====================================================
Page Coming Soon
Simple page with icon, text and optionally 
download buttons
==================================================== */

function ComingSoon(props) {
  return (
    <ComingSoonContainer {...props}>
      {props.icon && <ComingSoonIcon src={props.icon} />}
      {props.title && (
        <P faded center mReset mt={20}>
          {props.title}
        </P>
      )}
      {props.subtitle && (
        <P faded center mReset smaller mt={7} mb={20}>
          {props.subtitle}
        </P>
      )}
      {props.downloadButtons && (
        <DownloadButtonsContainer>
          <DownloadAppButtons />
        </DownloadButtonsContainer>
      )}
    </ComingSoonContainer>
  );
}

const DownloadButtonsContainer = styled.div`
  margin-top: ${toRem(20)};
`;

const ComingSoonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const ComingSoonIcon = styled.img`
  height: ${toRem(150)};

  @media ${Query.mobileOnly} {
    height: ${toRem(110)};
  }
`;

export default {
  Container,
  Title,
  ComingSoon
};
