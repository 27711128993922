import tracking from "utility/tracking";

export const activeClass = "loading-option-active";

export function handleOptionSelect(optionTitle) {
  const dataAttr = "data-load-option";
  const loadOptions = document.querySelectorAll(`[${dataAttr}]`);

  //Remove active class from all
  [].forEach.call(loadOptions, function(loadOption) {
    loadOption.classList.remove(activeClass);
  });

  //Get option selected and add class to that
  const selectedLoadOptions = document.querySelectorAll(`[${dataAttr}="${optionTitle}"]`);
  [].forEach.call(selectedLoadOptions, function(selectedLoadOption) {
    selectedLoadOption.classList.add(activeClass);
  });

  //Track clicking on each view
  tracking.event(`${optionTitle} view`);
}
