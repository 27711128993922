import React, { useState } from "react";
import { Panel, P, Icon } from "components";
import styled, { keyframes, css } from "styled-components/macro";
import { toRem } from "styles/Utility";
import { activeClass } from "./helper";
import { Query } from "styles/Utility";
import Locales from "locales";

//Open class
const openClass = "load-option-open";

//Text
const text__loadTime = Locales("page.addMoney.loadTime");

function LoadingOptionInfo(props) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <MoreInfoPanel data-load-option={props.title} className={`${props.isActive && activeClass}`}>
      <LoadPanelHeader
        icon={props.icon}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <Panel.Heading>{props.title}</Panel.Heading>
        <PanelLoadTimeHeader small mReset>
          {text__loadTime}: <LoadTime highlighted={props.highlighted}>{props.loadTime}</LoadTime>
          <LoadPanelCaret small name={isOpen ? "caret-up" : "caret-down"} />
        </PanelLoadTimeHeader>
      </LoadPanelHeader>
      <PanelBody className={isOpen && openClass}>{props.children}</PanelBody>
    </MoreInfoPanel>
  );
}

const fadeIn = keyframes`
  from {
    transform: translateY(${toRem(30)});
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const LoadPanelCaret = styled(Icon)`
  margin-left: ${props => props.theme.gutter.remSmall};
`;

const LoadTime = styled.span`
  ${props =>
    props.highlighted &&
    css`
      color: ${props => props.theme.colors.success};
    `}
`;

const LoadPanelHeader = styled(Panel.Header)`
  @media ${Query.mobileOnly} {
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
`;

const PanelLoadTimeHeader = styled(P)`
  display: none;

  @media ${Query.mobileOnly} {
    display: block;
  }
`;

const MoreInfoPanel = styled(Panel.Container)`
  display: none;

  &.${activeClass} {
    display: block;
    animation: ${fadeIn} 0.4s ease 1;
  }

  @media ${Query.mobileOnly} {
    display: block;
    margin-bottom: ${props => props.theme.gutter.rem};
    animation: ${fadeIn} 0.4s ease 1;
  }
`;

const PanelBody = styled(Panel.Body)`
  @media ${Query.mobileOnly} {
    display: none;

    &.${openClass} {
      display: block;
    }
  }
`;

export default LoadingOptionInfo;
