import { buildRequest } from "utility/StackApi";
import { getBrowserNameAndVersion } from "utility";
import qs from "qs";

// Get Profile Information
export async function getProfile() {
  return buildRequest({
    verb: "get",
    endpoint: "/profile",
  });
}

// Update profile
// Options = {newPassword: xxx, oldPassword: yyy}
export async function updatePassword(options) {
  return buildRequest({
    verb: "patch",
    data: qs.stringify(options),
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    endpoint: "/password",
  });
}

// User Status
export async function getUserStatus(callback) {
  return buildRequest({
    verb: "get",
    endpoint: "/user/status",
  });
}

//Submit SIN number for CRA direct deposit
export async function postSINNumber(sin) {
  return buildRequest({
    verb: "post",
    data: qs.stringify({
      sin,
      consent: true,
      browser: `${getBrowserNameAndVersion().browserName} ${
        getBrowserNameAndVersion().browserVersion
      }`,
    }),
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    endpoint: "/directDepositRegistration",
  });
}

export async function getVerificationURL() {
  return buildRequest({
    verb: "get",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    endpoint: "/kyc/verification-url",
  });
}
