// import the actionTypes
import { actionTypes } from "./index.js";

export const defaultState = {};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.SET_FEATURE_FLAGGING:
      return Object.assign({}, state, {
        ...action.value
      });
    default:
      return state;
  }
}
