import routePaths from "routes/routePaths";
import {
    getXApiKey,
    getTempApiKey,
    setXApiKey,
    setTempApiKey,
    setPinApiKey,
} from "utility/Token";
import { userStatus } from "store/user";
import store from "store";
import tracking from "utility/tracking";

/* ===============================================
Check to see if route is allowed 
based on user status.
=============================================== */
export function RouteIsAllowed({ status, path }) {
    var valid = false;

    Object.keys(routePaths).map((route, i) => {
        const routeObject = routePaths[route];

        if (path === routeObject.path) {
            if (routeObject.requiredStatus) {
                //If it has a required status
                return (valid = routeObject.requiredStatus.includes(status));
            } else {
                //If it doesn't have a required status, it's fine
                return (valid = true);
            }
        } else {
            return null;
        }
    });

    return valid;
}

/* ===============================================
Build Token Query
For re-routing to other sites
=============================================== */
export function GetTokenQuery() {
    const x = getXApiKey();
    const t = getTempApiKey();
    return `?x=${x}&t=${t}`;
}

/* ===============================================
Get route on login
based on user status
=============================================== */
export function GetLoginRoute(status) {
    const query = GetTokenQuery();

    switch (status) {
        case "FAILED_KYC":
            return routePaths.verification.path;
        case "PASSED_KYC":
            return `${process.env.REACT_APP_GETSTACK_SITE}/sign-up/finalize-profile${query}`;
        case "PASSWORD_INITIALIZED":
            return `${process.env.REACT_APP_GETSTACK_SITE}/sign-up/profile-information${query}`;
        case "REGISTERED":
            return routePaths.home.path;
        default:
            return routePaths.login.path;
    }
}

/* ===============================================
Determine if routing location is external
or on account webapp
=============================================== */
export function RouteToLocation(route, history) {
    const pathname = window.location.pathname;

    if (pathname !== route) {
        if (route.charAt(0) === "/") {
            //If internal
            history.push(route);
        } else {
            window.location.href = route;
        }
    }
}

/* ===============================================
Routing function used when user logs in
=============================================== */
export function Route({ status, history, delay = 0 }, callback) {
    setTimeout(function () {
        store.dispatch(userStatus(status));
        const route = GetLoginRoute(status);
        RouteToLocation(route, history);

        if (callback) {
            callback();
        }
    }, delay);
}

/* ===============================================
Logout
=============================================== */
export function Logout() {
    setXApiKey("null");
    setTempApiKey("null");
    setPinApiKey("null");
    tracking.event("Logout");

    if (window.adaEmbed) {
        window.adaEmbed.deleteHistory(); //
    }

    //TODO: create redux function to reset store
    window.location.reload();
}
