import { combineReducers } from 'redux';
import user from './user/reducer';
import navigation from './navigation/reducer';
import modal from './modal/reducer';
import page from './page/reducer';
import money from './money/reducer';
import transactions from './transactions/reducer';
import cards from './cards/reducer';
import rewards from './rewards/reducer';
import featureFlagging from './featureFlagging/reducer';

const appReducer = combineReducers({
  user,
  navigation,
  modal,
  page,
  money,
  transactions,
  cards,
  rewards,
  featureFlagging
})

export default appReducer;