export const actionTypes = {
  SET_CARD_DETAILS: "SET_CARD_DETAILS",
  SET_PHYSICAL_CARD_DETAILS: "SET_PHYSICAL_CARD_DETAILS",
  SET_PHYSICAL_CARD_PIN: "SET_PHYSICAL_CARD_PIN",
  SET_VIRTUAL_CARD_DETAILS: "SET_VIRTUAL_CARD_DETAILS"
};

//Value thats retrieved from /card/details
export function setCardDetails(value) {
  return {
    type: actionTypes.SET_CARD_DETAILS,
    value: value
  };
}

//Value thats retrieved from /card/details/physical
export function setPhysicalCardDetails(value) {
  return {
    type: actionTypes.SET_PHYSICAL_CARD_DETAILS,
    value: value
  };
}

//Value thats retrieved from /cardPin
export function setPhysicalCardPin(value) {
  return {
    type: actionTypes.SET_PHYSICAL_CARD_PIN,
    value: value
  };
}

//Value thats retrieved from /card/details/virtual
export function setVirtualCardDetails(value) {
  return {
    type: actionTypes.SET_VIRTUAL_CARD_DETAILS,
    value: value
  };
}