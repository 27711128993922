import React from "react";
import styled, { css, withTheme } from "styled-components";
import { toRem } from "styles/Utility";
import { Spinner } from "components";
import { Margins } from "styles/Mixins";

function Button(props) {
  return (
    <div>
      <StyledButton {...props}>
        {props.loading ? (
          <StyledSpinner color={props.theme.colors.pop} />
        ) : (
          <span>{props.children}</span>
        )}
      </StyledButton>
    </div>
  );
}

export const buttonHeight = 40;

const StyledSpinner = styled(Spinner)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: -3px;
  margin-left: -3px;
`;

const StyledButton = styled.button`
  color: ${(props) => props.theme.button.color};
  background: ${(props) => props.theme.button.bg};
  text-transform: ${(props) => props.theme.button.textTransform};
  border-radius: ${(props) => toRem(props.theme.button.borderRadius)};
  font-size: ${(props) => props.theme.button.fontSize};
  font-weight: ${(props) => props.theme.button.fontWeight};
  border: none;
  font-family: ${(props) => props.theme.button.fontFamily};
  height: ${toRem(buttonHeight)};
  min-width: ${toRem(140)};
  padding-left: ${toRem(20)};
  padding-right: ${toRem(20)};
  cursor: pointer;
  transition: background 0.3s, border-color 0.3s;
  will-change: background-color, border-color;
  position: relative;
  ${Margins}

  &:hover {
    background: ${(props) => props.theme.button.bgHover};
  }

  ${(props) =>
    props.small &&
    css`
      height: ${toRem(27)};
      min-width: ${toRem(60)};
      padding-left: ${toRem(10)};
      padding-right: ${toRem(10)};
    `}

  ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;
    `}

  ${(props) =>
    props.loading &&
    css`
      opacity: 0.9;
      pointer-events: none;
      color: transparent;
    `}

  ${(props) =>
    (props.secondary || props.warning || props.disabled) &&
    css`
      border-width: 2px;
      border-style: solid;
      color: ${(props) => props.theme.button.secondaryTextColor};
      background: none;

      &:hover {
        background: none;
      }
    `}

  ${(props) =>
    props.disabled &&
    css`
      color: ${(props) => props.theme.colors.disabled};
      border-color: ${(props) => props.theme.colors.disabled};
      pointer-events: none;

      &:hover {
        border-color: ${(props) => props.theme.colors.disabled};
      }
    `}

  ${(props) =>
    props.secondary &&
    css`
      border-color: ${(props) => props.theme.button.secondaryBorderColor};

      &:hover {
        border-color: ${(props) => props.theme.button.secondaryTextColor};
      }
    `}

  ${(props) =>
    props.warning &&
    css`
      border-color: ${(props) => props.theme.colors.warning};

      &:hover {
        border-color: ${(props) => props.theme.colors.warning};
      }
    `}
`;

export default withTheme(Button);
