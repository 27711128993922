import React from 'react';
import { createGlobalStyle } from 'styled-components';
import IcomoonStyles from "fonts/icons/style.css";

const Icons = (props) => (
  <StyledIcons/>
);

const StyledIcons = createGlobalStyle`
  ${IcomoonStyles}
`
export default Icons;
