//Navigation/Sidebar Settings

export const navId = "topBar";
export const scrollingClass = "scrolling";
export const mobileHeight = 70;
export const sidebarWidth = 245;
export const masterContainerId = 'masturr';
export const mobileNavOpenClass = 'mobile-nav-open';
export const sidebarId = 'sidebarNavigation';
export const mobileOpenButtonId = 'openNavButton';
export const mobileCloseButtonId = 'closeNavButton';


export const mobileNav = {
  open() {
    window.mobilNavIsOpen = true;
    document.getElementById(sidebarId).classList.add(mobileNavOpenClass);
    document.getElementById(masterContainerId).classList.add(mobileNavOpenClass);

    //hide open button, show close button
    document.getElementById(mobileOpenButtonId).style.display = "none";
    document.getElementById(mobileCloseButtonId).style.display = "block";
  }, 
  close() {
    window.mobilNavIsOpen = false;
    document.getElementById(sidebarId).classList.remove(mobileNavOpenClass);
    document.getElementById(masterContainerId).classList.remove(mobileNavOpenClass);

    //hide open button, show close button
    document.getElementById(mobileOpenButtonId).style.display = "block";
    document.getElementById(mobileCloseButtonId).style.display = "none";
  }
}