import { navId, scrollingClass } from "main/components/navigationSettings";

function onScroll(distance) {
  const nav = document.getElementById(navId);

  if (distance > 10) return nav.classList.add(scrollingClass);

  nav.classList.remove(scrollingClass);
}

export function init() {
  window.addEventListener('scroll', function() {
    const distance = document.documentElement.scrollTop;
    onScroll(distance);
  });
}