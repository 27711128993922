import React, { useEffect } from "react";
import LoginScreen from "./Login";
import TwoFactorAuthScreen from "./TwoFactorAuth";
import ResetPasswordScreen from "./ResetPassword";
import { connect } from "react-redux";
import { screenEnums } from "./store";
import styled, { css } from "styled-components/macro";
import { animationDuration, hideAndShowModule } from "./helper";

function LoginViews(props) {
  const { currentScreen, hidingScreens } = props.loginProps;

  useEffect(() => {
    if (props.startingScreen) {
      hideAndShowModule(props.startingScreen);
    }
  }, [props.startingScreen]);

  return (
    <ViewsContainer hiding={hidingScreens ? true : undefined}>
      {currentScreen === screenEnums.login && <LoginScreen {...props} />}
      {currentScreen === screenEnums.twoFactorAuth && (
        <TwoFactorAuthScreen {...props} successCallback={props.successCallback} />
      )}
      {currentScreen === screenEnums.resetPassword && <ResetPasswordScreen {...props} />}
    </ViewsContainer>
  );
}

const ViewsContainer = styled.div`
  transition: transform ${animationDuration / 1000}s, opacity ${animationDuration / 1000}s;
  opacity: 1;

  ${props =>
    props.hiding &&
    css`
      opacity: 0;
    `}
`;

const mapStateToProps = state => {
  return {
    loginProps: {
      ...state
    }
  };
};

export default connect(mapStateToProps)(LoginViews);
