// import the actionTypes
import { actionTypes } from "./index.js";

// define the default state
export const defaultState = {
  status: false,
  profile: false
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.USER_STATUS:
      return Object.assign({}, state, {
        ...state,
        status: action.value
      });
    case actionTypes.USER_PROFILE:
      return Object.assign({}, state, {
        ...state,
        profile: action.value
      });
    case actionTypes.USER_LOGOUT:
      return Object.assign({}, state, {
        ...state
      });
    default:
      return state;
  }
}
