import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { Panel, P, Button, CircleTextCta, Spinner } from "components";
import { toRem, Query } from "styles/Utility";
import { Logout } from "utility/Authentication";
import { getVerificationURL } from "utility/StackApi/User";
import {
  featureEnums,
  featureIsEnabled,
  setFeatureFlaggingConfig,
} from "utility/FeatureFlagging";
import Locales from "locales";
import routePaths from "routes/routePaths";

const text__title = Locales("page.verification.title");
const text__descriptionHtml = Locales("page.verification.descriptionHtml");
const text__beginCta = Locales("page.verification.beginCta");
const text__descriptionHtml__disabled = Locales(
  "page.verification.descriptionHtmlJumioDisabled"
);
const text__beginCta__disabled = Locales(
  "page.verification.beginCtaJumioDisabled"
);
const text__generalError = Locales("general.errorMessage");

const keys = {
  VALIDATION_ERROR: "VALIDATION_ERROR",
  ALREADY_FAILED_JUMIO_VERIFICATION: "ALREADY_FAILED_JUMIO_VERIFICATION",
  THROTTLING_LIMIT: "THROTTLING_LIMIT",
};

const Verification = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [responseMessage, setResponseMessage] = useState(true);

  useEffect(() => {
    const init = async () => {
      try {
        await setFeatureFlaggingConfig();
      } catch (err) {}

      if (!featureIsEnabled(featureEnums.jumio)) {
        setIsLoading(false);
        setRedirectUrl("/");
        return;
      }

      try {
        const resp = await getVerificationURL();

        if (resp?.data?.redirectUrl) {
          setRedirectUrl(resp.data.redirectUrl);
        }
      } catch (error) {
        const codeKey = error?.response?.data?.codeKey;
        const message = error?.response?.data?.message;

        if (codeKey && message) {
          if (Object.values(keys).includes(codeKey)) {
            setResponseMessage(message);
          }
        } else {
          setResponseMessage(text__generalError);
        }
      }

      setIsLoading(false);
    };

    init();
  }, []);

  return (
    <Wrapper>
      <Panel.Container>
        <Panel.Header>
          <Panel.Heading>{text__title}</Panel.Heading>
        </Panel.Header>
        <Panel.Body padLarge>
          {isLoading ? (
            <Spinner />
          ) : (
            <>
              {redirectUrl && (
                <>
                  <P>
                    <TextDescription
                      dangerouslySetInnerHTML={{
                        __html: featureIsEnabled(featureEnums.jumio)
                          ? text__descriptionHtml
                          : text__descriptionHtml__disabled,
                      }}
                    />
                  </P>
                  {featureIsEnabled(featureEnums.jumio) ? (
                    <a
                      rel="noopener noreferrer"
                      href={redirectUrl}
                      target="_blank"
                    >
                      <Button mt={15}>{text__beginCta}</Button>
                    </a>
                  ) : (
                    <a
                      href={routePaths.login.path}
                      onClick={() => {
                        Logout();
                      }}
                    >
                      <Button mt={15}>{text__beginCta__disabled}</Button>
                    </a>
                  )}
                </>
              )}
              {!redirectUrl && responseMessage && (
                <>
                  <P>{responseMessage}</P>
                </>
              )}
            </>
          )}
        </Panel.Body>
      </Panel.Container>
      <Panel.Container hideColors>
        <Panel.Body hideColors padLarge>
          <CircleTextCta mt={10} onClick={Logout} icon="logout">
            Logout
          </CircleTextCta>
        </Panel.Body>
      </Panel.Container>
    </Wrapper>
  );
};

const TextDescription = styled.span`
  a {
    text-decoration: underline;
  }
`;

const Wrapper = styled.div`
  width: calc(100% - ${toRem(40)});
  margin: 0 auto;
  max-width: ${toRem(800)};
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: auto;
  max-height: 90vh;

  @media ${Query.mobileOnly} {
    position: static;
    margin-top: ${toRem(40)};
    transform: none;
  }
`;

export default Verification;
