import React, { useState } from "react";
import { showSidebar, showStatusBar } from "store/navigation";
import { Spinner, P, Span } from "components";
import styled, { keyframes, css } from "styled-components/macro";
import { pageLoading, pageTitle, pageRequiredStatus } from "store/page";
import store from "store";
import useEffectAsync from "utility/useEffectAsync";
import dataRetriever from "routes/dataRetriever";
import Locales from "locales";
import { connect } from "react-redux";
import { setFeatureFlaggingConfig } from "utility/FeatureFlagging";

const loadingContainerId = "loadingContainer";

//Text
const text__loading = Locales("loading.text");
const text__retry = Locales("loading.retry");

//First load
let firstLoad = true;

//Component
function PageInitializerWrapper(props) {
  const [showRetryMessage, setShowRetryMessage] = useState(false);
  const [showContent, setShowContent] = useState(false);

  //Function to show page
  function showPage() {
    store.dispatch(pageLoading(false));
    setShowContent(true);
  }

  //Wait until all of the data is loaded
  const requiresData = props.dataRequired ? true : false;

  useEffectAsync(async () => {
    //Show Loading text "retry" after 6 seconds
    setTimeout(() => {
      const loadingContainerEl = document.getElementById(loadingContainerId);
      if (loadingContainerEl !== null) {
        if (loadingContainerEl.length) {
          setShowRetryMessage(true);
        }
      }
    }, 6000);

    //Set page information to store
    store.dispatch(pageLoading(true));
    store.dispatch(pageTitle(props.pageTitle));
    store.dispatch(showSidebar(props.sidebar));
    store.dispatch(showStatusBar(props.statusBar));
    store.dispatch(showStatusBar(props.statusBar));
    store.dispatch(pageRequiredStatus(props.requiredStatus));

    //Show the page if no data is required
    if (!requiresData) return showPage();

    //Load content for page and then show it
    try {
      //If it's the first load,
      //also load feature flagging config
      await Promise.all([
        firstLoad ? setFeatureFlaggingConfig() : null,
        ...props.dataRequired.map((fn) => dataRetriever[fn]()),
      ]);

      //Set first load to false now
      firstLoad = false;

      //Show da page
      showPage();
    } catch (error) {
      return;
    }
  }, []);

  if (props.store.page.requestsAllowed) {
    return (
      <div>
        {showContent ? (
          <PageContainer>
            {React.Children.map(props.children, (child) =>
              React.cloneElement(child, { ...props })
            )}
          </PageContainer>
        ) : (
          <LoadingContainer
            show={requiresData && requiresData}
            id={loadingContainerId}
          >
            <Spinner />
            <LoadingMessage
              faded
              data-active={showRetryMessage ? "true" : undefined}
            >
              {text__loading}{" "}
              <LoadingMessageClick
                onClick={() => {
                  window.location.reload();
                }}
              >
                {text__retry}
              </LoadingMessageClick>
            </LoadingMessage>
          </LoadingContainer>
        )}
      </div>
    );
  } else {
    return <div />;
  }
}

const PageFadeIn = keyframes`
    from {
        opacity: 0;
        transform: translateY(20px);
    },
    to {
        opacity: 1;
        transform: translateY(0px);
    }
`;
const PageContainer = styled.div`
  animation: 0.4s ${PageFadeIn} 1 forwards;
  min-height: 100vh;
`;

const LoadingContainer = styled.div`
  padding-top: 40vh;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: none;

  ${(props) =>
    props.show &&
    css`
      display: block;
    `}
`;

const LoadingMessage = styled(P)`
  opacity: 0;
  pointer-events: none;
  transition: transform 0.3s, opacity 0.3s;
  transform: translateY(10px);

  &[data-active] {
    opacity: 1;
    pointer-events: all;
    transform: none;
  }
`;

const LoadingMessageClick = styled(Span)`
  text-decoration: underline;
  cursor: pointer;
`;

const mapStateToProps = (state) => {
  return {
    store: {
      ...state,
    },
  };
};

export default connect(mapStateToProps)(PageInitializerWrapper);
