export const actionTypes = {
  ACTIVE_LINK: "ACTIVE_LINK",
  OPEN_PARENT_NAV: "OPEN_PARENT_NAV",
  CLOSE_ALL_OPEN_NAVS: "CLOSE_ALL_OPEN_NAVS",
  SHOW_SIDEBAR: "SHOW_SIDEBAR",
  SHOW_STATUSBAR: "SHOW_STATUSBAR",
  OPEN_MOBILE_MENU: "OPEN_MOBILE_MENU"
};

export function activeLink(activeLink) {
  return {
    type: actionTypes.ACTIVE_LINK,
    value: activeLink
  };
}

export function openParentNav(parentTitle) {
  return {
    type: actionTypes.OPEN_PARENT_NAV,
    value: parentTitle
  };
}

export function closeAllOpenNavs(value) {
  return {
    type: actionTypes.CLOSE_ALL_OPEN_NAVS,
    value
  }
}

export function showSidebar(value) {
  return {
    type: actionTypes.SHOW_SIDEBAR,
    value
  }
}

export function showStatusBar(value) {
  return {
    type: actionTypes.SHOW_STATUSBAR,
    value
  }
}

export function openMobileMenu(value) {
  return {
    type: actionTypes.OPEN_MOBILE_MENU,
    value
  }
}
