import { css } from "styled-components";
import { toRem } from "styles/Utility";

export default css`
  ${(props) =>
    props.clickable &&
    css`
      cursor: pointer;
    `}

  ${(props) =>
    props.smaller &&
    css`
      font-size: ${toRem(14)};
    `}

  ${(props) =>
    props.small &&
    css`
      font-size: ${toRem(12)};
    `}

  ${(props) =>
    props.faded &&
    css`
      color: ${(props) => props.theme.colors.popFaded};

      ${(props) =>
        props.hover &&
        css`
          &:hover {
            color: ${(props) => props.theme.colors.pop};
          }
        `}
    `}

  ${(props) =>
    props.underline &&
    css`
      text-decoration: underline;
    `}

  ${(props) =>
    props.heavy &&
    css`
      font-weight: ${(props) => props.theme.type.bodyFontWeightHeavy};
    `}

  ${(props) =>
    props.light &&
    css`
      font-weight: ${(props) => props.theme.type.bodyFontWeightLight};
    `}

  ${(props) =>
    props.center &&
    css`
      text-align: center;
    `}

  ${(props) =>
    props.left &&
    css`
      text-align: left;
    `}

  ${(props) =>
    props.success &&
    css`
      color: ${(props) => props.theme.colors.success};
    `}

  ${(props) =>
    props.error &&
    css`
      color: ${(props) => props.theme.colors.error};
    `}
`;
