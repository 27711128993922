import { getUrlParameter } from "utility";

export const xApiKeyStorageString = "x-api-key";
export const tempApiStorageString = "temp-api-key";
export const pinApiKeyStorageString = "pin-api-key";
export const eTagStorageString = "e-tag";

//X Api Key
export const setXApiKey = xApiKey => sessionStorage.setItem(xApiKeyStorageString, xApiKey);
export const getXApiKey = () => sessionStorage.getItem(xApiKeyStorageString);

//Temp Api Key
export const setTempApiKey = tempApiKey => sessionStorage.setItem(tempApiStorageString, tempApiKey);
export const getTempApiKey = () => sessionStorage.getItem(tempApiStorageString);

//Pin Api Key
export const setPinApiKey = pinApiKey => sessionStorage.setItem(pinApiKeyStorageString, pinApiKey);
export const getPinApiKey = () => sessionStorage.getItem(pinApiKeyStorageString);

//Etag
export const setEtag = eTag => sessionStorage.setItem(eTagStorageString, eTag);
export const getEtag = () => sessionStorage.getItem(eTagStorageString);

//Check to see if URL contains Tokens to intercept
function urlContainsTokens() {
  if (
    getUrlParameter(xApiKeyStorageString) !== undefined &&
    getUrlParameter(tempApiStorageString) !== undefined &&
    getUrlParameter(pinApiKeyStorageString) !== undefined
  ) {
    return true;
  } else {
    return false;
  }
}

//If token passed to URL, intercept
export function getTokensFromURL() {
  const tokens = {
    xApiKey: getUrlParameter(xApiKeyStorageString),
    tempApiKey: getUrlParameter(tempApiStorageString),
    pinApiKey: getUrlParameter(pinApiKeyStorageString)
  };

  console.log(tokens);

  return tokens;
}

//If tokens are passed as URL parameters
//Save to session storage
export function setURLTokens() {
  if (!urlContainsTokens()) return;

  const tokens = getTokensFromURL();

  setXApiKey(tokens.xApiKey);
  setTempApiKey(tokens.tempApiKey);
  setPinApiKey(tokens.pinApiKey);
}
