export const BodySize = 16;

export const toRem = (pixels, suffix = true) =>
  `${pixels / BodySize}${suffix ? "rem" : ""}`;

export const ScreenSizes = {
  xs: 330,
  small: 768,
  medium: 1024,
  large: 1200,
};

export const Query = {
  screen: `only screen`,
  xs: `only screen and (min-width: ${toRem(ScreenSizes.xs)})`,
  xsDown: `only screen and (max-width: ${toRem(ScreenSizes.xs - 1)})`,
  small: `only screen and (min-width: ${toRem(ScreenSizes.small)})`,
  mobileOnly: `only screen and (max-width: ${toRem(ScreenSizes.small - 1)})`,
  medium: `only screen and (min-width: ${toRem(ScreenSizes.medium)})`,
  smallDown: `only screen and (max-width: ${toRem(ScreenSizes.medium - 1)})`,
  large: `only screen and (min-width: ${toRem(ScreenSizes.large)})`,
  mediumDown: `only screen and (min-width: ${toRem(ScreenSizes.large - 1)})`,
  landscape: `only screen and (orientation: landscape)`,
  portrait: `only screen and (orientation: portrait)`,
  retina: `(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dppx)`,
};

// https://stackoverflow.com/questions/5560248/programmatically-lighten-or-darken-a-hex-color-or-rgb-and-blend-colors
// Plus or Minus the colour to make it darker or lighter
export function ShadeColor(color, percent) {
  if (color.charAt(0) === "#") {
    color = color.slice(0);
  }

  var R = parseInt(color.substring(1, 3), 16);
  var G = parseInt(color.substring(3, 5), 16);
  var B = parseInt(color.substring(5, 7), 16);

  R = parseInt((R * (100 + percent)) / 100);
  G = parseInt((G * (100 + percent)) / 100);
  B = parseInt((B * (100 + percent)) / 100);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  var RR = R.toString(16).length === 1 ? "0" + R.toString(16) : R.toString(16);
  var GG = G.toString(16).length === 1 ? "0" + G.toString(16) : G.toString(16);
  var BB = B.toString(16).length === 1 ? "0" + B.toString(16) : B.toString(16);

  return "#" + RR + GG + BB;
}

/* ********************************************************************
User function when starting app, only fire once,
or else on every page render it will re-load fonts
******************************************************************** */
export function generateFontFaceString(fontManifestFile, woff2Only) {
  return fontManifestFile.map((font) => {
    const { fontFamily, eot, ttf, woff, woff2 = null } = font;

    return `
      @font-face {
        font-family: ${fontFamily};
        ${
          woff2Only
            ? `
          ${woff2 ? `src: url('${woff2}') format("woff2");` : ""}
        `
            : `
          ${eot ? `src: ${" "} url('${eot}');` : ""}
          ${
            eot
              ? `src: ${" "} url('${eot}?#iefix') format("embedded-opentype"),`
              : ""
          }
          ${woff2 ? `${" "} url('${woff2}') format("woff2"),` : ""}
          ${woff ? `${" "} url('${woff}') format("woff"),` : ""}
          ${ttf ? `${" "} url('${ttf}') format("truetype");` : ""}
        `
        }
        font-weight: normal;
        font-style: normal;
      }
    `;
  });
}

export function loadFontStyling() {
  const iconFontManifest = require("fonts/icons/manifest").default;
  const orgFontManifest = require(`org/fonts/fonts.js`).default;
  let css = "";
  css += generateFontFaceString(iconFontManifest, false);
  css += generateFontFaceString(orgFontManifest, true);
  const head = document.head || document.getElementsByTagName("head")[0];
  const style = document.createElement("style");

  head.appendChild(style);
  style.type = "text/css";
  style.appendChild(document.createTextNode(css));
}
