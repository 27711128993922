/* **************************************************
Define all routes in here
Where universal links and configuration will exist
************************************************** */

import publicRoutes from "./routes/public";
import authenticatedRoutes from "./routes/authenticated";

const routePaths = {
  ...publicRoutes,
  ...authenticatedRoutes,
};

export const getPathLink = (pathname) => routePaths[pathname].path;

export default routePaths;
