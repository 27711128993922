/* **************************************************
Define all public routes in here
************************************************** */

import Locales from "locales";
import Login from "pages/Login";
import QRCode from "pages/QRCode";

const text__loginTitle = Locales("page.login.title");
const text__qrCodeTitle = Locales("page.qrCode.title");

export default {
  login: {
    path: "/login",
    title: text__loginTitle,
    component: Login,
    exact: true,
    sidebar: false
  },
  qrCode: {
    path: "/qr-code",
    title: text__qrCodeTitle,
    component: QRCode,
    sidebar: false
  }
};
