import { setTempApiKey, setXApiKey, setPinApiKey } from "utility/Token";
import { Route } from "utility/Authentication";
import { animationDuration } from "components/Login/helper";
import { getUrlParameter } from "utility";

function setData(user) {
  setTempApiKey(user["temp-api-key"]);
  setXApiKey(user["x-api-key"]);
  setPinApiKey(user["pin-api-key"]);
}

//When routed to this page from the email
//Ensure the proper params are set in URL
//?code=[CODE]&email=[EMAIL]&tempkey=[TEMPKEY]
export function getVerify2faData() {
  const code = getUrlParameter("code");
  const email = getUrlParameter("email");
  const tempkey = getUrlParameter("tempkey");

  if (code && email && tempkey) {
    return { code, email, tempkey };
  } else {
    return false;
  }
}

export function handleSuccess({ user, props }) {
  //Set Data
  setData(user);

  //Route User
  Route({
    status: user.userStatus,
    history: props.history,
    delay: animationDuration + 40
  });
}
