const appStoreLink = "https://apps.apple.com/ca/app/stack-finance/id1305820272";
const googlePlayLink =
    "https://play.google.com/store/apps/details?id=com.stack.android";

const learnMoreLinks = {
    cardPin:
        "https://support.getstack.ca/hc/en-us/articles/360008328973-How-do-I-get-my-card-PIN-",
    privacyPolicy: "https://docs.getstack.ca/privacy-policy",
    termsOfService: "https://docs.getstack.ca/terms-of-service",
    cardholderAgreement: "https://docs.getstack.ca/cardholder-agreement",
    faq: "https://support.getstack.ca/hc/en-us",
};

const zendeskWidgetVisitorDepartment = "Stack";

export default {
    appStoreLink,
    googlePlayLink,
    learnMoreLinks,
    zendeskWidgetVisitorDepartment,
};
