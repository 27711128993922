import React from "react";
import { NavLink } from "react-router-dom";
import { Panel, Grid, P, Heading, Button } from "components";
import styled, { css } from "styled-components/macro";
import { toRem, Query } from "styles/Utility";
import { getRoundedDollarAmount, getCents, formatWithComma } from "utility/Money";
import Locales from "locales";
import has from "lodash/has";
import routePaths from "routes/routePaths";

const text__balance = Locales("page.home.balance");
const text__goalSavings = Locales("page.home.goalSavings");
const text__addFunds = Locales("page.home.addFunds");

export default function BalanceComponent(props) {
  return (
    <Panel.Container hideColors>
      {has(props, "store.money.balance") && (
        <BalanceBody hideColors>
          <Grid.Row alignCenter>
            <Grid.Col size={{ sm: 12 }}>
              <Grid.Row align="flex-end">
                <Grid.Col shrink>
                  <BalanceTitle type="h4" faded mReset>
                    {text__balance}
                  </BalanceTitle>
                </Grid.Col>
                <Grid.Col>
                  <BalanceTitleLine />
                </Grid.Col>
              </Grid.Row>
              <Grid.Row
                mt={{ sm: 15, md: 25 }}
                justify={{ md: "space-between" }}
                align={{ md: "center" }}
                direction={{ sm: "column", md: "row" }}
              >
                <Grid.Col shrink={{ md: true }} size={{ sm: 12 }}>
                  <BalanceGoalContainer>
                    {has(props, "store.money.balance.balance") && (
                      <BalanceContainer>
                        <BalanceDollarSign mReset>$</BalanceDollarSign>
                        <BalanceAmount mReset>
                          {getRoundedDollarAmount(props.store.money.balance.balance)}
                        </BalanceAmount>
                        <BalanceDecimalAmount mReset>{`.${getCents(
                          props.store.money.balance.balance
                        )}`}</BalanceDecimalAmount>
                      </BalanceContainer>
                    )}
                    {has(props, "store.money.goal.availableBalance") && (
                      <GoalsContainer>
                        <P mReset faded>
                          {text__goalSavings}
                        </P>
                        <GoalsAmountContainer>
                          <GoalsAmount mReset>{`$${formatWithComma(
                            props.store.money.goal.availableBalance
                          )}`}</GoalsAmount>
                        </GoalsAmountContainer>
                      </GoalsContainer>
                    )}
                  </BalanceGoalContainer>
                </Grid.Col>
                <Grid.Col shrink mt={{ sm: 25, md: 15 }}>
                  <AddFundsContainer>
                    <NavLink to={routePaths.addMoney.path}>
                      <Button>{text__addFunds}</Button>
                    </NavLink>
                  </AddFundsContainer>
                </Grid.Col>
              </Grid.Row>
            </Grid.Col>
          </Grid.Row>
        </BalanceBody>
      )}
    </Panel.Container>
  );
}

const BalanceBody = styled(Panel.Body)`
  @media ${Query.mobileOnly} {
    padding-left: 0;
    padding-right: 0;
  }
`;

const lineHeight = css`
  line-height: 0.7;
`;

const balanceContainer = css`
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

const balanceSuperscript = css`
  ${lineHeight}
  font-size: ${props => toRem(props.theme.home.dollarSuperscriptSize.desktop)};

  @media ${Query.mobileOnly} {
    font-size: ${props => toRem(props.theme.home.dollarSuperscriptSize.mobile)};
  }
`;

const BalanceTitle = styled(Heading)`
  margin-bottom: -5px;
`;

const BalanceTitleLine = styled.div`
  width: 100%;
  border-top: 1px solid ${props => props.theme.colors.bgLight};
`;

const BalanceGoalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media ${Query.mobileOnly} {
    justify-content: space-between;
    width: 100%;
  }
`;

const BalanceContainer = styled.div`
  ${balanceContainer}
`;

const BalanceDollarSign = styled(Heading)`
  ${balanceSuperscript}
`;

const BalanceAmount = styled(Heading)`
  ${lineHeight}
  font-size: ${props => toRem(props.theme.home.dollarAmountDesktop.desktop)};

  @media ${Query.mobileOnly} {
    font-size: ${props => toRem(props.theme.home.dollarAmountDesktop.mobile)};
  }
`;

const BalanceDecimalAmount = styled(Heading)`
  ${balanceSuperscript}
`;

const GoalsContainer = styled.div`
  border-left: 1px solid ${props => props.theme.colors.bgLight};
  padding: ${props => props.theme.gutter.rem} 0 ${props => props.theme.gutter.rem} ${toRem(25)};
  margin-left: ${toRem(25)};
  text-align: left;

  @media ${Query.mobileOnly} {
    border: none;
    padding: 0;
  }
`;

const GoalsAmountContainer = styled.div`
  ${balanceContainer}
  justify-content: flex-start;

  @media ${Query.mobileOnly} {
    justify-content: flex-end;
  }
`;

const GoalsAmount = styled.div``;

const AddFundsContainer = styled.div`
  @media ${Query.mobileOnly} {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
