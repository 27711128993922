/* eslint-disable */

(function () {
  let tag = document.createElement('script');
  tag.src =
    'https://github.com/Cognigy/WebchatWidget/releases/download/v2.20.0/webchat.js';
  tag.type = 'text/javascript';
  tag.async = 'true';
  let applyTag = document.getElementsByTagName('script')[0];
  applyTag.parentNode.insertBefore(tag, applyTag);
})();
