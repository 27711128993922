import { createStore } from "redux";

export const actionTypes = {
  SET_CURRENT_SCREEN: "SET_CURRENT_SCREEN",
  SET_EMAIL: "SET_EMAIL",
  SET_HIDING_SCREENS: "SET_HIDING_SCREENS"
};

export const screenEnums = {
  login: "LOGIN",
  twoFactorAuth: "2FA",
  resetPassword: "RESET_PASSWORD"
};

//values: LOGIN, 2FA, RESET_PASSWORD
export function setCurrentScreen(value) {
  return {
    type: actionTypes.SET_CURRENT_SCREEN,
    value: value
  };
}

export function hidingScreen(value) {
  return {
    type: actionTypes.SET_HIDING_SCREENS,
    value: value
  };
}

export function setEmail(value) {
  return {
    type: actionTypes.SET_EMAIL,
    value: value
  };
}

export const defaultState = {
  currentScreen: screenEnums.login,
  email: "",
  hidingScreens: false
};

function reducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.SET_CURRENT_SCREEN:
      return Object.assign({}, state, {
        ...state,
        currentScreen: action.value
      });
    case actionTypes.SET_EMAIL:
      return Object.assign({}, state, {
        ...state,
        email: action.value
      });
    case actionTypes.SET_HIDING_SCREENS:
      return Object.assign({}, state, {
        ...state,
        hidingScreens: action.value
      });
    default:
      return state;
  }
}

const store = createStore(reducer);

export default store;
