export const actionTypes = {
  SET_RECENT_TRANSACTIONS: "SET_RECENT_TRANSACTIONS"
};

//Value thats retrieved from /account/balance
export function setRecentTransactions(value) {
  return {
    type: actionTypes.SET_RECENT_TRANSACTIONS,
    value: value
  };
}