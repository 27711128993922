import { toRem, ShadeColor } from "./Utility";
import { MobileMenuButton } from "components";

const Gutter = 15;

export const bodyFont = "proxima-nova, sans-serif";

//Gutters
export const GutterSettings = {
  default: Gutter,
  small: Gutter * 0.5,
  px: `${Gutter}px`,
  pxSmall: `${Gutter / 2}px`,
  rem: toRem(Gutter),
  remSmall: toRem(Gutter * 0.5),
};

//Typography
export const Colors = {
  white: "#ffffff",
  black: "#000000",
  primary: "#E21E4A",
  primaryLight: "#DE486B",
  secondary: "#A92FFF",
  disabled: "#7E7E7E",
  bg: "#1B1B1D",
  bgDark: "#131515",
  bgLight: "#2F2F34",
  pop: "#D8D8D8",
  popFaded: "#7C808E",
  success: "#1a9668",
  warning: "#E4D857",
  error: "#D8000C",
  errorLight: "#e9b5b8",
};

//Typography
export const Typography = {
  bodyFont, //Body font
  bodyFontWeightLight: "300",
  bodyFontWeight: "400", // 600 = semibold, 400 = regular, 300 = light
  bodyFontWeightBold: "600",
  displayFont: `'${process.env.REACT_APP_ORG}', 'Helvetica', 'Arial', sans-serif`, //See fonts/[org_name]/fonts.js for config
  displayFontTextTransform: "uppercase",
  displayFontLineheight: 1,
  displayFontSettings: [
    {
      tag: "h1",
      size: toRem(38),
      mobileSize: toRem(26),
    },
    {
      tag: "h2",
      size: toRem(32),
      mobileSize: toRem(24),
    },
    {
      tag: "h3",
      size: toRem(24),
      mobileSize: toRem(21),
    },
    {
      tag: "h4",
      size: toRem(16),
      mobileSize: toRem(16),
    },
    {
      tag: "h5",
      size: toRem(14),
      mobileSize: toRem(14),
    },
    {
      tag: "h6",
      size: toRem(14),
      mobileSize: toRem(14),
    },
  ],
};

//Button
export const Button = {
  bg: `linear-gradient(to right, ${Colors.primary} 0%, ${Colors.primaryLight} 100%)`,
  bgHover: `linear-gradient(to right, ${ShadeColor(
    Colors.primary,
    10
  )} 0%, ${ShadeColor(Colors.primaryLight, 10)} 100%)`,
  fontSize: toRem(16),
  fontWeight: 400,
  color: Colors.pop,
  textTransform: "uppercase",
  fontFamily: Typography.displayFont,
  borderRadius: 40,
  secondaryBorderColor: Colors.primaryLight,
  secondaryTextColor: Colors.pop,
};

//Custom grid settings
export const GridSettings = {
  gridSize: 12,
};

//Panel
export const Panel = {
  headerBg: Colors.bgLight,
  borderColor: Colors.bgLight,
  bodyBg: Colors.bg,
  borderRadius: 10,
};

//Navigation
export const Navigation = {
  bg: Colors.bg,
  borderColor: Colors.bgLight,
  link: {
    activeColor: Colors.pop,
    activeIconColor: Colors.primaryLight,
    inactiveColor: Colors.popFaded,
    activeBg: "#252529",
  },
  logo: {
    src: require(`org/logo.svg`),
    deskHeight: 35,
    mobileWidth: 75,
  },
  mobileMenuButton: MobileMenuButton,
};

//Homepage
export const Home = {
  dollarSuperscriptSize: {
    desktop: 36,
    mobile: 23,
  },
  dollarAmountDesktop: {
    desktop: 82,
    mobile: 40,
  },
};

//Reward Description
export const Reward = {
  fadedText: "#909090",
  bodyText: "#656565",
  bg: "#ffffff",
};

//Material UI Theme
export const MaterialUITheme = {
  theme: "dark", //https://material-ui.com/customization/palette/ (supply light or dark)
};
