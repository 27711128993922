import React from "react";
import { Panel, Button, TransactionListItem } from "components";
import styled from "styled-components/macro";
import { toRem } from "styles/Utility";
import { FlexboxUtility } from "styles/Mixins";
import { NavLink } from "react-router-dom";
import Locales from "locales";
import SpacingWrapper from "./SpacingWrapper";
import has from "lodash/has";
import routePaths from "routes/routePaths";

const text__activityTitle = Locales("page.home.activityTitle");
const text__viewAll = Locales("page.home.viewAll");

export default function Transactions(props) {
  const hasResentTransationProp = has(props, "store.transactions.recent");
  const hasTransactions = hasResentTransationProp
    ? props.store.transactions.recent.length > 0
    : false;

  if (hasTransactions) {
    return (
      <SpacingWrapper>
        <Panel.Container>
          <Panel.Header icon={"graph"} iconColor={props.theme.colors.primaryLight}>
            <Panel.Heading>{text__activityTitle}</Panel.Heading>
          </Panel.Header>
          <Panel.Body noHorizontalPad noVerticalPad>
            <div>
              {props.store.transactions.recent.map((transaction, i) => (
                <TransactionListItem key={i} {...transaction} />
              ))}
            </div>
            <ButtonContainer>
              <NavLink to={routePaths.transactions.path}>
                <Button>{text__viewAll}</Button>
              </NavLink>
            </ButtonContainer>
          </Panel.Body>
        </Panel.Container>
      </SpacingWrapper>
    );
  } else {
    return <span />;
  }
}

const ButtonContainer = styled.div`
  ${FlexboxUtility.center}
  padding: ${props => props.theme.gutter.rem} ${props => props.theme.gutter.rem} ${toRem(25)};
`;
