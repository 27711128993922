import React from "react";
import { Options } from "./components";
import { Container, Page } from "components";
import Locales from "locales";

const text__title = Locales("page.addMoney.title");

function Dashboard(props) {
  return (
    <Page.Container>
      <Container>
        <Page.Title>{text__title}</Page.Title>
        <Options {...props} />
      </Container>
    </Page.Container>
  );
}

export default Dashboard;
