import Locales from "locales";
import EtransferRequest from "./loadingModule/EtransferRequest";
import CashLoad from "./loadingModule/CashLoad";
import Payroll from "./loadingModule/Payroll";
import BillPay from "./loadingModule/BillPay";
import CRADirectDeposit from "./loadingModule/CRADirectDeposit";

const text__etransferRequest_title = Locales(
  "page.addMoney.etransferRequest.title"
);
const text__etransfer_loadTime = Locales("page.addMoney.etransfer.loadTime");
const text__cashLoad_title = Locales("page.addMoney.cashLoad.title");
const text__cashLoad_loadTime = Locales("page.addMoney.cashLoad.loadTime");
const text__payroll_title = Locales("page.addMoney.payroll.title");
const text__payroll_loadTime = Locales("page.addMoney.payroll.loadTime");
const text__billPay_title = Locales("page.addMoney.billPay.title");
const text__billPay_loadTime = Locales("page.addMoney.billPay.loadTime");
const text__cra__title = Locales("page.addMoney.cra.title");
const text__cra__loadTime = Locales("page.addMoney.cra.loadTime");

export default [
  {
    title: text__etransferRequest_title,
    loadTime: text__etransfer_loadTime,
    highlighted: true,
    icon: "interac",
    contentComponent: EtransferRequest,
  },
  {
    title: text__cashLoad_title,
    loadTime: text__cashLoad_loadTime,
    highlighted: true,
    icon: "cash",
    contentComponent: CashLoad,
  },
  {
    title: text__payroll_title,
    loadTime: text__payroll_loadTime,
    icon: "money-bag",
    contentComponent: Payroll,
  },
  {
    title: text__billPay_title,
    loadTime: text__billPay_loadTime,
    icon: "bank",
    contentComponent: BillPay,
  },
  {
    title: text__cra__title,
    loadTime: text__cra__loadTime,
    icon: "canada",
    contentComponent: CRADirectDeposit,
  },
];
