import React from 'react';
import { Container, Page, CenterContent } from "components";
import Locales from "locales";

const text__comingSoonTitle = Locales('page.trends.comingSoonTitle');
const text__comingSoonSubtitle = Locales('page.trends.comingSoonSubtitle');

function TrendsPage(props) {
  return (
    <CenterContent fullHeight>
      <Container>
        <Page.ComingSoon
          icon={require('images/asset--trends.svg')}
          title={text__comingSoonTitle}
          subtitle={text__comingSoonSubtitle}
          downloadButtons
        />
      </Container>
    </CenterContent>
  );
}

export default TrendsPage;
