import React from "react";
import styled, {withTheme, css} from "styled-components/macro";
import {toRem} from "styles/Utility";
import {Heading as H } from "components";
import {Padding} from "styles/Mixins";
import {Query} from "styles/Utility";

export const horizontalPadding = 50;

const paddingOptions = css`
  ${props =>
    !props.noHorizontalPad &&
    css`
      padding: ${props => props.theme.gutter.rem} ${toRem(horizontalPadding)};

      @media ${Query.mobileOnly} {
        padding: ${props => props.theme.gutter.rem} ${props => props.theme.gutter.rem};
      }
    `
  }

  ${props =>
    props.noHorizontalPad &&
    css`
      padding-left: 0;
      padding-right: 0;
    `
  }

  ${props =>
    props.noVerticalPad &&
    css`
      padding-top: 0;
      padding-bottom: 0;
    `
  }
`

/* ==============================================
Container
============================================== */

function ContainerComponent(props) {
  return <StyledContainer {...props}>{props.children}</StyledContainer>
}

const StyledContainer = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: ${props => toRem(props.theme.panel.borderRadius)};
  border: 1px solid transparent;

  ${props => 
    !props.hideColors &&
    css`
      border-color: ${props => props.theme.panel.borderColor};
    `}
`

const Container = withTheme(ContainerComponent);


/* ==============================================
Header
============================================== */

function HeaderComponent(props) {
  return (
    <StyledHeader {...props}>
      {props.icon &&
        <StyledIcon className={`icon-${props.icon}`} />
      }
      {props.children}
    </StyledHeader>
  )
}

const StyledHeader = styled.header`
  min-height: ${toRem(50)};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  ${paddingOptions}

  ${props =>
    props.icon &&
    css`
      @media ${Query.mobileOnly} {
        padding-left: ${props => toRem(props.theme.gutter.default * 2)};
      }
    `
  }

  ${props => 
    !props.hideColors &&
    css`
      background-color: ${props => props.theme.panel.headerBg};
    `}

  ${props =>
    props.iconColor &&
    css`
      i {
        color: ${props => props.iconColor};
      }
    `
  }
`

const StyledIcon = styled.i`
  position: absolute;
  left: ${toRem(horizontalPadding * 0.5)};
  top: 50%;
  transform: translate(-50%, -50%);

  @media ${Query.mobileOnly} {
    left: ${props => props.theme.gutter.rem};
    font-size: ${toRem(12)};
  }
`

const Header = withTheme(HeaderComponent);

/* ==============================================
Header
============================================== */

function HeadingComponent(props) {
  return <StyledHeading as="h4">{props.children}</StyledHeading>
}

const StyledHeading = styled(H)`
  margin: 0;
`

const Heading = withTheme(HeadingComponent);

/* ==============================================
Body
============================================== */

function BodyComponent(props) {
  return <StyledBody {...props}>{props.children}</StyledBody>
}

const StyledBody = styled.div`
  ${paddingOptions}
  ${Padding}

  & > :first-child {
    margin-top: 0;
  }

  & > :last-child {
    margin-bottom: 0;
  }


  ${props => 
    props.padLarge &&
    css`
      padding-top: ${props => toRem(props.theme.gutter.default * 2)};
      padding-bottom: ${props => toRem(props.theme.gutter.default * 2)};
    `}

  ${props => 
    !props.hideColors &&
    css`
      background-color: ${props => props.theme.panel.bodyBg};
    `}
`

const Body = withTheme(BodyComponent);





export default {
  Container,
  Header,
  Heading,
  Body,
  horizontalPadding
}