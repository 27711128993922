import React from "react";
import { Panel, P, TextCta } from "components";
import SpacingWrapper from "./SpacingWrapper";
import Locales from "locales";
import org from "org";
import has from "lodash/has";

const text__title = Locales("page.settings.cardPin.title");
const text__desc = Locales("page.settings.cardPin.desc");
const text__learnMore = Locales("general.learnMore");

export default function PersonalInfo(props) {
  if (has(org, "learnMoreLinks.cardPin")) {
    return (
      <SpacingWrapper>
        <Panel.Container>
          <Panel.Header>
            <Panel.Heading>{text__title}</Panel.Heading>
          </Panel.Header>
          <Panel.Body padLarge>
            <P>{text__desc}</P>
            <a href={org.learnMoreLinks.cardPin} target="_blank" rel="noopener noreferrer">
              <TextCta mReset icon="caret-right">
                {text__learnMore}
              </TextCta>
            </a>
          </Panel.Body>
        </Panel.Container>
      </SpacingWrapper>
    );
  } else {
    return <span />;
  }
}
