import React from 'react';
import styled from "styled-components/macro"
import {toRem} from "styles/Utility";

function SpacingWrapper(props) {
  return (
      <Wrapper>
        {props.children}
      </Wrapper>
  );
}

const Wrapper = styled.div`
  margin: ${toRem(35)} auto;
`;

export default SpacingWrapper;