import React from 'react';
import { createGlobalStyle } from 'styled-components'
import { toRem } from "styles/Utility";
import { Query } from "styles/Utility";

const Typography = (props) => (
  <StyledTypography/>
);

const StyledTypography = createGlobalStyle`
  body {
    font-family: ${props => props.theme.type.bodyFont};
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: ${props => props.theme.type.displayFont};
    text-transform: ${props => props.theme.type.displayFontTextTransform};
    margin: ${props => toRem(props.theme.gutter.default)} 0;
  }

  ${props => props.theme.type.displayFontSettings.map((heading) => {
    const {tag, size, mobileSize} = heading;
    return `
      ${tag} {
        font-size: ${size};

        @media ${Query.smallDown} {
          font-size: ${mobileSize};
        }
      }
    `
  })}
`
export default Typography;
