import React from 'react';
import styled from "styled-components/macro";
import {toRem, Query} from "styles/Utility";


export default function Logo(props) {
  return (
    <LogoParentContainer>
      <LogoContainer />
    </LogoParentContainer>
  )
}

const LogoParentContainer = styled.div`
  height: ${toRem(100)};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${Query.smallDown} {
    display: none;
  }
`

const LogoContainer = styled.div`
  height: ${props => toRem(props.theme.navigation.logo.deskHeight)};
  width: 80%;
  background-image: url(${props => props.theme.navigation.logo.src});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
`
