import React from 'react';
import { createGlobalStyle, css } from 'styled-components';

const UtilityClasses = (props) => (
  <StyledUtilityClasses/>
);

const StyledUtilityClasses = createGlobalStyle`
  .text--center {
    text-align: center;
  }

  ${props =>
    props.theme.colors &&
    css`
      ${colorClasses(props.theme.colors)}
    `
  }
`

function colorClasses(themeColorsObject) {
  return Object.keys(themeColorsObject).map((color) => {
    const colorHex = themeColorsObject[color];
    const className = color;
  
    return (`
      .color--${className} {
        color: ${colorHex};
      }

      .bg--${className} {
        background-color: ${colorHex};
      }
    `)
  });
}

export default UtilityClasses;
