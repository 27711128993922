import React from 'react';
import Base from "./GlobalStyles/Base";
import Typography from "./GlobalStyles/Typography";
import Icons from "./GlobalStyles/Icons";
import UtilityClasses from "./GlobalStyles/UtilityClasses";

const BaseStyling = (props) => (
  <div>
    <Base/>
    <Typography/>
    <Icons/>
    <UtilityClasses/>
  </div>
);

export default BaseStyling;
