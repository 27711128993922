import React from "react";
import { Grid } from "components";
import styled from "styled-components";
import { toRem } from "styles/Utility";
import CardInfo from "./CardInfo";
import CardNotActivated from "./CardNotActivated";

export default function CardModule(props) {
  const { card } = props;

  return (
    <CardModuleContainer>
      <Grid.Row collapse>
        <Grid.Col
          display={{ sm: "none", md: "block" }}
          shrink={{ md: true }}
          size={{ sm: 12 }}
        >
          {/* TODO: get card asset in one function based on status supplied, use in this + ./MobileCard.js */}
          {card.type === "VIRTUAL" ? (
            <Card src={require(`org/cards/virtual.svg`)} />
          ) : (
            <Card src={require(`org/cards/physical.svg`)} />
          )}
        </Grid.Col>
        <Grid.Col>
          {card.status === "ISSUED_INACTIVE" ? (
            <CardNotActivated {...props} />
          ) : (
            <CardInfo {...props} />
          )}
        </Grid.Col>
      </Grid.Row>
    </CardModuleContainer>
  );
}

const CardModuleContainer = styled.div`
  margin-bottom: ${toRem(25)};
`;

const Card = styled.img`
  display: block;
  width: ${toRem(190)};
  height: ${toRem(285)};
`;
