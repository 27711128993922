export const actionTypes = {
  SET_FEATURE_FLAGGING: "SET_FEATURE_FLAGGING"
};

// Set the spread of response.data returned from /config
export function setFeatureFlagging(value) {
  return {
    type: actionTypes.SET_FEATURE_FLAGGING,
    value: value
  };
}