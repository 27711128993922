import React from "react";
import styled, { css } from "styled-components/macro";
import { Grid, Panel, P, Small, Span, FlexContainer } from "components";
import { toRem } from "styles/Utility";
import ReactTooltip from "react-tooltip";
import Locales from "locales";
import { Query } from "styles/Utility";
import { getMonthDayYear } from "utility/Time";
import { prettyFormat, getCurrencyName, formatWithComma } from "utility/Money";
import { getFormattedP2PTitle } from "utility/Transactions";
import getCountryISO2 from "utility/getCountryISO2";
const tooltipClass = "transaction-tooltip";
const emoji = require("node-emoji");

const text__fxRate = Locales("transaction.fxRate");
const text__billSplit = Locales("transaction.billSplit");
const text__pending = Locales("transaction.pending");

function TransactionListItem(props) {
  const { transaction } = props;
  const countryCode = transaction.countryCode ? transaction.countryCode : null;
  const fxRate = transaction.fxRate ? transaction.fxRate : null;

  let EmojiIcon = null;
  if (fxRate && countryCode) {
    const countryIso2 = getCountryISO2(countryCode.toUpperCase());

    if (countryIso2) {
      const emojiKey = `flag-${countryIso2.toLowerCase()}`;
      const hasEmoji = emoji.hasEmoji(emojiKey);

      EmojiIcon = () => (
        <Span>
          {hasEmoji ? (
            <>
              {emoji.get(emojiKey)}
              {` `}
            </>
          ) : (
            <>{`${countryCode} | `}</>
          )}
        </Span>
      );
    }
  }

  return (
    <TransactionContainer {...props} data-json={JSON.stringify(transaction)}>
      <ReactTooltip />
      <Grid.Row
        collapse
        align={{ sm: "center" }}
        justify={{ sm: "space-between" }}
      >
        <Grid.Col size={{ sm: 7 }}>
          <P mReset>
            {EmojiIcon && <EmojiIcon />}
            {transaction.isBillSplit && (
              <Span mr={5} faded>
                {text__billSplit}:{" "}
              </Span>
            )}
            <Span>{getFormattedP2PTitle(transaction)}</Span>
            {transaction.transactionStatus === "PENDING" && (
              <Span ml={5} faded>
                {text__pending}
              </Span>
            )}
          </P>
          <div>
            {transaction.transactionDate && (
              <Small mReset faded mr={5}>
                {getMonthDayYear(transaction.transactionDate)}
              </Small>
            )}
          </div>
        </Grid.Col>
        <Grid.Col size={{ sm: 5 }}>
          <FlexContainer
            align={{ sm: "flex-end" }}
            justify={{ sm: "flex-end" }}
          >
            {props.goal && (
              <Grid.Col noPad shrink>
                <StackUpAmount
                  smaller
                  mReset
                  faded
                  data-tip={Locales("transaction.stackUpInfo", {
                    roundUpAmount: prettyFormat(props.goal.amount),
                  })}
                  data-class={tooltipClass}
                  data-html={true}
                  data-effect="solid"
                >
                  +{prettyFormat(props.goal.amount)}
                </StackUpAmount>
              </Grid.Col>
            )}
            {transaction.transactionAmount && (
              <Grid.Col noPad shrink>
                <TransactionAmount
                  mReset
                  credit={
                    transaction.transactionDirection === "CREDIT" ? true : false
                  }
                >
                  {transaction.transactionDirection === "CREDIT" && "+"}
                  {prettyFormat(transaction.transactionAmount)}
                </TransactionAmount>
              </Grid.Col>
            )}
          </FlexContainer>
          {fxRate && (
            <FlexContainer
              align={{ sm: "flex-end" }}
              justify={{ sm: "flex-end" }}
            >
              <Grid.Col noPad shrink>
                <FxAmount
                  faded
                  mReset
                  small
                  data-tip={`${text__fxRate}: ${fxRate} CAD = 1 ${getCurrencyName(
                    transaction.fxCurrencyName
                  )}`}
                  data-class={tooltipClass}
                  data-html={true}
                  data-effect="solid"
                >
                  {`${formatWithComma(transaction.fxAmount)} ${getCurrencyName(
                    transaction.fxCurrencyName
                  )}
                  `}
                </FxAmount>
              </Grid.Col>
            </FlexContainer>
          )}
        </Grid.Col>
      </Grid.Row>
    </TransactionContainer>
  );
}

const FxAmount = styled(Span)`
  cursor: pointer;
`;

const TransactionAmount = styled(P)`
  ${(props) =>
    props.credit &&
    css`
      color: ${(props) => props.theme.colors.success};
    `}
`;

const TransactionContainer = styled.div`
  padding: ${(props) => props.theme.gutter.rem}
    ${toRem(Panel.horizontalPadding)};
  border-bottom: 1px solid ${(props) => props.theme.colors.bgLight};

  @media ${Query.mobileOnly} {
    padding-left: ${(props) => props.theme.gutter.remSmall};
    padding-right: ${(props) => props.theme.gutter.remSmall};
  }

  &:last-child {
    border-bottom: none;
  }

  .${tooltipClass} {
    max-width: ${toRem(280)};
    text-align: center;
    background-color: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.black};
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.08);
    pointer-events: all;

    &:after {
      border-top-color: ${(props) => props.theme.colors.white} !important;
    }
  }
`;

const StackUpAmount = styled(P)`
  margin-right: ${toRem(5)};
  cursor: pointer;
`;

export default TransactionListItem;
