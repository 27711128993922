import React from "react";
import { Panel, Grid, P, Button } from "components";
import styled from "styled-components/macro";
import { toRem } from "styles/Utility";
import { NavLink } from "react-router-dom";
import Locales from "locales";
import SpacingWrapper from "./SpacingWrapper";
import routePaths from "routes/routePaths";

const text__noFundsText = Locales("page.home.noFundsText");
const text__addFunds = Locales("page.home.addFunds");

export default function AddFundsMessage(props) {
  return (
    <SpacingWrapper>
      <Panel.Container>
        <Panel.Body>
          <Grid.Row alignCenter>
            <Grid.Col>
              <MessageContainer>
                <Message>{text__noFundsText}</Message>
                <NavLink to={routePaths.addMoney.path}>
                  <Button>{text__addFunds}</Button>
                </NavLink>
              </MessageContainer>
            </Grid.Col>
          </Grid.Row>
        </Panel.Body>
      </Panel.Container>
    </SpacingWrapper>
  );
}

const Message = styled(P)`
  text-align: center;
`;

const MessageContainer = styled.div`
  min-height: ${toRem(265)};
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`;
