import React from "react";
import { Switch, Route } from "react-router-dom";

export default function Routes(props) {
  if (!props.children) return <div />;

  if (props.children.length > 0) {
    return (
      <Route
        render={({ location }) => {
          const { key } = location;
          return (
            <Switch key={key} location={props.location}>
              {props.children}
            </Switch>
          );
        }}
      />
    );
  } else {
    return <div />;
  }
}
