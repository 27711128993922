import React from "react"
import { Icon } from "components";
import styled from "styled-components"
import {toRem} from "styles/Utility";
import {mobileOpenButtonId, mobileCloseButtonId, mobileNav} from "main/components/navigationSettings";

export default function MobileMenuButton(props) {
  return (
    <IconHolder>
      <Icon id={mobileCloseButtonId} style={{display: 'none'}} name="x" onClick={() => {mobileNav.close()}} />
      <Icon id={mobileOpenButtonId} name="hamburger" onClick={() => {mobileNav.open()}} />
    </IconHolder>
  )
}

const IconHolder = styled.div`
  text-align: center;
  width: ${toRem(23)};
  height: ${toRem(23)};
  line-height: ${toRem(23)};
  cursor: pointer;
`
