import { buildRequest } from "utility/StackApi";

/* ==========================================================================
Get Profile Information
========================================================================== */
export async function getBalance() {
  return buildRequest({
    verb: "get",
    endpoint: "/account/balance",
  });
}

export async function getGoal() {
  return buildRequest({
    verb: "get",
    endpoint: "/goal",
  });
}

//Submit e-transfer request
export async function etransferRequest(amount) {
  return buildRequest({
    endpoint: "/etransfer/request",
    verb: "post",
    data: { amount },
    headers: { "Content-Type": "application/json" },
  });
}

//Retrieve payroll info + etransfer info
export async function getBankingInfo() {
  return buildRequest({
    verb: "get",
    endpoint: "/bankAccount",
  });
}

//Get QR code from Loadhub
export async function getLoadhubQRCode() {
  return buildRequest({
    verb: "get",
    endpoint: "/loadhub/qrCode",
  });
}

//Get payroll information
export async function getPayrollInfo() {
  return buildRequest({
    verb: "get",
    endpoint: "/payroll",
  });
}
