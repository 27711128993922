import { setPinApiKey } from "utility/Token";

export function handleVerifyPinSuccess(pinApiKey) {
  setPinApiKey(pinApiKey);
  window.location.reload(); //TODO: Find way to reload content on page without reloading window
}

// Retrieve pathname from URL string
// https://stackoverflow.com/questions/736513/how-do-i-parse-a-url-into-hostname-and-path-in-javascript
export function getLocation(href) {
  var l = document.createElement("a");
  l.href = href;
  return l;
}

//Paths to ignore if receive 401 from
export const ignore401Paths = ["/verifyPin"];
