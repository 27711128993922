import React from "react";
import has from "lodash/has";
import { AddFundsMessage, Balance } from "pages/Home/components";

export default function Funds(props) {
  return (
    <div>
      {has(props, "store.money.balance.balance") && (
        <div>
          {props.store.money.balance.balance <= 0.0001 ? (
            <AddFundsMessage />
          ) : (
            <Balance store={props.store} />
          )}
        </div>
      )}
    </div>
  );
}
