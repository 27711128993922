import React from "react";
import styled from "styled-components/macro";
import {toRem} from "styles/Utility";
import {Text, Margins} from "styles/Mixins";

export default function Small(props) {
  return <StyledSmall {...props}>{props.children}</StyledSmall>
}

const StyledSmall = styled.small`
  ${Margins}
  ${Text}
  display: inline-block;
  font-size: ${toRem(12)};
`
