import React from "react";
import styled from "styled-components/macro";
import {Text, Margins} from "styles/Mixins";

function Span(props) {
  return <StyledSpan {...props}>{props.children}</StyledSpan>
}

const StyledSpan = styled.span`
  ${Margins}
  ${Text}
`

export default Span;
