import React from "react";
import axios from "axios";
import merge from "lodash/merge";
import has from "lodash/has";
import { getPinApiKey, getXApiKey, getTempApiKey } from "utility/Token";
import { isOpen, content, canExit } from "store/modal";
import { requestsAllowed } from "store/page";
import store from "store";
import { ConfirmPasscode } from "components";
import { handleVerifyPinSuccess } from "./helper";

function errorResponseHandler(error) {
  if (has(error, "response.data.code")) {
    const code = Number(error.response.data.code);
    //const path = getLocation(error.response.config.url).pathname;

    //This means the user has an expired pin-api-key
    //Re-Confirm to continue session
    //Unless page is on an unauthenticated route like /login
    //And It's not an ignored request URL
    if (code === 105 && store.getState().page.pageRequiredStatus) {
      store.dispatch(isOpen(true));
      store.dispatch(requestsAllowed(false));
      store.dispatch(canExit(false));
      store.dispatch(
        content(
          <ConfirmPasscode
            onSuccess={({ pinApiKey }) => {
              handleVerifyPinSuccess(pinApiKey);
            }}
          />
        )
      );
    }
  }

  return Promise.reject(error);
}

// Add a request interceptor
function handleRequest(config) {
  //Confirm These are only for th stack api url
  if (config.url.includes(process.env.REACT_APP_API_URL)) {
    config.headers["app-agent"] = "web";
    config.headers["app-version"] = "2.0.0";
    config.headers["org"] = "stack";
  }

  return config;
}

//Handle Requests
axios.interceptors.request.use(handleRequest);

//Handle Successful and Error responses
axios.interceptors.response.use((response) => response, errorResponseHandler);

/* ============================================================
Build Request
Options: 
-verb ('get', 'post' etc)
-endpoint ('/profile', '/status' etc)
-data (for post requests)
-headers (override existing headers - uses lodash merge method)
============================================================ */

export async function buildRequest(options) {
  const { verb, endpoint, data, headers, query } = options;
  const fullEndpoint = `${process.env.REACT_APP_API_URL}${endpoint}${
    query ? query : ""
  }`;
  const builtHeaders = buildHeaders(headers);

  let response;
  if (verb === "get") {
    response = await axios.get(fullEndpoint, { headers: builtHeaders });
  } else if (verb === "post") {
    response = await axios.post(fullEndpoint, data ? data : {}, {
      headers: builtHeaders,
    });
  } else if (verb === "put") {
    response = await axios.put(fullEndpoint, data ? data : {}, {
      headers: builtHeaders,
    });
  } else if (verb === "patch") {
    response = await axios.patch(fullEndpoint, data ? data : {}, {
      headers: builtHeaders,
    });
  }

  return response;
}

export function getDefaultRequestHeaders() {
  const pinApiKey = getPinApiKey();
  const xApiKey = getXApiKey();

  const headers = {
    "x-api-key":
      xApiKey === undefined || xApiKey === "undefined" ? "" : xApiKey,
    "pin-api-key":
      pinApiKey === undefined || pinApiKey === "undefined" ? "" : pinApiKey,
    "temp-api-key": getTempApiKey(),
    "Content-Type": "application/json",
  };

  return headers;
}

function buildHeaders(overrideHeaders) {
  const headerOptions = merge(getDefaultRequestHeaders(), overrideHeaders);
  return headerOptions;
}
