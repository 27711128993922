import React, { useReducer, useEffect } from "react";
import ModuleContainer from "./ModuleContainer";
import { FlexContainer, Button, P, Span } from "components";
import locales from "locales";
import { Input } from "@material-ui/core";
import { verify2FA, resend2FA } from "utility/StackApi/General";
import { hideScreens } from "./helper";
import tracking from "utility/tracking";
import store, { setEmail } from "./store";
import { setTempApiKey } from "utility/Token";

const text__2faTitle = locales("component.2fa.title");
const text__2faPlaceholder = locales("component.2fa.inputPlaceholder");
const text__2faResendCode = locales("component.2fa.resendCode");
const text__go = locales("general.go");
const text__codeResent = locales("component.2fa.codeResent");
const text__error = locales("error.error");
const text__loading = locales("general.loading");

let initialState = {
  verificationCode: "",
  loading: false,
  status: false,
  statusMessage: false
};

function reducer(state, action) {
  switch (action.type) {
    case "verificationCode":
      return { ...state, verificationCode: action.value };
    case "loading":
      return { ...state, loading: action.value };
    case "status":
      return { ...state, status: action.value };
    case "statusMessage":
      return { ...state, statusMessage: action.value };
    default:
      throw new Error();
  }
}

export default function LoginComponent(props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  function reset() {
    dispatch({ type: "status", value: "" });
    dispatch({ type: "statusMessage", value: undefined });
    dispatch({ type: "loading", value: false });
  }

  useEffect(() => {
    if (props.prePopulatedData) {
      const { code, email, tempkey } = props.prePopulatedData;
      if (code) initialState.verificationCode = code;
      if (email) store.dispatch(setEmail(email));
      if (tempkey) setTempApiKey(tempkey);
    }
  }, [props.prePopulatedData]);

  async function handleSubmit() {
    reset();
    const validationMessage = validation(state.verificationCode);
    if (validationMessage !== true) {
      dispatch({ type: "status", value: "ERROR" });
      dispatch({ type: "statusMessage", value: validationMessage });
      return;
    }

    dispatch({ type: "loading", value: true });

    try {
      const response = await verify2FA(state.verificationCode);
      hideScreens();

      if (props.successCallback) {
        tracking.event("Login");
        props.successCallback(response);
      }
    } catch (err) {
      reset();
      dispatch({ type: "status", value: "ERROR" });
      dispatch({ type: "statusMessage", value: text__error });
    }
  }

  async function handleResendCode() {
    reset();
    try {
      await resend2FA(props.loginProps.email);
      reset();
      dispatch({ type: "status", value: "SUCCESS" });
      dispatch({ type: "statusMessage", value: text__codeResent });
    } catch (err) {
      reset();
      dispatch({ type: "status", value: "ERROR" });
      dispatch({ type: "statusMessage", value: text__error });
    }
  }

  return (
    <ModuleContainer
      title={text__2faTitle}
      statusMessage={state.statusMessage}
      status={state.status}
    >
      <form
        onSubmit={e => {
          e.preventDefault();
        }}
      >
        <P smaller center>
          {locales("component.2fa.desc", { email: props.loginProps.email })}
        </P>
        <FlexContainer mb={35}>
          <Input
            placeholder={text__2faPlaceholder}
            inputProps={{ style: { textAlign: "center" } }}
            fullWidth
            type="code"
            value={state.verificationCode}
            onChange={e => {
              const { value } = e.target;
              dispatch({ type: "verificationCode", value });
            }}
          />
        </FlexContainer>
        <Button
          fullWidth
          mb={10}
          loading={state.loading ? "true" : undefined}
          onClick={handleSubmit}
        >
          <Span>{state.loading ? text__loading : text__go}</Span>
        </Button>
        <P small center clickable onClick={handleResendCode}>
          {text__2faResendCode}
        </P>
      </form>
    </ModuleContainer>
  );
}

function validation(code) {
  if (code.length === 0) return locales("error.cannotBeBlank", { input: locales("general.code") });

  return true;
}
