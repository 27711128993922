import React from "react";
import { CenterContent, Spinner } from "components";
import useEffectAsync from "utility/useEffectAsync";
import { promiseArray, fetchUserStatus } from "./helper";
import { setURLTokens } from "utility/Token";

async function fetchStatus() {
  //If redirected by another site and url has tokens,
  //Set them tokens then fetch with tokens
  setURLTokens();

  //Fetch status first for faster loading
  await fetchUserStatus();
  await Promise.all(promiseArray);
}

export default function Loading(props) {
  useEffectAsync(async () => {
    await fetchStatus();
  }, []);

  return (
    <CenterContent fullHeight fullWidth>
      <Spinner {...props} />
    </CenterContent>
  );
}
