// import the actionTypes
import { actionTypes } from "./index.js";

export const defaultState = {
  recent: false,
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.SET_RECENT_TRANSACTIONS:
      return Object.assign({}, state, {
        ...state,
        recent: action.value
      });
    default:
      return state;
  }
}
