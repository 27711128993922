/* **************************************************
Define all authenticated routes in here
************************************************** */

import Locales from "locales";
import Home from "pages/Home";
import Transactions from "pages/Transactions";
import Goals from "pages/Goals";
import Trends from "pages/Trends";
import SendRequest from "pages/SendRequest";
//import BillPay from "pages/BillPay";
//import ETransferOut from "pages/ETransferOut";
import AddMoney from "pages/AddMoney";
import Cards from "pages/Cards";
import Rewards from "pages/Rewards";
import Settings from "pages/Settings";
import Support from "pages/Support";
import Verification from "pages/Verification";

// Text / Titles
const text__homeTitle = Locales("navigation.home");
const text__transactionsTitle = Locales("navigation.transactions");
const text__goalsTitle = Locales("navigation.goals");
const text__trendsTitle = Locales("navigation.trends");
const text__sendRequestTitle = Locales("navigation.sendRequest");
//const text__billPayTitle = Locales("navigation.billPay");
//const text__eTransferOutTitle = Locales('navigation.eTransferOut');
const text__addMoneyTitle = Locales("navigation.addMoney");
const text__cardsTitle = Locales("navigation.cards");
const text__rewardsTitle = Locales("navigation.rewards");
const text__settingsTitle = Locales("navigation.settings");
const text__supportTitle = Locales("navigation.support");
const text__verificationTitle = Locales("page.verification.title");

export default {
  home: {
    path: "/dashboard",
    title: text__homeTitle,
    component: Home,
    requiredStatus: ["REGISTERED"],
    data: [
      "recentTransactions",
      "profile",
      "balance",
      "goal",
      "cardDetails",
      "rewards",
    ],
    sidebar: true,
    showStatusBar: false,
  },
  transactions: {
    path: "/transactions",
    title: text__transactionsTitle,
    component: Transactions,
    requiredStatus: ["REGISTERED"],
    sidebar: true,
    statusBar: true,
  },
  goals: {
    path: "/goals",
    title: text__goalsTitle,
    component: Goals,
    requiredStatus: ["REGISTERED"],
    sidebar: true,
    statusBar: true,
  },
  trends: {
    path: "/trends",
    title: text__trendsTitle,
    component: Trends,
    requiredStatus: ["REGISTERED"],
    sidebar: true,
    statusBar: true,
  },
  sendRequest: {
    path: "/send-request",
    title: text__sendRequestTitle,
    component: SendRequest,
    requiredStatus: ["REGISTERED"],
    sidebar: true,
    statusBar: true,
  },
  addMoney: {
    path: "/add-money",
    title: text__addMoneyTitle,
    component: AddMoney,
    requiredStatus: ["REGISTERED"],
    data: ["bankingInfo", "loadhubQRCode", "payrollInfo", "profile"],
    sidebar: true,
    statusBar: true,
  },
  cards: {
    path: "/cards",
    title: text__cardsTitle,
    component: Cards,
    requiredStatus: ["REGISTERED"],
    data: ["cardDetails"],
    sidebar: true,
    statusBar: true,
  },
  rewards: {
    path: "/rewards",
    title: text__rewardsTitle,
    component: Rewards,
    requiredStatus: ["REGISTERED"],
    data: ["rewards"],
    sidebar: true,
    statusBar: true,
  },
  settings: {
    path: "/settings",
    title: text__settingsTitle,
    component: Settings,
    requiredStatus: ["REGISTERED"],
    data: ["profile"],
    sidebar: true,
    statusBar: true,
  },
  support: {
    path: "/support",
    title: text__supportTitle,
    component: Support,
    requiredStatus: ["REGISTERED"],
    sidebar: true,
    statusBar: true,
  },
  verification: {
    path: "/verification",
    title: text__verificationTitle,
    component: Verification,
    requiredStatus: ["FAILED_KYC"],
    sidebar: false,
    statusBar: false,
  },
};
