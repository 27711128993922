import React from "react";
import styled from "styled-components/macro";
import org from "org";
import AppStoreImage from "images/asset--app-store.svg";
import GooglePlayImage from "images/asset--google-play.svg";
import tracking from "utility/tracking";

export default function DownloadButtons(props) {
  return (
    <StyledDownloadButtons {...props}>
      {org.appStoreLink && (
        <ButtonWrapper 
          rel="noopener noreferrer" 
          href={org.appStoreLink} 
          target="_blank"
          onClick={() => {
            tracking.event("iOS Download Button click");
          }}>
          <img src={AppStoreImage} alt="App Store" />
        </ButtonWrapper>
      )}
      {org.googlePlayLink && (
        <ButtonWrapper 
          rel="noopener noreferrer" 
          href={org.googlePlayLink} 
          target="_blank"
          onClick={() => {
            tracking.event("Google Play Download Button click");
          }}>
          <img src={GooglePlayImage} alt="Google Play" />
        </ButtonWrapper>
      )}
    </StyledDownloadButtons>
  );
}

const StyledDownloadButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonWrapper = styled.a`
  padding: 0 ${props => props.theme.gutter.remSmall};
`;
