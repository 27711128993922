import React from "react";
import { connect } from "react-redux";
import { BrowserRouter, Redirect, withRouter } from "react-router-dom";
import routePaths from "./routePaths";
import App from "main/App.js";
import RouteListener from "./RouteListener";
import Routes from "./Routes";

//To get location props in main app container
const AppComponent = withRouter(props => <App {...props} />);

function RoutesComponent(props) {
  if (!props.store) return <div />;

  return (
    <BrowserRouter>
      <RouteListener {...props} />
      <AppComponent store={props.store}>
        <Routes />
        <Redirect to={routePaths.login.path} push />
      </AppComponent>
    </BrowserRouter>
  );
}

const mapStateToProps = state => {
  return {
    store: {
      ...state
    }
  };
};

export default connect(mapStateToProps)(RoutesComponent);
