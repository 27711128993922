import {css} from "styled-components";

export const center = css`
  display: flex;
  align-items: center;
  justify-content: center;
`

export default {
  center
}