import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import styled from "styled-components";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { isOpen } from "store/modal";
import { toRem } from "styles/Utility";
import store from "store";

function close(props) {
  if(props.store.modal.canExit) {
    store.dispatch(isOpen(false))
  }
}

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: 'transparent',
    boxShadow: theme.shadows[5],
    borderRadius: 10,
    position: 'relative',
    overflow: 'hidden'
  },
  rel: {
    position: 'relative'
  }
}));

function RewardsPage(props) {
  const classes = useStyles();

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={props.store.modal.isOpen}
      onClose={() => {close(props)}}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.store.modal.isOpen}>
        <div className={classes.rel}>
          {props.store.modal.canExit &&
            <StyledClose onClick={() => {close(props)}} />
          }
          <div className={classes.paper}>
            <ModalContent {...props}>
              {props.store.modal.content}
            </ModalContent>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}

const ModalContent = styled.div`
  max-height: calc(100vh - ${props => toRem(props.theme.gutter.default * 2)});
  max-width: calc(100vw - ${props => toRem(props.theme.gutter.default * 2)});
  overflow: auto;
  position: relative;
`

const StyledClose = styled.i`
  width: ${toRem(25)};
  height: ${toRem(25)};
  cursor: pointer;
  background-color: ${props => props.theme.colors.black};
  color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.white};
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: ${toRem(25)};
  position: absolute;
  right: ${toRem(-5)};
  top: ${toRem(-5)};
  z-index: 50;

  &:after {
    content: 'x';
    display: block;
    font-style: normal;
    font-size: ${toRem(14)};
  }
`

export default RewardsPage;
