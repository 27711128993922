import { buildRequest } from "utility/StackApi";

export async function getRewards() {
  return buildRequest({
    verb: 'get',
    endpoint: '/offer',
    query: `?page=0&numberPerPage=50`
  })
}

export async function redeemReward(rewardId) {
  return buildRequest({
    verb: 'put',
    endpoint: `/offer/${rewardId}`
  })
}