import React from "react";
import { P, Table, TapToCopy, Span } from "components";
import Locales from "locales";
import has from "lodash/has";

const text__description = Locales("page.addMoney.billPay.description");
const text__payeeNameToSearch = Locales("page.addMoney.billPay.payeeNameToSearch");
const text__accountNumber = Locales("page.addMoney.billPay.accountNumber");
const text__disclaimer = Locales("page.addMoney.billPay.disclaimer");

export default function LoadingOption(props) {
  const { accountNumber } = props.money.bankingInfo;

  return (
    <div>
      <P faded mb={25}>
        {text__description}
      </P>
      <Table.Body>
        <Table.Tr>
          <Table.Td light faded>
            {text__payeeNameToSearch}
          </Table.Td>
          <Table.Td>
            {has(props, "featureFlagging.config.billPayLoadEmail") ? (
              <TapToCopy
                showIconOnHover
                copyText={props.featureFlagging.config.billPayLoadEmail}
                displayText={props.featureFlagging.config.billPayLoadEmail}
              />
            ) : (
              <Span faded>
                {Locales("general.couldNotRetrieve", {
                  value: Locales("general.payeeName").toLowerCase()
                })}
              </Span>
            )}
          </Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td light faded>
            {text__accountNumber}
          </Table.Td>
          <Table.Td>
            {accountNumber && (
              <TapToCopy showIconOnHover copyText={accountNumber} displayText={accountNumber} />
            )}
          </Table.Td>
        </Table.Tr>
      </Table.Body>
      <P smaller faded>
        {text__disclaimer}
      </P>
    </div>
  );
}
