import React, { useState, useEffect } from "react";
import { Heading, P, Button, FlexContainer, StateMessage } from "components";
import styled from "styled-components/macro";
import Locales from "locales";
import { toRem } from "styles/Utility";
import { Input } from "@material-ui/core";
import { verifyPin } from "utility/StackApi/General";
const inputId = "confirmInput";

const text__title = Locales("component.enterPasscode.title");
const text__defaultDesc = Locales("component.enterPasscode.defaultDesc");
const text__go = Locales("component.enterPasscode.go");
const text__invalid = Locales("component.enterPasscode.invalidMessage");

export default function ConfirmPasscode(props) {
  const [val, setVal] = useState("");
  const [loading, setLoading] = useState(false);
  const [showErr, setShowErr] = useState(false);

  useEffect(() => {
    document.getElementById(inputId).focus();
  }, []);

  function handleChange(e) {
    const val = e.target.value;
    setVal(val);
  }

  async function handleSubmit(e, val) {
    e.preventDefault();
    setLoading(true);
    setShowErr(false);

    try {
      const response = await verifyPin({ pin: val });

      if (props.onSuccess) {
        const pinApiKey = response.data["pin-api-key"];
        props.onSuccess({ pinApiKey, passcode: val });
      }
    } catch (error) {
      setShowErr(true);
    }
    setLoading(false);
  }

  return (
    <PasscodeContainer
      onSubmit={e => {
        handleSubmit(e, val);
      }}
    >
      <Heading type="h4" center>
        {text__title}
      </Heading>
      <P smaller faded center>
        {props.descriptionText ? (
          <span>{props.descriptionText}</span>
        ) : (
          <span>{text__defaultDesc}</span>
        )}
      </P>
      <Input
        id={inputId}
        placeholder={text__title}
        inputProps={{ style: { textAlign: "center" } }}
        type="password"
        fullWidth
        value={val}
        onChange={handleChange}
      />
      <FlexContainer align="center" justify="center">
        <Button loading={loading ? "true" : undefined} small mt={15}>
          {text__go}
        </Button>
      </FlexContainer>
      {showErr && (
        <StateMessage smaller error mt={15}>
          {text__invalid}
        </StateMessage>
      )}
    </PasscodeContainer>
  );
}

const PasscodeContainer = styled.form`
  background-color: ${props => props.theme.colors.bg};
  color: ${props => props.theme.colors.pop};
  padding: ${props => toRem(props.theme.gutter.default * 2)} ${props => props.theme.gutter.rem};
  width: ${toRem(300)};

  input {
    text-algin: center;
  }
`;
