import React from "react";
import styled from "styled-components";
import { Text } from "styles/Mixins";

export default function Icon(props) {
  return <StyledI {...props} className={`${props.className ? props.className : ''} icon-${props.name ? props.name : ''}`} />
}

const StyledI = styled.i`
  ${Text}
`