import { Logout } from "utility/Authentication";
import { getPathLink } from "routes/routePaths";
import Locales from "locales";

const text__home = Locales("navigation.home");
const text__activity = Locales("navigation.activity");
const text__transactions = Locales("navigation.transactions");
const text__goals = Locales("navigation.goals");
const text__trends = Locales("navigation.trends");
const text__addMoney = Locales("navigation.addMoney");
const text__transfer = Locales("navigation.transfer");
const text__sendRequest = Locales("navigation.sendRequest");
const text__cards = Locales("navigation.cards");
const text__rewards = Locales("navigation.rewards");
const text__settings = Locales("navigation.settings");
const text__support = Locales("navigation.support");
const text__logout = Locales("navigation.logout");

export default [
  {
    title: text__home,
    icon: "home--outline",
    iconActive: "home",
    path: getPathLink("home"),
    sublinks: [],
  },
  {
    title: text__activity,
    icon: "transactions--outline",
    iconActive: "transactions",
    path: false,
    sublinks: [
      {
        title: text__transactions,
        path: getPathLink("transactions"),
      },
      {
        title: text__goals,
        path: getPathLink("goals"),
      },
      {
        title: text__trends,
        path: getPathLink("trends"),
      },
    ],
  },
  {
    title: text__addMoney,
    icon: "money--outline",
    iconActive: "money",
    path: getPathLink("addMoney"),
    sublinks: [],
  },
  {
    title: text__transfer,
    icon: "transfer",
    iconActive: "transfer",
    path: false,
    sublinks: [
      {
        title: text__sendRequest,
        path: getPathLink("sendRequest"),
      },
    ],
  },
  {
    title: text__cards,
    icon: "cards--outline",
    iconActive: "cards",
    path: getPathLink("cards"),
    sublinks: [],
  },
  {
    title: text__rewards,
    icon: "star--outline",
    iconActive: "star",
    path: getPathLink("rewards"),
    sublinks: [],
  },
  {
    title: text__settings,
    icon: "gear--outline",
    iconActive: "gear",
    path: getPathLink("settings"),
    sublinks: [],
  },
  {
    title: text__support,
    icon: "message--outline",
    iconActive: "message",
    path: getPathLink("support"),
    sublinks: [],
  },
  {
    title: text__logout,
    icon: "logout",
    iconActive: "logout",
    sublinks: [],
    mobileOnly: true,
    onClick: Logout,
  },
];
