import React from "react";
import has from "lodash/has";
import Locales from "locales";

const orgName = Locales('global.orgName');

function updateMeta(pageName) {
  const titleTag = document.getElementsByTagName('TITLE')[0];
  titleTag.innerHTML = `${orgName} ${pageName ? `| ${pageName}` : ''}`;
}

export default function UpdateHeadMeta(props) {
  has(props, 'page.pageTitle') ? updateMeta(props.page.pageTitle) : updateMeta('');
  
  return <span/>
}