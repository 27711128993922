import { getAvailableLanguages } from "locales";

/* ========================================
Get language cookie name
======================================== */

export const languageCookieName = `${process.env.REACT_APP_ORG}_acc_lang`;

/* ========================================
Get language shortform
Uses get cookie by name function
https://stackoverflow.com/questions/10730362/get-cookie-by-name
======================================== */

export function getSelectedLanguage() {
  var value = "; " + document.cookie;
  var parts = value.split("; " + languageCookieName + "=");
  if (parts.length === 2) {
    return parts
      .pop()
      .split(";")
      .shift();
  } else {
    return getAvailableLanguages()[0].short;
  }
}
