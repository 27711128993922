import React from "react";
import LoadingProfile from "./components/LoadingProfile";
import Sidebar from "main/components/Sidebar";
import {
    sidebarWidth,
    mobileNavOpenClass,
    masterContainerId,
    mobileNav,
} from "main/components/navigationSettings";
import { Modal } from "components";
import 'components/Cognigy/cognigy.css';
import 'components/Cognigy/snippet/cognigy.snippet';
import { startCognigyChat } from "components/Cognigy/CognigyChat";
import Navigation from "main/components/Navigation";
import Routes from "./components/Routes";
import styled, { css, withTheme } from "styled-components";
import { Query } from "styles/Utility";
import UpdateHeadMeta from "main/components/UpdateHeadMeta";

const NavigationModule = withTheme(Navigation); //Need theme props
//
function App(props) {
    const { status, profile } = props.store.user;
    const { mobileMenuOpen } = props.store.navigation;
    if (profile) {
        startCognigyChat(props);
    }
    if (status) {
        return (
            <MasterContainer
                id={masterContainerId}
                className={`${mobileMenuOpen ? mobileNavOpenClass : ""}`}
            >
                <UpdateHeadMeta {...props.store} />
                <Modal {...props} />
                <Sidebar {...props} />
                <NavigationModule {...props} />
                <MainContainer
                    {...props}
                    onClick={() => {
                        mobileNav.close();
                    }}
                >
                    <Routes {...props} />
                </MainContainer>
            </MasterContainer>
        );
    } else {
        return (
            <MasterContainer>
                <LoadingProfile {...props} />
            </MasterContainer>
        );
    }
}

const MasterContainer = styled.div`
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: flex-end;

    &.${mobileNavOpenClass} {
        overflow: hidden;
        height: 100vh;
    }
`;

const MainContainer = styled.main`
    width: 100%;
    position: relative;
    overflow: hidden;

    ${(props) =>
        props.store.navigation.showSidebar &&
        css`
            width: calc(100% - ${sidebarWidth}px);
        `}

    @media ${Query.smallDown} {
        width: 100% !important;
        transition: transform 0.5s, opacity 0.5s;
        will-change: transform, opacity;
    }

    ${MasterContainer}.${mobileNavOpenClass} & {
        transform: translateX(-10%);
        opacity: 0.6;
    }
`;

export default App;
