import { buildRequest } from "utility/StackApi";
import { getProfile } from "utility/StackApi/User";
import startOfMonth from "date-fns/startOfMonth";
import format from "date-fns/format";
import subMonths from "date-fns/subMonths";
import parse from "date-fns/parse";
import isBefore from "date-fns/isBefore";

/* ============================================================
Get recent transactions
-Just returns 5 recent transactions in the past 3 months
============================================================ */

export async function getRecentTransactions() {
  return buildRequest({
    verb: "get",
    endpoint: "/stackStory/you/recent",
    query: `?numTransactions=5&numMonths=3`,
  });
}

/* ============================================================
Return at least 15 values to the user
So if your first call for the first month retrieved 5 values
You would make a second call for the second month to see if there were more
------
don't stop making calls until:
-you've retrieved 15 values
-you've reached the join date
============================================================ */
const timeFormat = "yyyy-MM-dd";
const minimumToReturn = 10;
const firstOfCurrentMonth = format(startOfMonth(new Date()), timeFormat);
let transactionsAccum = [];
let joinDate = "";
let monthStoppedOn = ""; // What month did we 'leave off on'

function getPreviousMonth(month) {
  const currentMonthISO = parse(month, timeFormat, new Date());
  const prevMonthISO = subMonths(new Date(currentMonthISO), 1);
  const formattedPrevMonth = format(
    startOfMonth(new Date(prevMonthISO)),
    timeFormat
  );

  return formattedPrevMonth;
}

export async function getTransactionsByMonth(monthToFetch) {
  return buildRequest({
    verb: "get",
    endpoint: "/stackStory/you",
    query: `?startDate=${monthToFetch}`,
  });
}

async function transactionFetchLoop(monthToFetch) {
  // If month past start date, exit

  const monthToFetchISO = parse(monthToFetch, timeFormat, new Date());
  const joinDateISO = parse(joinDate, timeFormat, new Date());

  if (isBefore(monthToFetchISO, joinDateISO)) return false;

  const response = await getTransactionsByMonth(monthToFetch);
  const transactions = response.data;

  // If nothing is returned, run loop again
  if (transactions.length < 1) {
    return await transactionFetchLoop(getPreviousMonth(monthToFetch), joinDate);
  }

  // If something is returned, add it to the accumulator
  // If accumulator is less than 15, run again
  transactionsAccum = [...transactionsAccum, ...transactions];

  if (transactionsAccum.length >= minimumToReturn) {
    const cloneTransactionsAccum = [...transactionsAccum];
    monthStoppedOn = getPreviousMonth(monthToFetch);
    transactionsAccum = [];
    return cloneTransactionsAccum;
  } else {
    return await transactionFetchLoop(getPreviousMonth(monthToFetch), joinDate);
  }
}

export async function getTransactions(firstFetch) {
  let monthToFetch = monthStoppedOn;

  if (firstFetch) {
    const response = await getProfile();
    joinDate = format(
      startOfMonth(new Date(response.data.joinDate)),
      timeFormat
    );
    monthToFetch = firstOfCurrentMonth;
  }

  const transactions = await transactionFetchLoop(monthToFetch, joinDate);

  return transactions;
}
