
export const actionTypes = {
  USER_STATUS: "USER_STATUS",
  USER_PROFILE: "USER_PROFILE",
  USER_LOGOUT: "USER_LOGOUT"
};

// actionCreators
export function userStatus(userStatus) {
  return {
    type: actionTypes.USER_STATUS,
    value: userStatus
  };
}

//set when retrieved from /profile endpoint
export function setUserProfile(userProfile) {
  return {
    type: actionTypes.USER_PROFILE,
    value: userProfile
  };
}

export function userLogout() {
  return {
    type: actionTypes.USER_LOGOUT,
    value: true
  };
}
