import {css} from "styled-components";
import ResponsivePropGenerator from "./ResponsivePropGenerator";

export default css`
  display: flex;

  ${ResponsivePropGenerator({
    cssProperty: 'align-items',
    cssUnit: 'none',
    propName: 'align', 
    expectedType: 'string'
  })}

  ${ResponsivePropGenerator({
    cssProperty: 'flex-direction',
    cssUnit: 'none',
    propName: 'direction', 
    expectedType: 'string'
  })}

  ${ResponsivePropGenerator({
    cssProperty: 'justify-content',
    cssUnit: 'none',
    propName: 'justify', 
    expectedType: 'string'
  })}

  ${ResponsivePropGenerator({
    cssProperty: 'display',
    cssUnit: 'none',
    propName: 'flex', 
    expectedType: 'string'
  })}
`