import Locales from "locales";

/* =========================================
Supply default message;
ACTIVE, ISSUED_INACTIVE, INACTIVE
To receive formatted message
========================================= */

const text__active = Locales('card.active');
const text__notActivated = Locales('card.notActivated');
const text__frozen = Locales('card.frozen');

export function getFormattedStatus(responseStatus) {
  switch(responseStatus) {
    case 'ACTIVE':
      return text__active;
    case 'ISSUED_INACTIVE':
      return text__notActivated;
    case 'INACTIVE':
      return text__frozen;
    default:
      return 'Inactive'
  }
}

export function formatCardNumber(cardNumberString) {
  return cardNumberString.replace(/\W/gi, '').replace(/(.{4})/g, '$1 ');
}