import React from "react";
import styled from "styled-components/macro";
import { FlexContainer, Small, Heading, Span } from "components";
import { NavLink } from "react-router-dom";
import { toRem } from "styles/Utility";
import { BgImage } from "styles/Mixins";
import has from "lodash/has";
import routePaths from "routes/routePaths";
import { prettyFormat } from "utility/Money";
import { mobileNav } from "main/components/navigationSettings";

export default function Navigation(props) {
  const hasProfile = has(props, "store.user.profile.id");
  const profile = hasProfile ? props.store.user.profile : false;
  const hasMoney = has(props, "store.money.balance.balance");
  const money = hasMoney ? props.store.money.balance.balance : false; //0 = false, so logic for that below

  if (hasProfile) {
    return (
      <FlexContainer
        flex="inline-flex"
        align="center"
        justify="center"
        onClick={() => {
          mobileNav.close();
        }}
      >
        <NavLink to={routePaths.settings.path}>
          <ProfilePhoto
            image={
              profile.thumbnailProfilePictureUrl
                ? profile.thumbnailProfilePictureUrl
                : require("images/asset--user.png")
            }
          />
        </NavLink>
        <div>
          {profile.username && (
            <NavLink to={routePaths.settings.path}>
              <Small mReset>@{profile.username}</Small>
            </NavLink>
          )}
          {(money || money === 0) && (
            <NavLink to={routePaths.transactions.path}>
              <Heading type="h3" mReset>
                {money === 0 ? <Span>$0</Span> : <Span>{prettyFormat(money)}</Span>}
              </Heading>
            </NavLink>
          )}
        </div>
      </FlexContainer>
    );
  } else {
    return <span />;
  }
}

const ProfilePhoto = styled.div`
  width: ${toRem(42)};
  height: ${toRem(42)};
  ${BgImage("cover")}
  background-image: url(${props => props.image});
  border-radius: ${toRem(42)};
  margin-right: ${toRem(5)};
  border: 2px solid ${props => props.theme.colors.pop};
`;
