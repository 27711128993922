import React, { useState } from "react";
import styled, { css } from "styled-components/macro";
import { P, Button } from "components";
import { toRem } from "styles/Utility";
import { BgImage } from "styles/Mixins";
import Locales from "locales";
import { redeemReward } from "utility/StackApi/Rewards";
import tracking from "utility/tracking";

async function handleRedeem(rewardId, setReward, setLoading, onSuccessCallback) {
  setLoading(true);
  try {
    const response = await redeemReward(rewardId);
    setReward({ ...response.data });
    setLoading(false);

    //GTM Tracking
    if (response.data.id) {
      tracking.event("Activate offer", { offerId: response.data.id });
    }

    //Successfull callback
    if (onSuccessCallback) onSuccessCallback();
  } catch (error) {
    setLoading(false);
  }
}

/* ======================================================================
Listed Item
====================================================================== */

const text__activateOffer = Locales("reward.activateOffer");
const text__activated = Locales("reward.activated");

function RewardDescriptionModule(props) {
  const [reward, setReward] = useState({ ...props });
  const [loading, setLoading] = useState(false);

  return (
    <RewardInfoContainer>
      {reward.imageLarge && <RewardImageContainer image={reward.imageLarge}></RewardImageContainer>}
      {reward.subscriberCount && (
        <PeopleUsingRewardText smaller center>
          {Locales("reward.usingThisOffer", { number: reward.subscriberCount })}
        </PeopleUsingRewardText>
      )}
      <MasterDescContainer>
        {reward.description && (
          <Description>
            <P
              smaller
              mReset
              mb={25}
              dangerouslySetInnerHTML={{
                __html: reward.description.replace(/(?:\r\n|\r|\n)/g, "<br>")
              }}
            ></P>
          </Description>
        )}
      </MasterDescContainer>
      <ButtonContainer>
        {/* If is not activated */}
        {reward.id && !reward.subscribed && (
          <StyledButton
            loading={loading ? "true" : undefined}
            onClick={() => {
              handleRedeem(reward.id, setReward, setLoading, props.onActivate);
            }}
          >
            {text__activateOffer}
          </StyledButton>
        )}
        {/* If is cashback offer and is activated */}
        {reward.subscribed && !reward.linkButtonText && (
          <StyledButton loading={loading ? "true" : undefined} disabled>
            {text__activated}
          </StyledButton>
        )}
        {/* If is online offer and is activated */}
        {reward.subscribed && reward.linkButtonText && (
          <a href={reward.link} target="_blank" rel="noopener noreferrer">
            <StyledButton loading={loading ? "true" : undefined}>
              {reward.linkButtonText}
            </StyledButton>
          </a>
        )}
      </ButtonContainer>
    </RewardInfoContainer>
  );
}

const gradientHeight = 60;

const PeopleUsingRewardText = styled(P)`
  color: ${props => props.theme.reward.fadedText};
`;

const RewardInfoContainer = styled.div`
  max-width: ${toRem(500)};
  width: 100%;
  background-color: ${props => props.theme.reward.bg};
  color: ${props => props.theme.reward.bodyText};
`;

const RewardImageContainer = styled.div`
  ${props =>
    props.image &&
    css`
      width: 100%;
      height: ${toRem(220)};
      margin-bottom: ${toRem(20)};
      ${BgImage("cover")}
      background-image: url(${props.image});
    `}
`;

const MasterDescContainer = styled.div`
  position: relative;

  &:after {
    content: "";
    display: block;
    width: 100%;
    height: ${toRem(gradientHeight)};
    background: linear-gradient(
      0deg,
      ${props => props.theme.reward.bg}FF 0%,
      ${props => props.theme.reward.bg}00 100%
    );
    position: absolute;
    bottom: 0;
    left: 0;
    pointer-events: none;
  }
`;

const Description = styled.div`
  max-height: ${toRem(165)};
  overflow: auto;
  position: relative;
  padding: 0 ${props => props.theme.gutter.rem} ${toRem(gradientHeight)};
`;

const ButtonContainer = styled.div`
  text-align: center;
`;

const StyledButton = styled(Button)`
  margin: ${props => props.theme.gutter.rem};
`;

export default RewardDescriptionModule;
