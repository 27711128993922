// import the actionTypes
import { actionTypes } from "./index.js";

export const defaultState = {
  details: false,
  physicalCardDetails: false,
  physicalCardPin: false,
  virtualCardDetails: false,
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.SET_CARD_DETAILS:
      return Object.assign({}, state, {
        ...state,
        details: action.value
      });
    case actionTypes.SET_PHYSICAL_CARD_DETAILS:
        return Object.assign({}, state, {
          ...state,
          physicalCardDetails: action.value
        });
    case actionTypes.SET_PHYSICAL_CARD_PIN:
        return Object.assign({}, state, {
          ...state,
          physicalCardPin: action.value
        });
    case actionTypes.SET_VIRTUAL_CARD_DETAILS:
        return Object.assign({}, state, {
          ...state,
          virtualCardDetails: action.value
        });
    default:
      return state;
  }
}
