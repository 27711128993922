import React from "react";
import { Icon, P } from "components";
import styled, { css } from "styled-components/macro";
import { toRem } from "styles/Utility";
import Locales from "locales";
import { activeClass, handleOptionSelect } from "./helper";
import { Query } from "styles/Utility";

const text__loadTime = Locales("page.addMoney.loadTime");

export default function LoadingOption(props) {
  return (
    <OptionButton
      onClick={() => {
        handleOptionSelect(props.title);
      }}
      data-load-option={props.title}
      className={`${props.isActive ? activeClass : ""}`}
    >
      <OptionButtonInner>
        <TitleInfo>
          <StyledIcon name={props.icon} />
          <P smaller mReset mt={10}>
            {props.title}
          </P>
        </TitleInfo>
        <LoadTimeContainer>
          <P smaller mReset>
            {text__loadTime}: <LoadTime highlighted={props.highlighted}>{props.loadTime}</LoadTime>
          </P>
        </LoadTimeContainer>
      </OptionButtonInner>
      <Triangle />
    </OptionButton>
  );
}

const OptionButton = styled.div`
  padding-right: ${props => props.theme.gutter.remSmall};
  padding-left: ${props => props.theme.gutter.remSmall};
  flex: 1;
  text-align: center;

  @media ${Query.mobileOnly} {
    display: none;
  }
`;

const Triangle = styled.div`
    width: 0; 
    height: 0; 
    display: inline-block;
    border-left: ${toRem(5)} solid transparent;
    border-right: ${toRem(5)} solid transparent;
    border-top: ${toRem(5)} solid ${props => props.theme.colors.pop};
    opacity: 0;
    margin: ${toRem(15)} 0;
    pointer-events: none;
    transition: opacity 0.3s;

    ${OptionButton}.${activeClass} & {
        opacity: 1;
    }
`;

const OptionButtonInner = styled.div`
    background-color: ${props => props.theme.panel.bodyBg};
    border: 2px solid ${props => props.theme.panel.borderColor};
    border-radius: ${props => toRem(props.theme.panel.borderRadius)};
    opacity: 0.6;
    overflow: hidden;
    cursor: pointer;

    ${OptionButton}.${activeClass} & {
        opacity: 1;
        border-color: ${props => props.theme.colors.pop};
    }
`;

const StyledIcon = styled(Icon)`
    font-size: ${toRem(40)};
    color: ${props => props.theme.colors.popFaded};
    transition: color 0.2s;
    will-change: color;

    ${OptionButton}.${activeClass} &,
    ${OptionButton}:hover & {
        color: ${props => props.theme.colors.pop};
    }
`;

const TitleInfo = styled.div`
  padding: ${toRem(35)} ${toRem(5)};
`;

const LoadTimeContainer = styled.div`
  background-color: ${props => props.theme.panel.headerBg};
  padding: ${toRem(10)} ${toRem(5)};
`;

const LoadTime = styled.span`
  ${props =>
    props.highlighted &&
    css`
      color: ${props => props.theme.colors.success};
    `}
`;
