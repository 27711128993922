import React from "react";
import {
  WelcomeMessage,
  Transactions,
  ActivateYourCard,
  Cards,
  Funds,
  Rewards
} from "./components";
import { Container, Page, DownloadMobilePrompt } from "components";

function Dashboard(props) {
  return (
    <Page.Container>
      <DownloadMobilePrompt />
      <Container>
        <WelcomeMessage store={props.store} />
        <Funds {...props} />
        <ActivateYourCard theme={props.theme} store={props.store} />
        <Transactions {...props} />
        <Cards {...props} />
        <Rewards {...props} />
      </Container>
    </Page.Container>
  );
}

export default Dashboard;
