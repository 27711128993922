import React, {useEffect} from "react";
import { useCookies } from 'react-cookie';
import {isOpen, content} from "store/modal";
import styled from "styled-components";
import store from "store";
import {DownloadAppButtons, Heading, P} from "components";
import Locales from "locales";
import {toRem} from "styles/Utility";
import {isMobile} from "utility";
import org from "org";

const cookieName = 'show_mobile_download_prompt';
const text__title = Locales('component.downloadPrompt.title');
const text__desc = Locales('component.downloadPrompt.desc');

export default function() {
  const [cookies, setCookie] = useCookies([cookieName]);  

  useEffect(() => {
    if (
      !cookies[cookieName] && 
      isMobile() &&
      org.appStoreLink &&
      org.googlePlayLink
      ) {
        let date = new Date();
        date.setDate(date.getDate() + 7); //Set to expire after 7 days
        setCookie(cookieName, 'true', { path: '/', expires: date }); 
        store.dispatch(content(<DownloadModalContent/>));
        store.dispatch(isOpen(true));
    }
  }, [
    cookies,
    setCookie
  ])

  return <span />
}

function DownloadModalContent() {
  return (
    <DownloadPromptContainer>
      <Heading type='h3' mReset mb={10}>{text__title}</Heading>
      <P smaller mReset mb={20}>{text__desc}</P>
      <DownloadAppButtons />
    </DownloadPromptContainer>
  )
}

const DownloadPromptContainer = styled.div`
  text-align: center;
  max-width: ${toRem(320)};
  background-color: ${props => props.theme.colors.bg};
  padding: ${toRem(25)} ${props => props.theme.gutter.rem};
`