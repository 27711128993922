import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { BodySize } from "styles/Utility";

const Base = (props) => (
  <StyledBase/>
);

const StyledBase = createGlobalStyle`
  body {
    background: ${props => props.theme.colors.bgDark};
    color: ${props => props.theme.colors.pop};
    font-size: ${BodySize}px;

    * {
      box-sizing: border-box;
      outline: none !important;
    }
  }

  a {
    color: ${props => props.theme.colors.pop};
    text-decoration: none;
  }

  hr {
    border-color: ${props => props.theme.colors.bgLight};
    margin-top: ${props => props.theme.gutter.rem};
    margin-bottom: ${props => props.theme.gutter.rem};
  }
`
export default Base;
