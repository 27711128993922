import React from 'react';
import { Link, withRouter } from "react-router-dom";

function CustomLink(props) {
  //StaticContext throwing error as it is undefined
  //Remove from obj
  const newProps = Object.assign({}, props);
  delete newProps.staticContext;

  let el;
  if (props.location.pathname === props.to) {
    el = (
      <span
        className={`${props.className ? props.className : ''} ${props.activeClassName ? props.activeClassName : ''}`}>
        {props.children}
      </span>
    )
  } else {
    el = (
      <Link to={newProps.to} className={newProps.className}>{props.children}</Link>
    )
  }
  
  return el
}

export default withRouter(CustomLink);