import React from "react";
import { Panel, Grid, P } from "components";
import styled, { css } from "styled-components/macro";
import { toRem } from "styles/Utility";
import { BgImage } from "styles/Mixins";
import { Query } from "styles/Utility";
import Locales from "locales";
import has from "lodash/has";
import { NavLink } from "react-router-dom";
import routePaths from "routes/routePaths";

export default function WelcomeMessage(props) {
  return (
    <Panel.Container hideColors>
      {has(props.store, 'user.profile.id') &&
        <WelcomeBody hideColors>
          <Grid.Row align={{sm: 'center'}}>
              <Grid.Col shrink>
                <NavLink to={routePaths.settings.path}> 
                  <PhotoContainer photo={has(props.store, 'user.profile.thumbnailProfilePictureUrl') ? props.store.user.profile.thumbnailProfilePictureUrl : require('images/asset--user.png')}></PhotoContainer>
                </NavLink>
              </Grid.Col>
              <Grid.Col shrink>
                <NavLink to={routePaths.settings.path}>
                  {has(props.store, 'user.profile.firstName') &&
                    <StyledHeading as="h1">{Locales('page.home.welcome', {firstName: props.store.user.profile.firstName})}</StyledHeading>
                  }
                  {has(props.store, 'user.profile.username') &&
                    <P smaller mReset faded>@{props.store.user.profile.username}</P>
                  }
                </NavLink>
              </Grid.Col>
          </Grid.Row>
        </WelcomeBody> 
      }
    </Panel.Container>
  )
}

const WelcomeBody = styled(Panel.Body)`
  @media ${Query.mobileOnly} {
    padding-left: 0;
    padding-right: 0;
  }
`

const StyledHeading = styled(P)`
  text-transform: none;
  margin: 0;
  letter-spacing: 0;
`

const PhotoContainer = styled.div`
  width: ${toRem(65)};
  height: ${toRem(65)};
  border: 4px solid ${props => props.theme.colors.pop};
  border-radius: ${toRem(65)};
  ${BgImage('cover')}

  @media ${Query.mobileOnly} {
    width: ${toRem(45)};
    height: ${toRem(45)};
  }
  
  ${props => 
    props.photo &&
    css`
      background-image: url(${props => props.photo});
  `}
`

