import React from "react";
import { Panel, Table, Span } from "components";
import SpacingWrapper from "./SpacingWrapper";
import Locales from "locales";
import has from "lodash/has";
import { getMonthDayYear } from "utility/Time";

const text__title = Locales("page.settings.personalInfo.title");
const text__email = Locales("general.email");
const text__changeEmail = Locales("page.settings.personalInfo.changeEmail");
const text__phone = Locales("general.phone");
const text__joinDate = Locales("page.settings.personalInfo.joinDate");

export default function PersonalInfo(props) {
  const hasProfileInfo = has(props, "store.user.profile.email");
  const profile = hasProfileInfo ? props.store.user.profile : false;

  if (hasProfileInfo) {
    return (
      <SpacingWrapper>
        <Panel.Container>
          <Panel.Header>
            <Panel.Heading>{text__title}</Panel.Heading>
          </Panel.Header>
          <Panel.Body padLarge>
            <Table.Body>
              {profile.email && (
                <Table.Tr mb={10}>
                  <Table.Td light alignTop faded>
                    {text__email}
                  </Table.Td>
                  <Table.Td alignTop>
                    <Span mb={5}>{profile.email}</Span>
                    <br />
                    <Span faded small>
                      {text__changeEmail}
                    </Span>
                  </Table.Td>
                </Table.Tr>
              )}
              {profile.phoneNumber && (
                <Table.Tr mb={10}>
                  <Table.Td light faded>{text__phone}</Table.Td>
                  <Table.Td>{profile.phoneNumber}</Table.Td>
                </Table.Tr>
              )}
              {profile.joinDate && (
                <Table.Tr mb={10}>
                  <Table.Td light faded>{text__joinDate}</Table.Td>
                  <Table.Td>{getMonthDayYear(profile.joinDate)}</Table.Td>
                </Table.Tr>
              )}
            </Table.Body>
          </Panel.Body>
        </Panel.Container>
      </SpacingWrapper>
    );
  } else {
    return <span />;
  }
}
