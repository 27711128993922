import React from "react";
import { setPinApiKey } from "utility/Token";
import {
  getPhysicalCardDetails,
  getPhysicalCardPin,
  getVirtualCardDetails,
  freezePhysicalCard,
  unfreezePhysicalCard,
  freezeVirtualCard,
  unfreezeVirtualCard
} from "utility/StackApi/Cards";
import { isOpen, content } from "store/modal";
import { setPhysicalCardPin, setPhysicalCardDetails, setVirtualCardDetails } from "store/cards";
import store from "store";
import { ConfirmPasscode } from "components";
import Locales from "locales";
import dataRetriever from "routes/dataRetriever";
import routePaths from "routes/routePaths";
import tracking from "utility/tracking";

const text__enterToContinue = Locales("component.enterPasscode.enterToContinue");

function modalConfirmation(successCall, setIsLoading, callback) {
  store.dispatch(isOpen(true));
  store.dispatch(
    content(
      <ConfirmPasscode
        descriptionText={text__enterToContinue}
        onSuccess={async ({ pinApiKey, passcode }) => {
          store.dispatch(isOpen(false));
          setPinApiKey(pinApiKey);

          //Start loading animation
          if (setIsLoading) {
            setIsLoading(true);
          }

          //Await...
          const response = await successCall(passcode);

          //Stop loading animation
          if (setIsLoading) {
            setIsLoading(false);
          }

          //Callback
          callback(response);
        }}
      />
    )
  );
}

export function hidePhysicalCardDetails() {
  store.dispatch(setPhysicalCardDetails(false));
}

export function hideVirtualCardDetails() {
  store.dispatch(setVirtualCardDetails(false));
}

export function hidePhysicalCardPin() {
  store.dispatch(setPhysicalCardPin(false));
}

export async function handleViewPhysicalCardDetails(setLoading) {
  tracking.event("View physical card number");
  modalConfirmation(getPhysicalCardDetails, setLoading, response => {
    store.dispatch(setPhysicalCardDetails({ ...response.data }));
  });
}

export async function handleViewPhysicalCardPin(setLoading) {
  tracking.event("View physical card pin");
  modalConfirmation(getPhysicalCardPin, setLoading, response => {
    store.dispatch(setPhysicalCardPin(response.data.cardPin));
  });
}

export async function handleViewVirtualCardDetails(setLoading) {
  tracking.event("View virtual card number");
  modalConfirmation(getVirtualCardDetails, setLoading, response => {
    store.dispatch(setVirtualCardDetails({ ...response.data }));
  });
}

export async function handleFreezePhysicalCard(setLoading) {
  tracking.event("Freeze physical card");
  await freezePhysicalCard();
  await Promise.all(routePaths.cards.data.map(fn => dataRetriever[fn]()));
  hidePhysicalCardDetails();
  hidePhysicalCardPin();
  if (setLoading) setLoading(false);
}

export async function handleUnfreezePhysicalCard(setLoading) {
  tracking.event("Unfreeze physical card");
  await unfreezePhysicalCard();
  await Promise.all(routePaths.cards.data.map(fn => dataRetriever[fn]()));
  if (setLoading) setLoading(false);
}

export async function handleFreezeVirtualCard(setLoading) {
  tracking.event("Freeze virtual card");
  await freezeVirtualCard();
  await Promise.all(routePaths.cards.data.map(fn => dataRetriever[fn]()));
  hideVirtualCardDetails();
  if (setLoading) setLoading(false);
}

export async function handleUnfreezeVirtualCard(setLoading) {
  tracking.event("Unfreeze virtual card");
  await unfreezeVirtualCard();
  await Promise.all(routePaths.cards.data.map(fn => dataRetriever[fn]()));
  if (setLoading) setLoading(false);
}
