import React from "react";
import { Container, Page, Grid, Reward } from "components";
import Locales from "locales";
import has from "lodash/has";
import dataRetriever from "routes/dataRetriever";

const text__title = Locales("page.rewards.title");

function RewardsPage(props) {
  const hasRewards = has(props, "store.rewards.list.count");
  const rewards = hasRewards ? props.store.rewards.list.offers : [];

  return (
    <Page.Container>
      <Container>
        <Page.Title>{text__title}</Page.Title>
        <Grid.Row alignCenter collapse>
          {rewards.length > 0 &&
            rewards.map((reward, i) => (
              <Grid.Col key={i} vPad size={{ sm: 6, md: 4 }}>
                <Reward.ListItem
                  {...reward}
                  onActivate={() => {
                    dataRetriever.rewards();
                  }}
                />
              </Grid.Col>
            ))}
        </Grid.Row>
      </Container>
    </Page.Container>
  );
}

export default RewardsPage;
