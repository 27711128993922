import React from "react";
import styled from "styled-components";
import { Query, toRem } from "styles/Utility";

export default function CardModule(props) {
  return (
    <CardContainer>
      {props.type === "VIRTUAL" ? (
        <Card src={require(`org/cards/virtual.svg`)} />
      ) : (
        <Card src={require(`org/cards/physical.svg`)} />
      )}
    </CardContainer>
  );
}

const CardContainer = styled.div`
  display: none;

  @media ${Query.mobileOnly} {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: ${toRem(15)};
  }
`;

const Card = styled.img`
  display: block;
  width: ${toRem(175)};
  height: ${toRem(263)};
`;
