import React, { useState } from "react";
import { P, Panel, Button } from "components";
import Locales from "locales";
import styled from "styled-components";
import MobileCard from "./MobileCard";
import { toRem } from "styles/Utility";
import { buttonHeight } from "components/Button";
import { Input } from "@material-ui/core";
import { activateCard } from "utility/StackApi/Cards";
import dataRetriever from "routes/dataRetriever";
import routePaths from "routes/routePaths";

const text__shouldBe4Digits = Locales("page.card.shouldBe4Digits");
const text__incorrectDigitsEntered = Locales("page.card.incorrectDigitsEntered");
const text__enterLast4 = Locales("page.card.enterLast4");
const text__receivedCard = Locales("page.cards.receivedCard");
const text__activate = Locales("general.activate");
const text__activateCard = Locales("page.cards.activateCard");
const text__shippingAddress = Locales("general.shippingAddress");

export default function CardModule(props) {
  const { address } = props.card;
  const [showForm, setShowForm] = useState(false);
  const [cardNumber, setCardNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  async function handleSubmit() {
    //Needs to be 4 digits and only be numbers
    if (cardNumber.length !== 4 || /\D/.test(cardNumber))
      return setErrorMessage(text__shouldBe4Digits);

    setIsLoading(true);

    try {
      await activateCard(cardNumber);
      await Promise.all(routePaths.cards.data.map(fn => dataRetriever[fn]()));
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setErrorMessage(text__incorrectDigitsEntered);
    }
  }

  function handleChange(e) {
    setErrorMessage(false);
    setCardNumber(e.target.value);
  }

  return (
    <StyledPanelContainer>
      <StyledPanelBody>
        <MobileCard {...props} />
        <P faded center mReset mb={15}>
          {text__enterLast4}
        </P>
        {showForm ? (
          <div>
            <ActivateForm
              onSubmit={e => {
                e.preventDefault();
              }}
            >
              <Input
                placeholder={text__receivedCard}
                inputProps={{ style: { textAlign: "center" } }}
                type="text"
                fullWidth
                value={cardNumber}
                onChange={handleChange}
              />
              <Button small ml={10} loading={isLoading ? "true" : undefined} onClick={handleSubmit}>
                {text__activate}
              </Button>
            </ActivateForm>
            {errorMessage && (
              <P center error mReset smaller mt={15}>
                {errorMessage}
              </P>
            )}
          </div>
        ) : (
          <Button
            onClick={() => {
              setShowForm(true);
            }}
          >
            {text__activateCard}
          </Button>
        )}
        {address && (
          <P small center faded mt={15}>
            {text__shippingAddress}:<br />
            {address.streetNumber} {address.addressLine}
            {address.unitNumber && `, ${address.unitNumber}`}
            <br />
            {address.city}
            {address.region && `, ${address.region}`}
            <br />
            {address.postalCode}
          </P>
        )}
      </StyledPanelBody>
    </StyledPanelContainer>
  );
}

const ActivateForm = styled.form`
  height: ${toRem(buttonHeight)};
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  max-width: ${toRem(300)};
`;

const StyledPanelContainer = styled(Panel.Container)`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.panel.bodyBg};
`;

const StyledPanelBody = styled(Panel.Body)`
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;
