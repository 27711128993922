import React, { useState } from "react";
import { Panel, P, Button, FlexContainer } from "components";
import styled from "styled-components";
import SpacingWrapper from "./SpacingWrapper";
import { setPinApiKey, setXApiKey } from "utility/Token";
import Locales from "locales";
import { Input } from "@material-ui/core";
import { toRem } from "styles/Utility";
import { updatePassword } from "utility/StackApi/User";
import tracking from "utility/tracking";

const text__currentPasswordRequired = Locales("page.settings.security.currentPasswordRequired");
const text__newPasswordsRequired = Locales("page.settings.security.newPasswordsRequired");
const text__passwordsMustMatch = Locales("page.settings.security.passwordsMustMatch");
const text__noMatchPassword = Locales("page.settings.security.noMatchPassword");
const text__passwordCriteria = Locales("page.settings.security.passwordCriteria");
const text__currentPasswordIncorrect = Locales("page.settings.security.currentPasswordIncorrect");
const text__title = Locales("page.settings.security.title");
const text__passwordChangeSuccess = Locales("page.settings.security.passwordChangeSuccess");
const text__desc = Locales("page.settings.security.desc");
const text__cta = Locales("page.settings.security.cta");
const text__enterOldPass = Locales("page.settings.security.enterOldPass");
const text__enterNewPass = Locales("page.settings.security.enterNewPass");
const text__reenterNewPass = Locales("page.settings.security.reenterNewPass");
const text__securityCta = Locales("page.settings.security.cta");

export default function PersonalInfo(props) {
  const [showForm, setShowForm] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [reEnterNewPassword, setReEnterNewPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(undefined);
  const [success, setSuccess] = useState(false);

  async function handleSubmit() {
    if (oldPassword === "") return setErrorMessage(text__currentPasswordRequired);
    if (newPassword === "" || reEnterNewPassword === "")
      return setErrorMessage(text__newPasswordsRequired);
    if (newPassword !== reEnterNewPassword) return setErrorMessage(text__passwordsMustMatch);
    if (newPassword === oldPassword) return setErrorMessage(text__noMatchPassword);

    setLoading("true");

    try {
      //Set keys
      const response = await updatePassword({ newPassword, oldPassword });
      setPinApiKey(response.data["pin-api-key"]);
      setXApiKey(response.data["x-api-key"]);
      setSuccess(true);
      setLoading(undefined);

      //Track password change
      tracking.event("Change password");
    } catch (err) {
      setLoading(undefined);
      if (err.response.status === 400) return setErrorMessage(text__passwordCriteria);
      if (err.response.status === 401) return setErrorMessage(text__currentPasswordIncorrect);
    }
  }

  return (
    <SpacingWrapper>
      <Panel.Container>
        <Panel.Header>
          <Panel.Heading>{text__title}</Panel.Heading>
        </Panel.Header>
        <Panel.Body padLarge>
          {success ? (
            <P success>{text__passwordChangeSuccess}</P>
          ) : (
            <div>
              {!showForm ? (
                <FlexContainer direction="column">
                  <P mReset mb={20}>
                    {text__desc}
                  </P>
                  <Button
                    onClick={() => {
                      setShowForm(true);
                    }}
                  >
                    {text__cta}
                  </Button>
                </FlexContainer>
              ) : (
                <PasswordForm
                  onSubmit={e => {
                    e.preventDefault();
                  }}
                >
                  <InputContainer>
                    <Input
                      placeholder={text__enterOldPass}
                      type="password"
                      fullWidth
                      autoComplete="off"
                      autoCorrect="off"
                      autoCapitalize="none"
                      value={oldPassword}
                      onChange={e => {
                        setOldPassword(e.target.value);
                        setErrorMessage("");
                      }}
                    />
                  </InputContainer>
                  <InputContainer>
                    <Input
                      placeholder={text__enterNewPass}
                      type="password"
                      fullWidth
                      autoComplete="off"
                      autoCorrect="off"
                      autoCapitalize="none"
                      value={newPassword}
                      onChange={e => {
                        setNewPassword(e.target.value);
                        setErrorMessage("");
                      }}
                    />
                  </InputContainer>
                  <InputContainer>
                    <Input
                      placeholder={text__reenterNewPass}
                      type="password"
                      fullWidth
                      autoComplete="off"
                      autoCorrect="off"
                      autoCapitalize="none"
                      value={reEnterNewPassword}
                      onChange={e => {
                        setReEnterNewPassword(e.target.value);
                        setErrorMessage("");
                      }}
                    />
                  </InputContainer>
                  <InputContainer>
                    <Button
                      loading={loading}
                      onClick={async () => {
                        handleSubmit();
                      }}
                    >
                      {text__securityCta}
                    </Button>
                  </InputContainer>
                  {errorMessage !== "" && (
                    <P error mReset smaller mt={30}>
                      {errorMessage}
                    </P>
                  )}
                </PasswordForm>
              )}
            </div>
          )}
        </Panel.Body>
      </Panel.Container>
    </SpacingWrapper>
  );
}

const PasswordForm = styled.form``;

const InputContainer = styled.div`
  padding: ${props => props.theme.gutter.remSmall} 0;
  max-width: ${toRem(250)};
`;
