import React from "react";
import ReactDOM from "react-dom";
import Routes from "./routes";
import { Provider } from "react-redux";
import store from "store";
import { ThemeProvider } from "styled-components";
import { Normalize } from "styled-normalize";
import theme, { materialUITheme } from "./styles/Theme";
import BaseStyling from "./styles/BaseStylingComponent";
import { loadFontStyling } from "styles/Utility";
import { ThemeProvider as MaterialThemeProvider } from "@material-ui/core/styles";

//Fonts needed to be loaded separately, not part of theme
loadFontStyling();

const App = () => (
  <ThemeProvider theme={theme}>
    <Normalize />
    <BaseStyling />
    <MaterialThemeProvider theme={materialUITheme}>
      <Provider store={store}>
        <Routes />
      </Provider>
    </MaterialThemeProvider>
  </ThemeProvider>
);

ReactDOM.render(<App />, document.getElementById("root"));
