export const actionTypes = {
  SET_REWARDS: "SET_REWARDS",
};

//Value thats retrieved from /offer
export function setRewards(value) {
  return {
    type: actionTypes.SET_REWARDS,
    value: value
  };
}