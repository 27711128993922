export const actionTypes = {
  SET_BALANCE: "SET_BALANCE",
  SET_GOAL: "SET_GOAL",
  SET_BANKING_INFO: "SET_BANKING_INFO",
  LOADHUB_QR_CODE: "LOADHUB_QR_CODE",
  PAYROLL_INFO: "PAYROLL_INFO",
};


//Value thats retrieved from /account/balance
export function setBalance(value) {
  return {
    type: actionTypes.SET_BALANCE,
    value: value
  };
}

//Value thats retrieved from /goal
export function setGoal(value) {
  return {
    type: actionTypes.SET_GOAL,
    value: value
  };
}

//Value thats retrieved from /bankAccount
export function setBankingInfo(value) {
  return {
    type: actionTypes.SET_BANKING_INFO,
    value: value
  };
}

//Value thats retrieved from /loadhub/qrCode
export function setLoadhubQRCode(value) {
  return {
    type: actionTypes.LOADHUB_QR_CODE,
    value: value
  };
}

//Value thats retrieved from /payroll
export function setPayrollInfo(value) {
  return {
    type: actionTypes.PAYROLL_INFO,
    value: value
  };
}