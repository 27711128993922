/* ===========================================
Supply a default dollar amount
to receive formatted (ie. 103.1 => 103.10) 
============================================ */
export function formatMoney(amount) {
  return Number(amount.toFixed(2));
}

/* ===========================================
Supply a default dollar amount (ie. 103.1)
to receive formatted (ie. 103.1 => 103)
============================================ */
export function getRoundedDollarAmount(amount) {
  return Math.floor(amount);
}

/* ===========================================
Supply a default dollar amount (Number) (ie. 101.3)
to receive cents value (101.3 => 30)
============================================ */
export function getCents(amount) {
  let cents = (amount + "").split(".")[1];

  if (!cents) return "00";

  if (cents.length === 1) {
    cents = cents + "0";
  }
  return cents;
}

/* ===========================================
Supply a default dollar amount (ie. 101.3)
to receive dollar with comma value 
ie. 2101.3 => 2,101.30
============================================ */
export function getAmountWithCommas(amount) {
  return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

/* ===========================================
Supply a default dollar amount (ie. 101.3)
to receive dollar with comma value 
ie. 2101.3 => 2,101.30
============================================ */
export function formatWithComma(amount) {
  const dollarAmount = getRoundedDollarAmount(amount);
  const cents = getCents(amount);
  return getAmountWithCommas(dollarAmount + `.${cents}`);
}

/* ===========================================
Supply a default dollar amount (ie. 101.3)
to receive dollar with comma value 
ie. 2101.3 => $2,101.30
============================================ */
export function prettyFormat(amount) {
  return `$${formatWithComma(amount)}`;
}

/* ===========================================
I2C supplies currency name as the long form, 
with a short form.
ie (European EMU, Euro)
We just want "Euro"
============================================ */
export function getCurrencyName(i2cCurrencyName) {
  if (!i2cCurrencyName.includes(",")) return i2cCurrencyName;

  return i2cCurrencyName.split(", ")[1];
}
