import React from "react";
import styled, { css } from "styled-components/macro";
import { toRem } from "styles/Utility";
import { Unhighlightable } from "styles/Mixins";
import { Query } from "styles/Utility";
import NavItem from "./NavItem";
import {
  sidebarWidth,
  mobileHeight,
  mobileNavOpenClass,
  sidebarId,
} from "main/components/navigationSettings";
import Logo from "./Logo";
import ProfileSummary from "main/components/Navigation/ProfileSummary";
import { NavLink } from "react-router-dom";
import navLinks from "org/config/navLinks";
import routePaths from "routes/routePaths";

/* ================================================
Navigation
================================================ */

export default function SidebarModule(props) {
  return (
    <Sidebar
      {...props}
      id={sidebarId}
      className={`${
        props.store.navigation.mobileMenuOpen ? mobileNavOpenClass : ""
      }`}
    >
      <NavLink to={routePaths.home.path}>
        <Logo />
      </NavLink>
      <ProfileSummaryContainer>
        <ProfileSummary {...props} />
      </ProfileSummaryContainer>
      <nav>
        {navLinks.map((link, i) => {
          return <NavItem key={i} {...props} link={link} />;
        })}
      </nav>
    </Sidebar>
  );
}

const ProfileSummaryContainer = styled.div`
  display: none;

  @media ${Query.smallDown} {
    display: block;
    padding: ${toRem(20)} ${toRem(25)} ${toRem(7)};
  }
`;

const Sidebar = styled.div`
  ${Unhighlightable}
  width: ${toRem(sidebarWidth)};
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  overflow: auto;
  background-color: ${(props) => props.theme.navigation.bg};
  border-right: 1px solid ${(props) => props.theme.navigation.borderColor};
  transform: translateX(-101%);
  opacity: 0;
  transition: transform 0.5s, opacity 0.5s;
  will-change: transform, opacity;
  z-index: 101;
  pointer-events: none;

  ${(props) =>
    props.store.navigation.showSidebar &&
    css`
      transform: translateX(0);
      opacity: 1;
      pointer-events: all;
    `}

  @media ${Query.smallDown} {
    right: 0;
    transform: translateX(101%);
    left: auto;
    border-left: 1px solid ${(props) => props.theme.navigation.borderColor};
    border-right: none;
    padding-top: ${toRem(mobileHeight)};

    &.${mobileNavOpenClass} {
      transform: none;
    }
  }
`;
