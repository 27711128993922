// import the actionTypes
import { actionTypes } from "./index.js";

// define the default state
export const defaultState = {
  isLoaded: false,
  isLoading: true,
  pageTitle: "",
  pageRequiredStatus: false,
  requestsAllowed: true
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.PAGE_LOADED:
      return Object.assign({}, state, {
        ...state,
        isLoaded: action.value
      });
    case actionTypes.PAGE_LOADING:
      return Object.assign({}, state, {
        ...state,
        isLoading: action.value
      });
    case actionTypes.PAGE_TITLE:
      return Object.assign({}, state, {
        ...state,
        pageTitle: action.value
      });
    case actionTypes.PAGE_REQUIRED_STATUS:
      return Object.assign({}, state, {
        ...state,
        pageRequiredStatus: action.value
      });
    case actionTypes.PAGE_REQUESTS_ALLOWED:
      return Object.assign({}, state, {
        ...state,
        requestsAllowed: action.value
      });
    default:
      return state;
  }
}
