import React from "react";
import {
  PersonalInfo,
  Security,
  CardPin,
  LearnMore,
  CloseAccount,
  LanguagePreferences
} from "./components";
import { Container, Page } from "components";
import Locales from "locales";

const text__title = Locales("page.settings.title");

function Dashboard(props) {
  return (
    <Page.Container>
      <Container>
        <Page.Title>{text__title}</Page.Title>
        <PersonalInfo {...props} />
        <Security {...props} />
        <LanguagePreferences {...props} />
        <CardPin {...props} />
        <LearnMore {...props} />
        <CloseAccount {...props} />
      </Container>
    </Page.Container>
  );
}

export default Dashboard;
