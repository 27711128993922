import React from "react";
import { Panel, Button, Grid } from "components";
import styled from "styled-components/macro";
import { toRem } from "styles/Utility";
import { FlexboxUtility, Padding } from "styles/Mixins";
import { NavLink } from "react-router-dom";
import Locales from "locales";
import SpacingWrapper from "../SpacingWrapper";
import Card from "./Card";
import has from "lodash/has";
import routePaths from "routes/routePaths";

const text__cardsTitle = Locales("page.home.cardsTitle");
const text__viewCards = Locales("page.home.viewCards");

export default function CardsModule(props) {
  const hasCardsProp = has(props, "store.cards.details.cards");
  const hasCards = hasCardsProp ? props.store.cards.details.cards.length > 0 : false;

  if (hasCards) {
    const cards = props.store.cards.details.cards;

    return (
      <SpacingWrapper>
        <Panel.Container>
          <Panel.Header icon={"cards--outline"} iconColor={props.theme.colors.primaryLight}>
            <Panel.Heading>{text__cardsTitle}</Panel.Heading>
          </Panel.Header>
          <Panel.Body>
            <Cards align="center" justify="center" direction={{ sm: "column", md: "row" }}>
              {cards.map((card, i) => (
                <Card key={i} {...card} />
              ))}
            </Cards>
            <Grid.Row>
              <Grid.Col>
                <ButtonContainer>
                  <NavLink to={routePaths.cards.path}>
                    <Button>{text__viewCards}</Button>
                  </NavLink>
                </ButtonContainer>
              </Grid.Col>
            </Grid.Row>
          </Panel.Body>
        </Panel.Container>
      </SpacingWrapper>
    );
  } else {
    return <span />;
  }
}

const Cards = styled(Grid.Row)`
  ${Padding}
`;

const ButtonContainer = styled.div`
  ${FlexboxUtility.center}
  padding: ${toRem(30)} ${props => props.theme.gutter.rem} ${toRem(25)};
`;
