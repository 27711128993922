import React from "react";
import { P, Table, Grid, Small, TextCta } from "components";
import styled from "styled-components/macro";
import { toRem } from "styles/Utility";
import routePaths from "routes/routePaths";
import Locales from "locales";
import QRCode from "qrcode.react";
import { queryParam } from "pages/QRCode";
import { Query } from "styles/Utility";
import { featureEnums, featureIsEnabled, featureUnavailableText } from "utility/FeatureFlagging";

const text__description = Locales("page.addMoney.cashLoad.description");
const text__printQrCode = Locales("page.addMoney.cashLoad.printQrCode");
const text__otherDescription = Locales("page.addMoney.cashLoad.otherDescription");
const text__viewLocations = Locales("page.addMoney.cashLoad.viewLocations");
const text__loadLimits = Locales("page.addMoney.cashLoad.loadLimits");
const text__minLoad = Locales("page.addMoney.cashLoad.minLoad");
const text__minLoadAmt = Locales("page.addMoney.cashLoad.minLoadAmt");
const text__maxLoadCanPost = Locales("page.addMoney.cashLoad.maxLoadCanPost");
const text__maxLoadCanPostAmt = Locales("page.addMoney.cashLoad.maxLoadCanPostAmt");
const text__maxLoadOther = Locales("page.addMoney.cashLoad.maxLoadOther");
const text__maxLoadOtherAmt = Locales("page.addMoney.cashLoad.maxLoadOtherAmt");
const text__loadLocationsTitle = Locales("page.addMoney.cashLoad.loadLocationsTitle");
const text__note = Locales("page.addMoney.cashLoad.note");
const text__cashLoad = Locales("general.cashLoad");

export default function LoadingOption(props) {
  const { code } = props.money.loadhubQRCode;

  return (
    <div>
      {featureIsEnabled(featureEnums.cashLoad) ? (
        <div>
          <Grid.Row>
            <Grid.Col>
              <P faded mb={25}>
                {text__description}
              </P>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            {code && (
              <Grid.Col shrink={{ md: true }} size={{ sm: 12 }}>
                <QRCodeContainer>
                  <QRCode size={110} value={code} />
                  <a
                    rel="noopener noreferrer"
                    href={`${routePaths.qrCode.path}?${queryParam}=${code}`}
                    target="_blank"
                  >
                    <P faded hover smaller mt={10} center>
                      {text__printQrCode}
                    </P>
                  </a>
                </QRCodeContainer>
              </Grid.Col>
            )}
            <Grid.Col>
              <InfoColumn>
                <Grid.Row>
                  <Grid.Col size={{ sm: 12, md: 10 }}>
                    <P mReset mb={20}>
                      {text__otherDescription}
                    </P>
                    <a
                      href="https://www.nowprepay.net/storelocator/live/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <TextCta mReset mb={20} icon="caret-right">
                        {text__viewLocations}
                      </TextCta>
                    </a>
                    <P mReset faded mb={10}>
                      {text__loadLimits}
                    </P>
                    <Table.Body mb={20}>
                      <Table.Tr>
                        <Table.Td>{text__minLoad}</Table.Td>
                        <Table.Td>{text__minLoadAmt}</Table.Td>
                      </Table.Tr>
                      <Table.Tr>
                        <Table.Td>{text__maxLoadCanPost}</Table.Td>
                        <Table.Td>{text__maxLoadCanPostAmt}</Table.Td>
                      </Table.Tr>
                      <Table.Tr>
                        <Table.Td>{text__maxLoadOther}</Table.Td>
                        <Table.Td>{text__maxLoadOtherAmt}</Table.Td>
                      </Table.Tr>
                    </Table.Body>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col size={{ sm: 12 }}>
                    <P mReset faded mb={10}>
                      {text__loadLocationsTitle}
                    </P>
                    <LocationsImg src={require("images/asset--cashload-locations.png")} />
                    <Small smaller faded mReset mt={15}>
                      {text__note}
                    </Small>
                  </Grid.Col>
                </Grid.Row>
              </InfoColumn>
            </Grid.Col>
          </Grid.Row>
        </div>
      ) : (
        <P faded>{featureUnavailableText(text__cashLoad)}</P>
      )}
    </div>
  );
}

const InfoColumn = styled.div`
  padding-left: ${toRem(15)};

  @media ${Query.mobileOnly} {
    padding-left: 0;
  }
`;

const LocationsImg = styled.img`
  max-width: 100%;
  display: block;
`;

const QRCodeContainer = styled.div`
  text-align: center;
`;
