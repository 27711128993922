import React, { useEffect, useReducer } from "react";
import ModuleContainer from "./ModuleContainer";
import { FlexContainer, Button, P, Span } from "components";
import locales from "locales";
import { emailRegex } from "utility";
import { Input } from "@material-ui/core";
import { login } from "utility/StackApi/General";
import { setTempApiKey } from "utility/Token";
import store, { setEmail, screenEnums } from "./store";
import { hideAndShowModule } from "./helper";
import { removeCognigyChat } from "components/Cognigy/CognigyChat";

const text__loginTitle = locales("component.login.title");
const text__emailPlaceholder = locales("general.email");
const text__loginPassPlaceholder = locales("general.password");
const text__loginCta = locales("component.login.cta");
const text__loginForgotPass = locales("component.login.forgotPass");
const text__invalidCredentials = locales("error.invalidCredentials");
const text__fraudText = locales("component.login.fraudText");
const text__loading = locales("general.loading");

const initialState = {
  email: "",
  password: "",
  loading: false,
  status: false,
  statusMessage: false,
};

function reducer(state, action) {
  switch (action.type) {
    case "email":
      return { ...state, email: action.value };
    case "password":
      return { ...state, password: action.value };
    case "loading":
      return { ...state, loading: action.value };
    case "status":
      return { ...state, status: action.value };
    case "statusMessage":
      return { ...state, statusMessage: action.value };
    default:
      throw new Error();
  }
}

export default function LoginComponent(props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  async function handleSubmit() {
    const validationMessage = validation(state.email, state.password);
    if (validationMessage !== true) {
      dispatch({ type: "status", value: "ERROR" });
      dispatch({ type: "statusMessage", value: validationMessage });
      return;
    }

    dispatch({ type: "loading", value: true });
    dispatch({ type: "status", value: false });

    try {
      const response = await login({ email: state.email, password: state.password });

      //If fraud user
      if (response.data.userStatus === "FAILED_KYC_HARD") {
        dispatch({ type: "status", value: "ERROR" });
        dispatch({ type: "statusMessage", value: text__fraudText });
        return;
      }

      //Set temp api key
      setTempApiKey(response.data["temp-api-key"]);

      dispatch({ type: "loading", value: false });
      hideAndShowModule(screenEnums.twoFactorAuth);
      store.dispatch(setEmail(state.email));
    } catch (err) {
      dispatch({ type: "loading", value: false });
      dispatch({ type: "status", value: "ERROR" });
      dispatch({ type: "statusMessage", value: text__invalidCredentials });
    }
  }

  function goToResetPassword() {
    hideAndShowModule(screenEnums.resetPassword);
  }

  useEffect(() => {
    removeCognigyChat();
  }, []);

  return (
    <ModuleContainer
      title={text__loginTitle}
      statusMessage={state.statusMessage}
      status={state.status}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <FlexContainer mb={25}>
          <Input
            placeholder={text__emailPlaceholder}
            inputProps={{ style: { textAlign: "center" } }}
            fullWidth
            autoComplete="email"
            value={state.email}
            onChange={(e) => {
              const { value } = e.target;
              dispatch({ type: "email", value });
            }}
          />
        </FlexContainer>
        <FlexContainer mb={35}>
          <Input
            placeholder={text__loginPassPlaceholder}
            inputProps={{ style: { textAlign: "center" } }}
            fullWidth
            type="password"
            autoComplete="new-password"
            value={state.password}
            onChange={(e) => {
              const { value } = e.target;
              dispatch({ type: "password", value });
            }}
          />
        </FlexContainer>
        <Button
          fullWidth
          mb={10}
          loading={state.loading ? "true" : undefined}
          onClick={handleSubmit}
        >
          <Span>{state.loading ? text__loading : text__loginCta}</Span>
        </Button>
        <P small center clickable onClick={goToResetPassword}>
          {text__loginForgotPass}
        </P>
      </form>
    </ModuleContainer>
  );
}

function validation(email, password) {
  if (!emailRegex.test(email)) return locales("error.invalidEmail");
  if (password.length === 0)
    return locales("error.cannotBeBlank", { input: locales("general.password") });

  return true;
}
