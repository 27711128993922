const checkCognigyEmbedLoaded = () => {
  return window?.initWebchat;
};

const isCognigyLoaded = () => {
  return window?.webchat;
};

export const removeCognigyChat = () => {
  const webChatRoot = document.querySelector('.webchat-root');
  if (webChatRoot) {
    webChatRoot.remove();
  }
  window.webchat = undefined;
};

const initCognigyChat = props => {
  return new Promise((resolve, reject) => {
    console.log("focus props?.store?.user?.profile?.id", props?.store?.user?.profile?.id);
    if (checkCognigyEmbedLoaded()) {
      window
        .initWebchat(
          'https://endpoint-app.cognigy.ai/95dab027d24c97fcda4c1afe1b4a6ba7b2db00e36941b4863a57443611f68491',
          {
            sessionId: props?.store?.user?.profile?.id,
            settings: {
              colorScheme: '#e21e4a',
              disableBranding: true,
            },
          },
        )
        .then(webchat => {
          window.webchat = webchat;
          resolve();
        })
        .catch(e => {
          console.error('Error initializing Cognigy chat.');
          reject(e);
        });
    } else {
      console.error('Cognigy Chat support is not enabled.');
      reject();
    }
  });
};

export const startCognigyChat = async props => {
  if (!isCognigyLoaded() || typeof window.webchat.open !== 'function') {
    await initCognigyChat(props);
  }
};