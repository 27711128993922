import React, { useState, useEffect } from 'react';
import styled, {css} from "styled-components/macro";
import {toRem, Query} from "styles/Utility";
import { NavLink } from "react-router-dom";
import { Icon } from "components";
import { ShadeColor } from "styles/Utility";
import { isChildIsActive, hideOtherOpenNavs } from "./helper";
import {mobileNav} from "main/components/navigationSettings";

const navTitlePadding = 60;
const activeClassName = 'active';
const openClass = 'open-nav';
const childActiveClass = "child-active";
const clickableClass = 'clickable';

export default function NavigationItem(props) {
  const {title, icon, iconActive, path, sublinks, onClick, mobileOnly} = props.link;
  const [isOpen, setIsOpen] = useState(false);
  const [firstLoad, setFirstLoad] = useState(false);
  const [childLinkActive, setChildLinkActive] = useState(false);

  useEffect(() => {
    const childIsActive = isChildIsActive(sublinks, props.store.navigation.activeLink);
    setChildLinkActive(childIsActive);

    if (!firstLoad) {
      setIsOpen(childIsActive); 
      setFirstLoad(true);
    }

    if (props.store.navigation.closeAllOpenNavs) {
      setIsOpen(false);
    }
  }, [
    props.store.navigation.activeLink, 
    props.store.navigation.closeAllOpenNavs,
    sublinks, 
    isOpen, 
    firstLoad
  ])

  return (
    <div>
      {onClick ? (
        <NavItem icon={icon} iconActive={iconActive} title={title} onClick={onClick} mobileOnly={mobileOnly} />
      ):(
        <div>
          {sublinks.length > 0 ? (
            <ParentLinkContainer className={`
                ${isOpen ? openClass : ''} 
                ${childLinkActive ? childActiveClass : ''}
              `}>
              <div onClick={() => { setIsOpen(!isOpen) }}>
                <NavItem icon={icon} iconActive={iconActive} title={title}/>
                {isOpen ? (<Caret name="caret-up" />):(<Caret name="caret-down" />)}
              </div>
              {sublinks &&
                <SubnavContainer>
                  {sublinks.map(({path, title}, i) => {
                    return (
                      <StyledSubnavLink activeClassName={activeClassName} exact={true} key={i} to={path} onClick={() => {mobileNav.close()}}>
                        <SubnavItemTitle>{title}</SubnavItemTitle>
                      </StyledSubnavLink>
                    )
                  })}
                </SubnavContainer>
              }
            </ParentLinkContainer>
          ):(
            <StyledNavLink activeClassName={activeClassName} to={path} exact={true} onClick={hideOtherOpenNavs}>
              <NavItem icon={icon} iconActive={iconActive} title={title} />
            </StyledNavLink>
          )}
        </div>
      )}
    </div>

  )
}

function NavItem({icon, iconActive, title, onClick, mobileOnly}) {
  return (
    <NavItemContainer onClick={() => {onClick && onClick()}} className={`${onClick && clickableClass}`} mobileOnly={mobileOnly}>
      <InactiveIcon className="icon--inactive" name={icon}></InactiveIcon>
      <ActiveIcon className="active" name={iconActive}></ActiveIcon>
      <NavItemTitle>{title}</NavItemTitle>
    </NavItemContainer>
  )
}

const Caret = styled(Icon)`
  top: ${toRem(18)};
  right: ${toRem(25)};
  position: absolute;
  font-size: ${toRem(10)};
`

const NavItemGeneralStyles = css`
  display: block;
  position: relative;
  overflow: hidden;
  color: ${props => props.theme.navigation.link.inactiveColor};
  cursor: pointer;

  &.${activeClassName} {
    background-color: ${props => props.theme.navigation.link.activeBg};
    color: ${props => props.theme.navigation.link.activeColor};
  } 
`

const transitionStyles = css`
  transition: color 0.2s, background-color 0.2s;
  will-change: background-color, color;
`

const IconStyles = css`
  ${transitionStyles}
  position: absolute;
  top: ${toRem(14)};
  left: ${toRem(navTitlePadding / 2)};
`

const StyledNavLink = styled(NavLink)`
  ${NavItemGeneralStyles}

  &.${activeClassName} {
    pointer-events: none;
  } 
`

const StyledSubnavLink = styled(StyledNavLink)`
  
`

const ParentLinkContainer = styled.div`
  ${NavItemGeneralStyles}
  ${transitionStyles}

  &.${openClass} {
    background-color: ${props => props.theme.navigation.link.activeBg};
    padding-bottom: 7px;
  }

  &.${childActiveClass} {

  }
`

const InactiveIcon = styled(Icon)`
  ${IconStyles}
  ${StyledNavLink}.${activeClassName} &,
  ${ParentLinkContainer}.${openClass} &,
  ${ParentLinkContainer}.${childActiveClass} & {
    display: none;
  }

`

const ActiveIcon = styled(Icon)`
  ${IconStyles}
  display: none;
  color: ${props => props.theme.navigation.link.activeIconColor};

  ${StyledNavLink}.${activeClassName} &,
  ${ParentLinkContainer}.${openClass} &,
  ${ParentLinkContainer}.${childActiveClass} & {
    display: block;
  }

  ${ParentLinkContainer}.${openClass} &,
  ${ParentLinkContainer}.${childActiveClass} & {
    color: ${props => props.theme.navigation.link.activeColor};
  }

  ${ParentLinkContainer}.${childActiveClass} & {
    color: ${props => props.theme.navigation.link.activeIconColor} !important;
  }
`

const NavItemContainer = styled.div`
  position: relative;

  &.${clickableClass} {
    color: ${props => props.theme.navigation.link.inactiveColor};
    cursor: pointer;
  }

  ${props => 
    props.mobileOnly &&
    css`
      display: none;

      @media ${Query.smallDown} {
        display: block;
      }
    `
  }
`

const NavItemTitle = styled.div`
  ${transitionStyles}
  padding: 0 ${toRem(10)} 0 ${toRem(navTitlePadding)};
  height: ${toRem(45)};
  display: flex;
  align-items: center;
  justify-content: flex-start;

  ${StyledNavLink}.${activeClassName} & {
    font-weight: ${props => props.theme.type.bodyFontWeightBold};
  }

  ${ParentLinkContainer}.${childActiveClass} &,
  ${ParentLinkContainer}.${openClass} & {
    color: ${props => props.theme.navigation.link.activeColor};
  }
`

const SubnavItemTitle = styled.div`
  padding: ${toRem(10)} ${toRem(10)} ${toRem(10)} ${toRem(navTitlePadding)};

  ${StyledSubnavLink}.${activeClassName} & {
    font-weight: ${props => props.theme.type.bodyFontWeightBold};
  }
  
  &:hover {
    color: ${props => ShadeColor(props.theme.navigation.link.inactiveColor, 20)}
  }
`

const SubnavContainer = styled.div`
  display: none;

  ${ParentLinkContainer}.${openClass} & {
    display: block;
  }
`