// import the actionTypes
import React from "react";
import { actionTypes } from "./index.js";

export const defaultState = {
  isOpen: false,
  canExit: true,
  content: <div></div>
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.IS_OPEN:
      return Object.assign({}, state, {
        ...state,
        isOpen: action.value
      });
    case actionTypes.MODAL_CONTENT:
        return Object.assign({}, state, {
          ...state,
          content: action.value
        });
    case actionTypes.SET_CAN_EXIT:
        return Object.assign({}, state, {
          ...state,
          canExit: action.value
        });
    default:
      return state;
  }
}
