import React from 'react';
import styled from "styled-components/macro";
import {toRem} from "styles/Utility";

function Container(props) {
  return (
      <StyledContainer {...props}>{props.children}</StyledContainer>
  );
}

const StyledContainer = styled.div`
  padding-left: ${props => props.theme.gutter.rem};
  padding-right: ${props => props.theme.gutter.rem};
  width: 100%;
  max-width: ${toRem(915)};
  margin: 0 auto;
`;

export default Container;