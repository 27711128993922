// import the actionTypes
import { actionTypes } from "./index.js";

export const defaultState = {
  activeLink: false,
  openParentNav: false,
  closeAllOpenNavs: false,
  showSidebar: false,
  showStatusBar: false,
  mobileMenuOpen: false
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.ACTIVE_LINK:
      return Object.assign({}, state, {
        ...state,
        activeLink: action.value
      });
    case actionTypes.OPEN_PARENT_NAV:
        return Object.assign({}, state, {
          ...state,
          openParentNav: action.value
        });  
    case actionTypes.CLOSE_ALL_OPEN_NAVS:
      return Object.assign({}, state, {
        ...state,
        closeAllOpenNavs: action.value
      });  
    case actionTypes.SHOW_SIDEBAR:
      return Object.assign({}, state, {
        ...state,
        showSidebar: action.value
      });
    case actionTypes.SHOW_STATUSBAR:
      return Object.assign({}, state, {
        ...state,
        showStatusBar: action.value
      });
    case actionTypes.OPEN_MOBILE_MENU:
      return Object.assign({}, state, {
        ...state,
        mobileMenuOpen: action.value
      });
    default:
      return state;
  }
}
