// import the actionTypes
import { actionTypes } from "./index.js";

export const defaultState = {
  balance: false,
  goal: false,
  bankingInfo: false,
  loadhubQRCode: false,
  payrollInfo: false
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.SET_BALANCE:
      return Object.assign({}, state, {
        ...state,
        balance: action.value
      });
    case actionTypes.SET_GOAL:
      return Object.assign({}, state, {
        ...state,
        goal: action.value
      });
    case actionTypes.SET_BANKING_INFO:
        return Object.assign({}, state, {
          ...state,
          bankingInfo: action.value
        });
    case actionTypes.LOADHUB_QR_CODE:
        return Object.assign({}, state, {
          ...state,
          loadhubQRCode: action.value
        });
    case actionTypes.PAYROLL_INFO:
        return Object.assign({}, state, {
          ...state,
          payrollInfo: action.value
        });
    default:
      return state;
  }
}
