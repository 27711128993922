import React from "react";
import { Container, Page, Panel, P, Table } from "components";
import Locales from "locales";
import has from "lodash/has";
import org from "org";

const text__title = Locales("page.support.title");
const text__desc = Locales("page.support.desc");
const text__email = Locales("general.email");
const text__phone = Locales("general.phone");
const text__callNumber = Locales("global.callNumber");
const text__hours = Locales("general.hours");
const text__faqs = Locales("general.faqs");
const text__learnMore = Locales("general.learnMore");
const text__memberCareHours = Locales("general.memberCareHours");

function Dashboard(props) {
  return (
    <Page.Container>
      <Container>
        <Page.Title>{text__title}</Page.Title>
        <Panel.Container>
          <Panel.Body padLarge>
            <P>{text__desc}</P>
            <Table.Body>
              <Table.Tr>
                <Table.Td faded light>
                  {text__email}
                </Table.Td>
                <Table.Td>info@getstack.ca</Table.Td>
              </Table.Tr>
              <Table.Tr>
                <Table.Td faded light>
                  {text__phone}
                </Table.Td>
                <Table.Td>{text__callNumber}</Table.Td>
              </Table.Tr>
              <Table.Tr>
                <Table.Td faded light>
                  {text__hours}
                </Table.Td>
                <Table.Td>{text__memberCareHours}</Table.Td>
              </Table.Tr>
              {has(org, "learnMoreLinks.faq") && (
                <Table.Tr>
                  <Table.Td faded light>
                    {text__faqs}
                  </Table.Td>
                  <Table.Td faded light>
                    <a href={org.learnMoreLinks.faq} target="_blank" rel="noopener noreferrer">
                      {text__learnMore}
                    </a>
                  </Table.Td>
                </Table.Tr>
              )}
            </Table.Body>
          </Panel.Body>
        </Panel.Container>
      </Container>
    </Page.Container>
  );
}

export default Dashboard;
