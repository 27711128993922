export const actionTypes = {
  IS_OPEN: "IS_OPEN",
  MODAL_CONTENT: "MODAL_CONTENT",
  SET_CAN_EXIT: "SET_CAN_EXIT"
};

export function isOpen(bool) {
  return {
    type: actionTypes.IS_OPEN,
    value: bool
  };
}

export function canExit(bool) {
  return {
    type: actionTypes.SET_CAN_EXIT,
    value: bool
  };
}

export function content(component) {
  return {
    type: actionTypes.MODAL_CONTENT,
    value: component
  };
}