import React from "react";
import styled, {css} from "styled-components/macro";
import {toRem} from "styles/Utility";

export default function CardStatusDot(props) {
  return (
    <StatusIcon {...props} status={props.status} />
  )
}

const statusIconHeight = 5;

const StatusIcon = styled.span`
  width: ${toRem(statusIconHeight)};
  height: ${toRem(statusIconHeight)};
  border-radius: ${toRem(statusIconHeight)};
  display: none;
  position: absolute;
  left: calc(100% + ${toRem(statusIconHeight)});
  top: calc(50% - ${toRem(statusIconHeight / 2)});

  ${props =>
    props.status === 'ACTIVE' &&
    css`
      display: inline-block;
      background-color: ${props.theme.colors.success};
    `
  }

  ${props =>
    props.status === 'INACTIVE' &&
    css`
      display: inline-block;
      background-color: ${props.theme.colors.error};
    `
  }
`