import React, { useState, useEffect } from "react";
import { Grid } from "components";
import ProfileSummary from "./ProfileSummary";
import Logout from "./Logout";
import styled, { css } from "styled-components/macro";
import {
  sidebarWidth,
  navId,
  scrollingClass,
  mobileHeight
} from "main/components/navigationSettings";
import { toRem, Query } from "styles/Utility";
import { BgImage } from "styles/Mixins";
import { init } from "./helper";
import { NavLink } from "react-router-dom";
import routePaths from "routes/routePaths";

export default function Navigation(props) {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
      init();
    }
  }, [loaded]);

  let MobileMenuButton;
  if (props.theme) {
    MobileMenuButton = props.theme.navigation.mobileMenuButton;
  }

  return (
    <NavigationContainer id={navId} {...props}>
      <Grid.Row justify="flex-end" align="flex-end" display={{ sm: "none", lg: "flex" }}>
        <StatusGridCol {...props} shrink mr={10}>
          <ProfileSummary {...props} />
        </StatusGridCol>
        <Grid.Col shrink mr={30}>
          <Logout {...props} />
        </Grid.Col>
      </Grid.Row>
      <MobileRow justify="space-between" align="center" display={{ lg: "none" }}>
        <Grid.Col shrink>
          <NavLink to={routePaths.home.path}>
            <Logo />
          </NavLink>
        </Grid.Col>
        <Grid.Col shrink>
          <MobileMenuButton {...props} />
        </Grid.Col>
      </MobileRow>
    </NavigationContainer>
  );
}

const MobileRow = styled(Grid.Row)`
  padding-left: ${props => props.theme.gutter.remSmall};
  padding-right: ${props => props.theme.gutter.remSmall};
  width: 100%;
`;

const Logo = styled.div`
  ${BgImage("contain")}
  width: ${props => toRem(props.theme.navigation.logo.mobileWidth)};
  height: ${toRem(50)};
  background-image: url(${props => props.theme.navigation.logo.src});
  background-position: left center;
`;

const StatusGridCol = styled(Grid.Col)`
  opacity: 0;
  transform: translateY(${toRem(-15)});
  transition: opacity 0.4s, transform 0.4s;
  pointer-events: none;

  ${props =>
    props.store.navigation.showStatusBar &&
    css`
      opacity: 1;
      transform: translateY(0px);
      pointer-events: all;
    `}
`;

const NavigationContainer = styled.nav`
  width: calc(100% - ${toRem(sidebarWidth)});
  z-index: 100;
  position: fixed;
  top: 0;
  right: 0;
  padding: ${props => props.theme.gutter.rem} ${props => props.theme.gutter.remSmall};
  background-color: ${props => props.theme.colors.bgDark}e8;
  border-bottom: 1px solid transparent;
  transform: translateY(-101%);
  transition: 0.3s border-color, 0.3s padding, transform 0.3s, opacity 0.3s;
  opacity: 0;
  pointer-events: none;
  will-change: border-color, padding, transform, opacity;
  z-index: 100;

  @media ${Query.smallDown} {
    width: 100%;
    padding: 0;
    position: absolute;
    height: ${toRem(mobileHeight)};
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 150;
    border-bottom: 1px solid ${props => props.theme.colors.bgLight};
  }

  &.${scrollingClass} {
    @media ${Query.medium} {
      border-color: ${props => props.theme.colors.bgLight};
      padding: ${props => props.theme.gutter.remSmall} ${props => props.theme.gutter.remSmall};
    }
  }

  ${props =>
    props.store.navigation.showSidebar &&
    css`
      transform: translateY(0);
      opacity: 1;
      pointer-events: all;
    `}
`;
