import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { activeLink } from "store/navigation";
import routePaths from "./routePaths";
import store from "store";

const RouteListener = ({ history }) => {
  useEffect(() => {
    //On load, just give it the window pathname
    store.dispatch(activeLink(window.location.pathname));

    history.listen((location, action) => {
      const { pathname } = location;

      //When location changes, update store
      store.dispatch(activeLink(pathname));
    });

    //Push to login if nothing
    if (history.location.pathname === "/") return history.push(routePaths.login.path);
  }, [history]);

  return <div></div>;
};

export default withRouter(RouteListener);
