import React from "react";
import routePaths from "./routePaths";
import AuthenticationWrapper from "./AuthenticationWrapper";
import { withTheme } from "styled-components";
import { Route, withRouter } from "react-router-dom";
import PageInitializer from "main/components/PageInitializer";

const AuthenticationWrapperComponent = withRouter(props => <AuthenticationWrapper {...props} />);

const staticRoutes = Object.keys(routePaths).map((route, i) => {
  const routeObject = routePaths[route];
  const Component = withTheme(routeObject.component);

  return (
    <Route
      key={i}
      path={routeObject.path}
      exact={routeObject.exact ? true : false}
      render={routeProps => (
        <AuthenticationWrapperComponent {...routeProps}>
          <PageInitializer
            {...routeProps}
            sidebar={routeObject.sidebar ? routeObject.sidebar : false}
            statusBar={routeObject.statusBar ? routeObject.statusBar : false}
            pageTitle={routeObject.title ? routeObject.title : ""}
            dataRequired={routeObject.data ? routeObject.data : ""}
            requiredStatus={routeObject.requiredStatus ? routeObject.requiredStatus : false}
          >
            <Component {...routeProps} />
          </PageInitializer>
        </AuthenticationWrapperComponent>
      )}
    />
  );
});

export default () => {
  return staticRoutes;
};
