import React from "react";
import { Container, Page } from "components";
import { Card } from "./components";
import Locales from "locales";
import has from "lodash/has";

const text__title = Locales("page.cards.title");

function Dashboard(props) {
  const cardsExist = has(props, "store.cards.details.cards");
  const cards = cardsExist ? props.store.cards.details.cards : false;

  return (
    <Page.Container>
      <Container>
        <Page.Title>{text__title}</Page.Title>
        {cardsExist && (
          <div>
            {cards.map((card, i) => (
              <Card key={i} activated card={card} {...props} />
            ))}
          </div>
        )}
      </Container>
    </Page.Container>
  );
}

export default Dashboard;
