import React from 'react';
import styled, {css} from "styled-components/macro";
import { P, Small, Icon, Reward } from "components";
import { toRem } from "styles/Utility";
import { Fill, BgImage } from "styles/Mixins";
import { isOpen, content } from "store/modal";
import Locales from "locales";
import store from "store";

/* ======================================================================
Listed Item
====================================================================== */

function RewardListItem(props) {
  return (
      <RewardContainer onClick={() => {
          store.dispatch(content(<Reward.Description {...props} onActivate={props.onActivate ? props.onActivate : false} />));
          store.dispatch(isOpen(true));
        }}>
        <ImageContainer>
          <Image image={props.image ? props.image : false} />
          {props.subscribed && <Star><Icon name="star"/></Star>}
        </ImageContainer>
        <RewardDescription>
          {props.merchantName && <P mReset>{props.merchantName}</P>}
          {props.title && <DescriptionText smaller mReset faded mt={5}>{props.title}</DescriptionText>}
          {props.offerExpiryTimeLeft && <div><Small smaller mReset faded mt={5}>{Locales('reward.expiryTimeLeft', {expiryTime: Math.floor(props.offerExpiryTimeLeft / 86400)})}</Small></div>}
        </RewardDescription>
      </RewardContainer>
  );
}

const Star = styled.div`
  width: ${toRem(40)};
  height: ${toRem(40)};
  border-radius: ${toRem(40)};
  background-color: ${props => props.theme.colors.bg};
  color: ${props => props.theme.colors.warning};
  font-size: ${toRem(16)};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: ${toRem(15)};
  padding-left: 1px;
  right: ${toRem(15)};
  z-index: 20;
`

const RewardContainer = styled.div`
  overflow: hidden;
  border-radius: ${props => toRem(props.theme.panel.borderRadius)};
  cursor: pointer;
  display: flex;
  flex: 1;
  height: 100%;
  width: 100%;
  flex-direction: column;
  transition: opacity 0.3s;
  will-change: opacity;
  background-color: ${props => props.theme.colors.bgLight};

  &:hover {
    opacity: 0.9;
  }
`;

// const TypeOfReward = styled(FlexContainer)`
//   position: absolute;
//   left: ${props => props.theme.gutter.remSmall};
//   bottom: 100%;
//   transform: translateY(60%);
//   background-color: ${props => props.theme.colors.bgLight};
//   padding: ${toRem(5)} ${props => props.theme.gutter.remSmall};
//   border-radius: 10px;
// `

const ImageContainer = styled.div`
  padding-bottom: 69%;
  position: relative;
  background-image: url(${require('images/pattern--dot-grid--dark.png')});
  background-size: 300px 300px;
`

const Image = styled.div`
  ${Fill}
  ${BgImage('cover')}

  ${props =>
    props.image &&
    css`
      background-image: url(${props => props.image});
    `
  }
`

const RewardDescription = styled.div`
  position: relative;
  z-index: 2;
  padding: ${toRem(20)} ${props => props.theme.gutter.rem};
`

const DescriptionText = styled(Small)`
  line-clamp: 2;
`



export default RewardListItem;