import React from "react";
import { Panel, Heading, P } from "components";
import { toRem } from "styles/Utility";
import styled, { css } from "styled-components/macro";

export default function LoginComponent(props) {
  return (
    <LoginModuleContainer>
      <Panel.Container>
        <StyledHeaderContainer>
          <StyledHeading type="h3" center mReset>
            {props.title && props.title}
          </StyledHeading>
        </StyledHeaderContainer>
        <StyledPanelBody
          status={props.status && props.status}
          p={`${toRem(30)} ${toRem(15)} ${toRem(30)} ${toRem(15)}`}
        >
          <StatusMessageBar status={props.status && props.status}>
            <P mReset small center>
              {props.statusMessage}
            </P>
          </StatusMessageBar>
          {props.children && props.children}
        </StyledPanelBody>
      </Panel.Container>
    </LoginModuleContainer>
  );
}

const StyledHeading = styled(Heading)`
  width: 100%;
`;

const StyledPanelBody = styled(Panel.Body)`
  position: relative;
  transition: padding 0.2s;
  will-change: padding;

  ${props =>
    props.status !== "" &&
    props.status !== undefined &&
    props.status !== false &&
    css`
      padding-top: ${toRem(40)};
    `}
`;

const StatusMessageBar = styled.div`
  padding: ${toRem(5)} ${toRem(15)};
  display: none;
  margin-bottom: ${toRem(20)};
  margin-left: ${toRem(-15)};
  width: calc(100% + ${toRem(30)});
  position: absolute;
  top: 0;
  left: 0;
  color: ${props => props.theme.colors.white};

  ${props =>
    (props.status === "SUCCESS" || props.status === "ERROR") &&
    css`
      display: block;
    `}

  ${props =>
    props.status === "SUCCESS" &&
    css`
      background-color: ${props => props.theme.colors.success};
    `}

  ${props =>
    props.status === "ERROR" &&
    css`
      background-color: ${props => props.theme.colors.error};
    `}
`;

const StyledHeaderContainer = styled(Panel.Header)`
  padding: ${toRem(25)} ${toRem(15)};
`;

const LoginModuleContainer = styled.div`
  width: ${toRem(300)};
  max-width: 100%;
`;
