import React from "react";
import { P, Grid, CardStatusDot } from "components";
import styled from "styled-components/macro";
import { Margins } from "styles/Mixins";
import Locales from "locales";
import { getFormattedStatus } from "utility/Cards";

//Text
const text__virtualCard = Locales("card.virtualCard");
const text__physicalCard = Locales("card.physicalCard");
const text__cardStatus = Locales("card.cardStatus");

export default function CardsModule(props) {
  const isVirtual = props.type === "VIRTUAL";
  const cardAsset = isVirtual
    ? require(`org/cards/virtual.svg`)
    : require(`org/cards/physical.svg`);

  return (
    <Grid.Col shrink>
      <CardContainer mr={{ sm: 15 }} ml={{ sm: 15 }} mt={{ sm: 25, md: 15 }}>
        <img src={cardAsset} alt={props.type} />
        <P mReset mt={10}>
          {isVirtual ? text__virtualCard : text__physicalCard}
        </P>
        <StyledStatusText
          smaller
          mReset
          faded={props.status === "ISSUED_INACTIVE"}
        >
          {text__cardStatus}: {getFormattedStatus(props.status)}
          <CardStatusDot status={props.status} />
        </StyledStatusText>
      </CardContainer>
    </Grid.Col>
  );
}

const StyledStatusText = styled(P)`
  position: relative;
  display: inline-block;
`;

const CardContainer = styled.div`
  ${Margins}
  text-align: center;
`;
