import React from "react";
import styled, {withTheme, css} from "styled-components";

//Got from https://loading.io/css/
function Spinner(props) {
  return <SpinnerEl {...props}><span></span><span></span><span></span><span></span></SpinnerEl>;
}

export default withTheme(Spinner);

const SpinnerEl = styled.span`
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;

  ${props =>
    props.size &&
    css`
      width: ${props.size}px;
      height: ${props.size}px;
    `
  }

  span {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 15px;
    height: 15px;
    margin: 6px;
    border: 3px solid ${props => props.theme.colors.popFaded};
    border-radius: 50%;
    animation: lds-ring 0.4s linear infinite;
    border-color: ${props => props.theme.colors.popFaded} transparent transparent transparent;
  }

  ${props =>
    props.color &&
    css`
      span {
        border-color: ${props => props.color} transparent transparent transparent;
      }
    `
  }

  span:nth-child(1) {
    animation-delay: -0.45s;
  }

  span:nth-child(2) {
    animation-delay: -0.3s;
  }

  span:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`