import React from "react";
import { Span, Icon, P } from "components";
import { randomId } from "utility";
import styled, { keyframes, css } from "styled-components";
import { toRem } from "styles/Utility";
import { Unhighlightable } from "styles/Mixins";
import Locales from "locales";

const inputPrefix = "input_";
const copiedTextAlertPrefix = "copied_";
const activeClass = "tap-to-copy-active";
const copiedAnimationMilliseconds = 600;
const text__tapToCopy = Locales("general.tapToCopy");
const text__copied = Locales("general.copied");

function handleClick(id) {
  const copyText = document.getElementById(inputPrefix + id);
  const copiedAlert = document.getElementById(copiedTextAlertPrefix + id);
  copiedAlert.classList.remove(activeClass);
  copyText.select();
  copyText.setSelectionRange(0, 99999);
  document.execCommand("copy");
  copiedAlert.classList.add(activeClass);

  setTimeout(function() {
    copiedAlert.classList.remove(activeClass);
  }, copiedAnimationMilliseconds + 50);
}

export default function TapToCopy(props) {
  const id = randomId();

  return (
    <ClickableArea
      {...props}
      onClick={() => {
        handleClick(id);
      }}
      title={text__tapToCopy}
    >
      <CopyiedAlert id={`${copiedTextAlertPrefix + id}`}>
        <P mReset smaller>
          {text__copied}
        </P>
      </CopyiedAlert>
      <StyledInput readOnly id={`${inputPrefix + id}`} value={props.copyText} />
      {props.displayText && props.displayText}
      <StyledIcon
        name="copy"
        showIconOnHover={props.showIconOnHover ? props.showIconOnHover : false}
      />
    </ClickableArea>
  );
}

const CopiedAnimation = keyframes`
    from {
        opacity: 1;
        transform: translate(-50%, 0);
    },
    to {
        opacity: 0;
        transform: translate(-50%, -40px);
    }
`;

const ClickableArea = styled(Span)`
  ${Unhighlightable}
  cursor: pointer;
  position: relative;
  display: inline-block;
`;

const CopyiedAlert = styled.div`
  display: inline-block;
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.black};
  padding: ${toRem(3)} ${toRem(5)};
  border-radius: 10px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translate(-50%, 0);
  pointer-events: none;
  opacity: 0;

  &.${activeClass} {
    animation: ${copiedAnimationMilliseconds / 1000}s ${CopiedAnimation} 1 forwards;
  }
`;

const StyledInput = styled.input`
  opacity: 0;
  display: block;
  left: 9999px;
  position: absolute;
`;

const StyledIcon = styled(Icon)`
  color: ${props => props.theme.colors.popFaded};
  font-size: 0.7em;
  margin-left: ${toRem(5)};

  ${ClickableArea}:hover & {
    color: ${props => props.theme.colors.pop};
  }

  ${props =>
    props.showIconOnHover &&
    css`
      opacity: 0;

      ${ClickableArea}:hover & {
        opacity: 1;
      }
    `}
`;
