import React from "react";
import styled, {css} from "styled-components";
import {toRem} from "styles/Utility";
import {Spinner} from "components";
import {Text, Margins} from "styles/Mixins";

export default function CircleTextCta(props) {
  return (
    <StyledTextCTA {...props} as="p">
        {props.loading ? (
          <StyledIcon><StyledSpinner size={30} /></StyledIcon>
        ):(
          <span>{props.icon && <StyledIcon className={`icon-${props.icon}`} /> }</span>
        )}
        
         <span>{props.children}</span>
    </StyledTextCTA>
  )
}

const StyledTextCTA = styled.p`
  ${Margins}
  ${Text}
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  ${props =>
    props.loading &&
    css`
      pointer-events: none;
    `
  }

  ${props =>
    props.disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `
  }
`

const StyledSpinner = styled(Spinner)`
  position: absolute;
  top: -2px;
  left: -2px;
`

const iconSize = toRem(25);

const StyledIcon = styled.i`
  color: ${props => props.theme.colors.pop};
  border: 1px solid ${props => props.theme.colors.popFaded};
  position: relative;
  width: ${iconSize};
  height: ${iconSize};
  border-radius: ${iconSize};
  font-size: ${toRem(12)};
  display: block;
  transition: transform 0.2s;
  will-change: transform;
  text-align: center;
  margin-right: ${toRem(5)};

  &:before {
      line-height: ${iconSize}
  }

  ${StyledTextCTA}:hover & {
    border-color: ${props => props.theme.colors.pop};
    color: ${props => props.theme.colors.pop};
  }
`