import React from 'react';
import styled from "styled-components/macro";
import options from './options';
import LoadingOption from './LoadingOption';
import LoadingOptionInfo from './LoadingOptionInfo';
import { toRem } from "styles/Utility";

function Options(props) {
  return (
    <div>
        <OptionsContainer>
            {options.map((option, i) => <LoadingOption isActive={(i === 0) ? true : false} key={i} {...option} /> )}
        </OptionsContainer>
        <OptionsInfoContainer>
            {options.map((option, i) => {
              const ContentComponent = option.contentComponent;

              return (
                <LoadingOptionInfo isActive={(i === 0) ? true : false} key={i} {...option}>
                  <ContentComponent {...props.store} />
                </LoadingOptionInfo>
              )
            })}
        </OptionsInfoContainer>
    </div>
  );
}

const OptionsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 ${props => toRem(props.theme.gutter.small * -1)};
`

const OptionsInfoContainer = styled.div`
    
`


export default Options;
