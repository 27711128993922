import React, { useEffect, useState } from "react";
import { RouteIsAllowed, Route } from "utility/Authentication";
import { connect } from "react-redux";

function AuthWrapper(props) {
  const [isAllowed, setIsAllowed] = useState(false);
  const status = props.store.user.status;
  const path = props.location.pathname;

  useEffect(() => {
    const isValidPath = RouteIsAllowed({ status, path });

    setIsAllowed(isValidPath);

    if (!isValidPath) {
      Route({
        status,
        history: props.history
      });
    }
  }, [status, props.history, path]);

  /*
  Only show the route if the user is allowed in based on status
  The above function will determine the redirect
  So if it redirects to a correct page, We're good.
  */
  return isAllowed ? <div>{props.children}</div> : <div></div>;
}

const mapStateToProps = state => {
  return {
    store: {
      ...state
    }
  };
};

export default connect(mapStateToProps)(AuthWrapper);
