import React from 'react';
import styled, { css } from "styled-components/macro";
import { Query, toRem } from "styles/Utility";
import { Margins, Visibility } from "styles/Mixins";

export default function Col(props) {
  return (
    <StyledCol {...props}>{props.children}</StyledCol>
  );
}

function getSize(colNumber, props) {
  const {gridSize} = props.theme.gridSettings;
  return (colNumber / gridSize) * 100 + '%';
}

const StyledCol = styled.div`
  padding-left: ${props => props.theme.gutter.remSmall};
  padding-right: ${props => props.theme.gutter.remSmall};
  min-width: 0;
  flex: 1 1 0px;
  ${Margins}
  ${Visibility}

  & > .row {
    margin-left: ${props => toRem(props.theme.gutter.small * -1)};
    margin-right: ${props => toRem(props.theme.gutter.small * -1)};
  }

  ${props => 
    props.vPad &&
    css`
      padding-top: ${props => props.theme.gutter.remSmall};
      padding-bottom: ${props => props.theme.gutter.remSmall};
    `
  }

  ${props => 
    props.noPad &&
    css`
      padding-left: 0;
      padding-right: 0;
    `
  }

  ${props =>
    props.shrink &&
    css`
      ${props => 
        typeof props.shrink !== 'object' &&
        css`
          flex: 0 0 auto;
          max-width: 100%;
        `
      }    
    `
  }

  ${props => 
    props.shrink &&
    css`
      ${props.shrink.lg &&
        css`
          @media ${Query.medium} {
            flex: 0 0 auto;
            max-width: 100%;
          }
        `
      }
      ${props.shrink.md &&
        css`
          @media ${Query.small} {
            flex: 0 0 auto;
            max-width: 100%;
          }
        `
      }
      ${props.shrink.sm &&
        css`
          flex: 0 0 auto;
          max-width: 100%;
        `
      }
    `
  }

  ${props => 
    props.size &&
    css`
      ${props.size.lg &&
        css`
          @media ${Query.medium} {
            flex: 0 0 ${getSize(props.size.lg, props)};
            max-width: ${getSize(props.size.lg, props)};
          }
        `
      }
      ${props.size.md &&
        css`
          @media ${Query.small} {
            flex: 0 0 ${getSize(props.size.md, props)};
            max-width: ${getSize(props.size.md, props)};
          }
        `
      }
      ${props.size.sm &&
        css`
          flex: 0 0 ${getSize(props.size.sm, props)};
          max-width: ${getSize(props.size.sm, props)};
        `
      }
    `
  }

  ${props => 
    props.offset &&
    css`
      ${props.offset.lg &&
        css`
          @media ${Query.medium} {
            margin-left: ${getSize(props.offset.lg, props)};
          }
        `
      }
      ${props.offset.md &&
        css`
          @media ${Query.small} {
            margin-left: ${getSize(props.offset.md, props)};
          }
        `
      }
      ${props.offset.sm &&
        css`
          margin-left: ${getSize(props.offset.sm, props)};
        `
      }
    `
  }


`;