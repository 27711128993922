import React from "react";
import {Text, Margins} from "styles/Mixins";
import styled, {css} from "styled-components";
import find from "lodash/find";
import { Query } from "styles/Utility";

export default function Heading(props) {
  return <StyledHeading {...props}>{props.children}</StyledHeading>
}

const StyledHeading = styled.h3`
  ${Text}
  ${Margins}

  ${props => 
    props.type &&
    css`
      font-size: ${props => find(props.theme.type.displayFontSettings, {tag: props.type}).size};

      @media ${Query.smallDown} {
        font-size: ${props => find(props.theme.type.displayFontSettings, {tag: props.type}).mobileSize};
      }
    `
  }
`
