import React from 'react';
import styled, {css} from "styled-components";

function CenterContent(props) {
  return (
      <FlexyBoi {...props}>{props.children}</FlexyBoi>
  );
}

const FlexyBoi = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${props =>
    props.fullHeight &&
    css`
      min-height: 100vh;
    `};

  ${props =>
    props.fullWidth &&
    css`
      width: 100%;
    `};
`;

export default CenterContent;