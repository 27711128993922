import {css} from "styled-components";
import ResponsivePropGenerator from "./ResponsivePropGenerator";

export default css`
  ${props => 
    props.mReset && 
    css`
      margin: 0;
    `}

  ${ResponsivePropGenerator({
    cssProperty: 'margin-top', 
    cssUnit: 'rem',
    propName: 'mt', 
    expectedType: 'number'
  })}

  ${ResponsivePropGenerator({
    cssProperty: 'margin-left', 
    cssUnit: 'rem',
    propName: 'ml', 
    expectedType: 'number'
  })}

  ${ResponsivePropGenerator({
    cssProperty: 'margin-right', 
    cssUnit: 'rem',
    propName: 'mr', 
    expectedType: 'number'
  })}

  ${ResponsivePropGenerator({
    cssProperty: 'margin-bottom', 
    cssUnit: 'rem',
    propName: 'mb', 
    expectedType: 'number'
  })}

  ${ResponsivePropGenerator({
    cssProperty: 'margin', 
    cssUnit: 'none',
    propName: 'm', 
    expectedType: 'string'
  })}
`