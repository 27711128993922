import { buildRequest } from "utility/StackApi";
import { getEtag } from "utility/Token";


export async function getFeatureFlagConfig() {
  return buildRequest({
    verb: 'get',
    headers: {"if-none-match": getEtag()},
    endpoint: '/config'
  })
}

