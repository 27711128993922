import { getFeatureFlagConfig } from "utility/StackApi/FeatureFlagging";
import { setFeatureFlagging } from "store/featureFlagging";
import store from "store";
import has from "lodash/has";
import Locales from "locales";

//fetch profile info (for navigation)
export async function setFeatureFlaggingConfig() {
  try {
    const response = await getFeatureFlagConfig();
    store.dispatch(setFeatureFlagging({ ...response.data }));
    return response;
  } catch (err) {
    return;
  }
}

export const featureEnums = {
  autoEtransfer: "AUTO_ETRANSFER",
  etransferRequest: "ETRANSFER_REQUEST",
  cashLoad: "CASH_LOAD",
  fireBase: "FIREBASE",
  p2pInvite: "P2P_INVITE",
  virtualCard: "VIRTUAL_CARD",
  jumio: "ID_VERIFICATION",
};

//Supply featureEnum to this function to
//Determine if feature is enabled
export function featureIsEnabled(featureEnum) {
  const state = store.getState();
  const hasEnabledFeaturesArr = has(state, "featureFlagging.enabledFeatures");
  if (!hasEnabledFeaturesArr) return false;

  const { enabledFeatures } = state.featureFlagging;
  return enabledFeatures.includes(featureEnum);
}

//Get generic "Feature unavailable" text
export function featureUnavailableText(featureName) {
  return Locales("general.featureUnavailable", { feature: featureName });
}
