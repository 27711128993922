import React from "react";
import styled from "styled-components";
import {Text, Margins} from "styles/Mixins";

function P(props) {
  return <StyledP {...props}>{props.children}</StyledP>
}

const StyledP = styled.p`
  ${Margins}
  ${Text}
`

export default P;
