import React, { useState, useCallback } from "react";
import { P, Button, Small, Heading } from "components";
import Locales from "locales";
import styled from "styled-components/macro";
import { Input } from "@material-ui/core";
import { Query, toRem } from "styles/Utility";
import { prettyFormat } from "utility/Money";
import { etransferRequest } from "utility/StackApi/Money";
import {
  featureEnums,
  featureIsEnabled,
  featureUnavailableText,
} from "utility/FeatureFlagging";
import { isOpen, content } from "store/modal";
import store from "store";

const text__description = Locales("page.addMoney.etransferRequest.description");
const text__inputPlaceholder = Locales(
  "page.addMoney.etransferRequest.inputPlaceholder"
);
const text__inputNote = Locales("page.addMoney.etransferRequest.inputNote");
const text__buttonText = Locales("page.addMoney.etransferRequest.buttonText");
const text__legal = Locales("page.addMoney.etransferRequest.legal");

const text__confirmTitle = Locales(
  "page.addMoney.etransferRequest.confirmTitle"
);
const text__confirm = Locales("general.confirm");
const text__cancel = Locales("general.cancel");

const text__errorLessThan = Locales(
  "page.addMoney.etransferRequest.errorLessThan"
);
const text__errorNumber = Locales("page.addMoney.etransferRequest.errorNumber");
const text__errorMoreThan = Locales(
  "page.addMoney.etransferRequest.errorMoreThan"
);
const text__successTitle = Locales(
  "page.addMoney.etransferRequest.successTitle"
);
const text__successDone = Locales("page.addMoney.etransferRequest.successDone");
const text__errorTitle = Locales("page.addMoney.etransferRequest.errorTitle");
const text__errorDescription = Locales(
  "page.addMoney.etransferRequest.errorDescription"
);

const text__errorCall = Locales("page.addMoney.etransferRequest.errorCall");

const text__errorTooManyRequestsTitle = Locales(
  "page.addMoney.etransferRequest.errorTooManyRequestsTitle"
);
const text__errorTooManyRequestsDesc = Locales(
  "page.addMoney.etransferRequest.errorTooManyRequestsDesc"
);

const text__callNumber = Locales("global.callNumber");

const detectErrors = (value) => {
  let error = false;

  if (isNaN(value)) {
    error = text__errorNumber;
  } else if (value.split(".")[1] && value.split(".")[1].length > 2) {
    error = text__errorNumber;
  } else if (value < 10) {
    error = text__errorLessThan;
  } else if (value > 3000) {
    error = text__errorMoreThan;
  }

  if (value === "") {
    error = false;
  }

  return error;
};

const screenKeys = {
  FORM: "FORM",
  SUCCESS: "SUCCESS",
  FAIL: "FAIL",
};

const failKeys = {
  UNKNOWN: "UNKNOWN",
  TOO_MANY_REQUESTS: "TOO_MANY_REQUESTS",
};

const ModalConfirm = ({
  amount,
  setCurrentScreen,
  setFailType,
  setRequestId,
}) => {
  const [loading, setLoading] = useState(false);
  const formattedMoney = prettyFormat(amount);

  const handleCancel = useCallback(() => {
    store.dispatch(isOpen(false));
  }, []);

  const handleSubmit = useCallback(async () => {
    setLoading(true);

    try {
      const resp = await etransferRequest(Number(amount));

      if (resp?.data?.requestId) {
        setRequestId(resp.data.requestId);
      }

      setCurrentScreen(screenKeys.SUCCESS);
    } catch (err) {
      if (err.status === 400) {
        setFailType(failKeys.TOO_MANY_REQUESTS);
      }

      setCurrentScreen(screenKeys.FAIL);
    }

    store.dispatch(isOpen(false));
    setLoading(false);
  }, [setCurrentScreen, amount, setFailType, setRequestId]);

  return (
    <ModalContentContainer>
      <Heading type="h3">{text__confirmTitle}</Heading>
      <P>
        {Locales("page.addMoney.etransferRequest.confirmDescription", {
          amount: formattedMoney,
        })}
      </P>
      <Button loading={loading} mb={10} onClick={handleSubmit}>
        {text__confirm}
      </Button>
      <Button disabled={loading} onClick={handleCancel} secondary>
        {text__cancel}
      </Button>
    </ModalContentContainer>
  );
};

export function FormComponent(props) {
  const [inputValue, setInputValue] = useState(null);
  const [inputErr, setInputErr] = useState(false);
  const { setCurrentScreen, setFailType, setRequestId } = props;

  const handleButtonClick = useCallback(() => {
    store.dispatch(
      content(
        <ModalConfirm
          setFailType={setFailType}
          setCurrentScreen={setCurrentScreen}
          amount={inputValue}
          setRequestId={setRequestId}
        />
      )
    );
    store.dispatch(isOpen(true));
  }, [inputValue, setCurrentScreen, setFailType, setRequestId]);

  const handleInputChange = useCallback(
    (e) => {
      const value = e.target.value;
      const errors = detectErrors(value);
      setInputValue(value);

      if (errors) {
        setInputErr(errors);
      } else {
        setInputErr(false);
      }
    },
    [setInputValue]
  );

  return (
    <div>
      {featureIsEnabled(featureEnums.etransferRequest) ? (
        <div>
          <P mb={25}>{text__description}</P>
          <InputContainer>
            <Input
              placeholder={text__inputPlaceholder}
              type="text"
              value={inputValue}
              style={{ width: 200 }}
              onChange={(e) => {
                handleInputChange(e);
              }}
            />
            <div>
              {inputErr ? (
                <Small error>{inputErr}</Small>
              ) : (
                <Small faded>{text__inputNote}</Small>
              )}
            </div>
            <Button
              disabled={inputValue && !inputErr ? false : true}
              onClick={handleButtonClick}
              mt={15}
            >
              {text__buttonText}
            </Button>
          </InputContainer>
          <Small mt={25} faded>
            {text__legal}
          </Small>
        </div>
      ) : (
        <P faded>{featureUnavailableText(text__errorDescription)}</P>
      )}
    </div>
  );
}

const ResultComponent = (props) => {
  const { failType, requestId, resetFlow } = props;

  let failTitle =
    failType === failKeys.UNKNOWN
      ? text__errorTitle
      : text__errorTooManyRequestsTitle;
  let failDescription =
    failType === failKeys.UNKNOWN
      ? text__errorDescription
      : text__errorTooManyRequestsDesc;

  return (
    <>
      {props.currentScreen === screenKeys.SUCCESS ? (
        <div>
          <Heading type="h4" success>
            {text__successTitle}
          </Heading>
          <P>
            {Locales("page.addMoney.etransferRequest.successDescription", {
              email: props?.user?.profile?.email,
            })}
          </P>
          {requestId && (
            <P>
              {Locales("page.addMoney.etransferRequest.successTransId", {
                transactionId: `#${requestId}`,
              })}
            </P>
          )}
          <Button onClick={resetFlow}>{text__successDone}</Button>
        </div>
      ) : (
        <div>
          <div>
            <Heading type="h4">{failTitle}</Heading>
            <P>{failDescription}</P>
            {failType === failKeys.UNKNOWN && (
              <P underline>
                <a href={`tel:${text__callNumber}`}>{text__errorCall}</a>
              </P>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default function (props) {
  const [currentScreen, setCurrentScreen] = useState(screenKeys.FORM);
  const [failType, setFailType] = useState(failKeys.UNKNOWN);
  const [requestId, setRequestId] = useState(null);

  const resetFlow = useCallback(() => {
    setCurrentScreen(screenKeys.FORM);
    setFailType(failKeys.UNKNOWN);
    setRequestId(null);
  }, [setCurrentScreen, setFailType, setRequestId]);

  return (
    <>
      {currentScreen === screenKeys.FORM ? (
        <FormComponent
          {...props}
          setFailType={setFailType}
          setCurrentScreen={setCurrentScreen}
          setRequestId={setRequestId}
        />
      ) : (
        <ResultComponent
          {...props}
          currentScreen={currentScreen}
          failType={failType}
          requestId={requestId}
          resetFlow={resetFlow}
        />
      )}
    </>
  );
}

const ModalContentContainer = styled.div`
  background-color: ${(props) => props.theme.colors.bg};
  color: ${(props) => props.theme.colors.pop};
  padding: ${(props) => toRem(props.theme.gutter.default * 2)}
    ${(props) => props.theme.gutter.rem};
  width: ${toRem(300)};
  text-align: center;
`;

const InputContainer = styled.div`
  @media ${Query.smallDown} {
    text-align: center;

    input {
      text-align: center;
    }
  }
`;
