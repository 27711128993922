import store, { hidingScreen, setCurrentScreen } from "./store";

export const animationDuration = 300;

export function hideScreens() {
  store.dispatch(hidingScreen(true));
}

export function hideAndShowModule(screenToShow) {
  store.dispatch(hidingScreen(true));

  setTimeout(function() {
    store.dispatch(setCurrentScreen(screenToShow));
    setTimeout(function() {
      store.dispatch(hidingScreen(false));
    }, 20);
  }, animationDuration);
}
