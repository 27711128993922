import React, { useEffect } from "react";
import org from "org";
import { createGlobalStyle, css } from "styled-components";
import { isMobile } from "utility";
import tracking from "utility/tracking";
import theme from "styles/Theme.js";

let ran = false;

export default function ZendeskWidget(props) {
  useEffect(() => {
    if (props.store.page.pageRequiredStatus && !ran) {
      ran = true;

      const widget = document.getElementById("launcher");

      if (process.env.REACT_APP_ZENDESK_WIDGET_ID && !widget) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.setAttribute("id", "ze-snippet");
        script.src = `https://static.zdassets.com/ekr/snippet.js?key=${process.env.REACT_APP_ZENDESK_WIDGET_ID}`;
        document.getElementsByTagName("body")[0].appendChild(script);
      }

      var checkForZendesk = setInterval(() => {
        //Color for zendesk popup
        if (theme?.colors?.primary) {
          window.zESettings = {
            webWidget: {
              color: { theme: theme.colors.primary },
            },
          };
        }

        if (window.$zopim) {
          window.$zopim(function () {
            //Track users opening chat dialogue
            window.$zopim.livechat.window.onShow(function () {
              tracking.event("Chat");
            });

            //Change department to whatever user has selected
            if (org.zendeskWidgetVisitorDepartment) {
              window.$zopim.livechat.departments.setVisitorDepartment(
                org.zendeskWidgetVisitorDepartment
              );
              window.$zopim.livechat.departments.filter("");
            }
            clearInterval(checkForZendesk);
          });
        }
      }, 200);
    }
  }, [props.store.page.pageRequiredStatus]);
  return <GenericFormStyling />;
}

//Fix for android showing enormous zendesk button
//Hide it if styling is indicating it is large
const GenericFormStyling = createGlobalStyle`
  ${
    isMobile() &&
    css`
      iframe#launcher[tabindex="0"][style*="40px 80px"] {
        display: none !important;
      }
    `
  }
`;
