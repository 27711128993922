import React from "react";
import { Panel, Grid, Button, Reward } from "components";
import { NavLink } from "react-router-dom";
import Locales from "locales";
import SpacingWrapper from "./SpacingWrapper";
import has from "lodash/has";
import routePaths from "routes/routePaths";

const text__rewardsTitle = Locales("page.home.rewardsTitle");
const text__viewAll = Locales("general.viewAll");

export default function RewardsModule(props) {
  const hasRewardsProp = has(props, "store.rewards.list.offers");
  const hasRewards = hasRewardsProp ? props.store.rewards.list.offers.length > 0 : false;

  if (hasRewards) {
    const { offers } = props.store.rewards.list;

    return (
      <SpacingWrapper>
        <Panel.Container hideColors>
          <Panel.Header hideColors icon="star--outline" iconColor={props.theme.colors.primaryLight}>
            <Panel.Heading hideColors>{text__rewardsTitle}</Panel.Heading>
          </Panel.Header>
          <Panel.Body hideColors noHorizontalPad>
            <Grid.Row alignCenter collapse>
              {offers.map((reward, i) => {
                const max = 4;
                const count = i + 1;

                if (count <= max) {
                  return (
                    <Grid.Col
                      vPad
                      key={i}
                      size={{ sm: 6, md: 4 }}
                      display={{ sm: "block", md: count === max ? "none" : "block" }}
                    >
                      <Reward.ListItem {...reward} />
                    </Grid.Col>
                  );
                } else {
                  return null;
                }
              })}
            </Grid.Row>
            <Grid.Row align="center" justify="center">
              <Grid.Col mt={25} shrink>
                <NavLink to={routePaths.rewards.path}>
                  <Button>{text__viewAll}</Button>
                </NavLink>
              </Grid.Col>
            </Grid.Row>
          </Panel.Body>
        </Panel.Container>
      </SpacingWrapper>
    );
  } else {
    return <span />;
  }
}
