import Locales from "locales";

/* =========================================
Supply default message;
SEND_MONEY - SEND_MONEY_REFUND - RECEIVE_MONEY
To receive formatted message
========================================= */

const text__eTransferLoad = Locales('transaction.eTransferLoad');
const text__goalCashOut = Locales('transaction.goalCashOut');

export function getFormattedP2PTitle(transaction) {
  const {transactionType} = transaction;
  const {transactorName} = transaction;

  switch(transactionType) {
    case 'SEND_MONEY':
      const messaging = (transaction.transactionDirection === 'CREDIT') ? 'transaction.p2p.received' : 'transaction.p2p.sentSuccessful';
      return Locales(messaging, {fullName: transactorName});
    case 'SEND_MONEY_REFUND':
      return Locales('transaction.p2p.declined', {fullName: transactorName});
    case 'RECEIVE_MONEY':
      return Locales('transaction.p2p.received', {fullName: transactorName});
    case 'E_TRANSFER_LOAD':
      return text__eTransferLoad;
    case 'GOAL_CASHOUT':
      return text__goalCashOut;
    case 'OFFER_REDEMPTION':
      return Locales('transaction.offerRedemption', {locationName: transactorName});
    default:
      return transaction.merchantName ? transaction.merchantName : transactorName;
  }
}

