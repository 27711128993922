import React from "react";
import { Provider } from "react-redux";
import loginStore from "./store";
import Views from "./Views";

function LoginModule(props) {
  return (
    <Provider store={loginStore}>
      <Views
        successCallback={props.successCallback}
        startingScreen={props.startingScreen}
        prePopulatedData={props.prePopulatedData}
      />
    </Provider>
  );
}

export default LoginModule;
