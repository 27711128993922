// import the actionTypes
import { actionTypes } from "./index.js";

export const defaultState = {
  list: false,
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.SET_REWARDS:
      return Object.assign({}, state, {
        ...state,
        list: action.value
      });
    default:
      return state;
  }
}
