import { buildRequest } from "utility/StackApi";
const qs = require("qs");

/* ==========================================================================
Get Profile Information
returns "undefined" if successful
========================================================================== */
export async function verifyPin(data) {
  const { pin } = data;

  return buildRequest({
    verb: "post",
    endpoint: "/verifyPin",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    data: qs.stringify({ pin })
  });
}

/* ==========================================================================
Login
========================================================================== */
export async function login(data) {
  const { email, password } = data;

  return buildRequest({
    verb: "post",
    endpoint: "/login",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    data: qs.stringify({ email, password })
  });
}

/* ==========================================================================
Verify 2-Factor Authentication
========================================================================== */
export async function verify2FA(verificationCode) {
  return buildRequest({
    verb: "post",
    endpoint: "/verifyTwoFactorAuth",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    data: qs.stringify({ verificationCode })
  });
}

/* ==========================================================================
Re-Send 2FA code
========================================================================== */
export async function resend2FA(email) {
  return buildRequest({
    verb: "post",
    endpoint: "/sendTwoFactorAuth",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    data: qs.stringify({ email })
  });
}

/* ==========================================================================
Send Password Reset Email
========================================================================== */
export async function sendPasswordResetEmail(email) {
  return buildRequest({
    verb: "post",
    endpoint: "/sendPasswordResetEmail",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    data: qs.stringify({ email })
  });
}
