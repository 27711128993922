/* eslint-disable */

//Disable eslint because we need to check global var
//Specific to GTM
function canTrack() {
  if (!dataLayer) return false;
  if (!Array.isArray(dataLayer)) return false;

  return true;
}

export default {
  event(eventName, otherTrackingProps) {
    if (canTrack) {
      dataLayer.push({
        event: eventName,
        ...otherTrackingProps
      });
    }
  }
};

/* eslint-enable */
