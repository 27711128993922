import React from "react";
import { Container, Page, CenterContent } from "components";
import Locales from "locales";

const text__comingSoonTitle = Locales("page.sendRequest.comingSoonTitle");
const text__comingSoonSubtitle = Locales("page.sendRequest.comingSoonSubtitle");

function SendRequestPage(props) {
  return (
    <CenterContent fullHeight>
      <Container>
        <Page.ComingSoon
          icon={require("images/asset--send-request.svg")}
          title={text__comingSoonTitle}
          subtitle={text__comingSoonSubtitle}
          downloadButtons
        />
      </Container>
    </CenterContent>
  );
}

export default SendRequestPage;
