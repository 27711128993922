import React from "react";
import { P } from "components";
import styled, { css } from "styled-components";

export default function StateMessage(props) {
  return (
    <StyledStateMessage {...props} mReset center>{props.children}</StyledStateMessage>
  )
}

const StyledStateMessage = styled(P)`
  padding: ${props => props.theme.gutter.remSmall};
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.black};

  ${props =>
    props.error &&
    css`
      background-color: ${props => props.theme.colors.error};
      color: ${props => props.theme.colors.white};
    `
  }

  ${props =>
    props.success &&
    css`
      background-color: ${props => props.theme.colors.success};
      color: ${props => props.theme.colors.white};
    `
  }
`